import { MoreHoriz } from "@mui/icons-material";
import { ConflictsAction, isIncompleteSearchedStatus, PermissionsContextDirect, QuickSearch, SearchStatus, SearchVersion } from "aderant-conflicts-models";
import { ComboBox, ComboBoxItem } from "@aderant/aderant-react-components";
import { ValuesOf } from "@aderant/aderant-web-fw-core";
import _ from "lodash";
import { HitResult } from "pages/ResultsPage/HitResult";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { searchActions } from "state/actions/SearchActions";
import { PathMap } from "utilities/routingPathMap";
import uuid from "uuid";
import { usePermissionsContext } from "../../state/selectors/appSelectors";

interface SearchOptionsInfo {
    searchId?: string;
    versionId?: string;
    assignedToUserId?: string | null;
    searchStatus?: SearchStatus;
    onPrintReport?: () => void;
    results?: HitResult[];
    gridColumns?: Map<string, { displayName: string; sequence?: number }>;
    isQuickSearch?: boolean;
}

interface SearchOptionsComboBoxProps {
    searchInfo: SearchOptionsInfo;
    iconSize: number;
    variant?: "contained" | "outlined" | "plain";
}

const SearchOptions = {
    CreateCopy: "CREATECOPY",
    Delete: "DELETE",
    Edit: "EDIT",
    NewVersion: "NEWVERSION",
    PrintReport: "PRINTREPORT",
    ConvertToSearchRequest: "CONVERTTOSEARCHREQUEST",
    SearchAgain: "SEARCHAGAIN"
};

type SearchOptions = ValuesOf<typeof SearchOptions>;

export const messages = {
    createSearchCopy: "Create Copy",
    delete: "Delete",
    edit: "Edit",
    newSearchVersion: "Create New Version",
    printReport: "Print Report",
    convertToSearchRequest: "Convert to Search Request",
    searchAgain: "Search Again"
};

const allowNewVersion = (permissions: PermissionsContextDirect, searchInfo: SearchOptionsInfo) => {
    return (
        searchInfo.assignedToUserId === permissions.currentUserId &&
        searchInfo.searchStatus &&
        isIncompleteSearchedStatus(searchInfo.searchStatus) &&
        permissions.currentUserHasPermission(ConflictsAction.SubmitSearch)
    );
};

const getSearchOptionsByUser = (permissions: PermissionsContextDirect, searchInfo: SearchOptionsInfo) => {
    let searchOptions: ComboBoxItem<SearchOptions>[] = [];
    if (searchInfo.isQuickSearch && (searchInfo.searchStatus === "SEARCHED" || searchInfo.searchStatus === "DRAFT")) {
        searchOptions.push({ value: SearchOptions.Delete, displayName: messages.delete });
        searchOptions.push({ value: SearchOptions.SearchAgain, displayName: messages.searchAgain });
        if (searchInfo.searchStatus === "SEARCHED") {
            searchOptions.push({ value: SearchOptions.Edit, displayName: messages.edit });
            searchOptions.push({ value: SearchOptions.ConvertToSearchRequest, displayName: messages.convertToSearchRequest });
        }
    } else {
        if (searchInfo.searchStatus === "DRAFT") {
            searchOptions.push({ value: SearchOptions.Delete, displayName: messages.delete });
        }
        if (searchInfo.searchStatus !== "SEARCHING") {
            searchOptions.push({ value: SearchOptions.CreateCopy, displayName: messages.createSearchCopy });
        }
        if (allowNewVersion(permissions, searchInfo)) {
            searchOptions.push({ value: SearchOptions.NewVersion, displayName: messages.newSearchVersion });
        }
    }

    if (searchInfo.onPrintReport && searchInfo.results) {
        searchOptions.push({ value: SearchOptions.PrintReport, displayName: messages.printReport });
    }

    searchOptions = _.sortBy(searchOptions, (option) => option.displayName);
    return searchOptions;
};

export const SearchOptionsComboBox = (props: SearchOptionsComboBoxProps): JSX.Element | null => {
    const { searchInfo, iconSize, variant } = props;
    const [items, setItems] = React.useState<ComboBoxItem<SearchOptions>[]>([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const permissions = usePermissionsContext();

    useEffect(() => {
        const options = getSearchOptionsByUser(permissions, searchInfo);
        setItems(options);
    }, [searchInfo]);

    const handleNewSearchVersion = () => {
        history.push(`/search/${searchInfo.searchId}${PathMap.newVersion}`);
    };

    const handleConvertToSearchRequest = () => {
        if (searchInfo.searchId) {
            dispatch(searchActions.convertToSearchRequest({ quickSearchId: searchInfo.searchId, newSearchId: uuid(), history: history }));
        } else {
            window.alert("Search Id is undefined.");
        }
    };

    const handleCreateSearchCopy = () => {
        //clear current search unless navigating from the Search Edit Page due to confirmation dialogs
        if (history.location.pathname.includes(PathMap.searchRequest) && !history.location.pathname.includes(PathMap.newVersion) && searchInfo.searchId) {
            dispatch(searchActions.addSearchCopyFromId({ currentSearchId: searchInfo.searchId, newSearchId: uuid(), history: history, setAsCurrentSearch: false }));
        } else if (searchInfo.searchId) {
            dispatch(searchActions.addSearchCopyFromId({ currentSearchId: searchInfo.searchId, newSearchId: uuid(), history: history }));
        } else {
            window.alert("Search Id is undefined.");
        }
    };

    const handlePrintReport = () => {
        searchInfo.onPrintReport && searchInfo.onPrintReport();
    };

    const handleDelete = () => {
        if (searchInfo.searchId && searchInfo.versionId) {
            dispatch(searchActions.deleteSearches({ searchVersionIdentifiers: [{ searchId: searchInfo.searchId, versionId: searchInfo.versionId }] }));
            if (searchInfo.isQuickSearch) {
                history.push(PathMap.quickSearches);
            } else {
                history.push(PathMap.searchRequests);
            }
        } else {
            window.alert("Search Id and/or Version Id is undefined.");
        }
    };

    const handleSearchAgain = () => {
        if (searchInfo.searchId && searchInfo.versionId && searchInfo.searchStatus) {
            dispatch(searchActions.performQuickSearchAgain({ searchId: { searchId: searchInfo.searchId, versionId: searchInfo.versionId }, previousStatus: searchInfo.searchStatus, history }));
        }
    };

    const handleEdit = () => {
        if (searchInfo.searchId) {
            history.push(`/search/${searchInfo.searchId}${PathMap.quickSearch}`);
        } else {
            window.alert("Search Id is undefined.");
        }
    };

    const onItemSelected = (item: ComboBoxItem<SearchOptions>) => {
        switch (item.value) {
            case SearchOptions.NewVersion:
                handleNewSearchVersion();
                break;
            case SearchOptions.CreateCopy:
                handleCreateSearchCopy();
                break;
            case SearchOptions.PrintReport:
                handlePrintReport();
                break;
            case SearchOptions.Delete:
                handleDelete();
                break;
            case SearchOptions.ConvertToSearchRequest:
                handleConvertToSearchRequest();
                break;
            case SearchOptions.SearchAgain:
                handleSearchAgain();
                break;
            case SearchOptions.Edit:
                handleEdit();
                break;
        }
    };

    if (items.length > 0) {
        return (
            <ComboBox
                iconButton
                startIcon={<MoreHoriz style={{ margin: iconSize }} />}
                style={{ marginLeft: iconSize }}
                aria-label="SearchOptionsComboBox"
                rounded
                variant={variant || undefined}
                items={items}
                onItemSelected={onItemSelected}
            />
        );
    } else {
        return null;
    }
};
