import { SingleSelectValue, DataGridPreference, updateViewByPreference } from "@aderant/aderant-react-components";
import { AssignedToUserPickList } from "components/AssignedToUserPickList/AssignedToUserPickList";
import { RootState } from "MyTypes";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { refinerActions } from "state/actions/RefinerActions";
import { ViewByRefiner, VIEW_BY_FILTERS } from "state/reducers/ReducerStateTypes";
import { getAllUsers, getCurrentUser, viewByRefinerSelector } from "state/selectors";
import { Messages } from "./Messages";
import { getGridPreferences, getSelectedSubscription } from "state/selectors";
import { gridPreferencesEqualityFunction } from "components/DataGridWithRefiner/DataGridWithRefiner";
import { appActions } from "state/actions";

type ViewByPickListsProps = { gridId: string; disabled: boolean; persistViewByRefiners: boolean | undefined };
const ViewByPickLists = (props: ViewByPickListsProps) => {
    const { gridId, disabled, persistViewByRefiners } = props;
    const dispatch = useDispatch();

    const viewByRefiners: ViewByRefiner[] = useSelector((rootState: RootState) => {
        return viewByRefinerSelector(rootState);
    });
    const users = useSelector(getAllUsers, shallowEqual);

    const currentUser = useSelector(getCurrentUser);
    const selectOptions: SingleSelectValue[] = users
        ? users.map((u) => {
              return { label: u.name, value: u.id };
          })
        : currentUser
        ? [{ label: currentUser.name, value: currentUser.id }]
        : [];
    const subscription = useSelector(getSelectedSubscription);
    const gridPreferences = useSelector(getGridPreferences(gridId), gridPreferencesEqualityFunction);
    const prepValues = (key: string): SingleSelectValue[] => {
        const ids = viewByRefiners.find((viewBy: ViewByRefiner) => viewBy.name == key)?.ids ?? [];
        const values = selectOptions.filter((option) => option.value && ids.includes(option.value));
        return values;
    };

    function updateGridPreference(newGridPreferences: DataGridPreference) {
        if (!subscription?.id) {
            console.error("Subscription id not found in app state. Unable to update grid preferences.");
        } else {
            dispatch(appActions.updateGridPreference({ preference: newGridPreferences, subscriptionId: subscription.id }));
        }
    }

    useEffect(() => {
        //persist the change in viewByRefiners to grid preferences, but only after they have been loaded or updated
        if (persistViewByRefiners && viewByRefiners.length > 0) {
            updateViewByPreference(gridPreferences, updateGridPreference, viewByRefiners);
        }
    }, [viewByRefiners]);

    return (
        <>
            {VIEW_BY_FILTERS.map((view) => {
                return (
                    <AssignedToUserPickList
                        multiSelect
                        onUserChanged={(userIds?: (string | null)[] | null): void => {
                            const ids: (string | null | undefined)[] = userIds ?? [];
                            //If null is a selected value in the picklist, we want to filter with both null and undefined values
                            if (userIds?.includes(null)) {
                                ids.push(undefined);
                            }
                            dispatch(refinerActions.updateViewByRefiners({ viewByRefiner: { name: view.name, path: view.path, ids } }));
                        }}
                        label={Messages[view.name].getMessage()}
                        selectedUserIds={viewByRefiners.find((viewBy: ViewByRefiner) => viewBy.name === view.name)?.ids ?? []}
                        disabled={disabled}
                        labelProps={{ position: "top", width: "180px" }}
                        key={view.name}
                    />
                );
            })}
        </>
    );
};

export default React.memo(ViewByPickLists);
