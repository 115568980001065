import { createNewQuickSearchFromID, isErroredSearch, QUICK_SEARCH_TERMS_LIMIT } from "aderant-conflicts-models";
import { Button, MessageDialog } from "@aderant/aderant-react-components";
import { Logger } from "@aderant/aderant-web-fw-core";
import { Action, Location } from "history";
import { RootState } from "MyTypes";
import { default as React, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { searchActions } from "state/actions";
import { getCurrentQuickSearch, getIsFetchingCurrentSearch, getIsNewSearch, getIsSearchLoaded, usePermissionsContext } from "state/selectors";
import SearchEditPage, { SearchEditPageDesktopProps } from "./SearchEditPage";
import { Backdrop, CircularProgress } from "@mui/material";
import { conflictsStyles } from "styles/conflictsStyles";
import { Messages } from "./Messages";

export function QuickSearchEditPageContainer(props: { logger: Logger }) {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const searchVersion = useSelector((rootState: RootState) => getCurrentQuickSearch(rootState), shallowEqual);
    const history = useHistory();
    const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] = useState(false);
    const navLocationAction = useRef<{ location: Location; action: Action }>({ location: history.location, action: history.action });
    const shouldNavigateAway = useRef(false);
    const suppressUnsavedDataHandling = useRef(false);
    const permissions = usePermissionsContext();
    const isCurrentSearchLoaded: boolean = useSelector((rootState: RootState) => getIsSearchLoaded(rootState.search, id));
    const isFetchingCurrentSearch: boolean = useSelector((rootState: RootState) => getIsFetchingCurrentSearch(rootState, id));
    const isNewSearch: boolean = useSelector(getIsNewSearch);

    useEffect(() => {
        if (isNewSearch) {
            dispatch(searchActions.addSearch({ newSearchVersion: createNewQuickSearchFromID(id, permissions.currentUserId), doNotPersist: true }));
        }
    }, [id]);

    const initialSearchVersion = useRef(searchVersion);

    useEffect(() => {
        //Everytime the etag changes, it means that we have saved the document and we want to reset the initial search version to the last saved search version
        initialSearchVersion.current = searchVersion;
    }, [searchVersion?._etag]);

    const searchAction = (searchVersion, history, updateIsSearchingWhenDone) => {
        if (!searchVersion || !searchVersion.isQuickSearch) {
            return;
        }
        dispatch(searchActions.performQuickSearch({ quickSearch: searchVersion, history, updateIsSearchingWhenDone }));
    };

    const addTermAndThenPeformSearchAction = (searchVersion, history, requestTerm, updateIsSearchingWhenDone) => {
        if (!searchVersion) {
            return;
        }
        suppressUnsavedDataHandling.current = true;
        dispatch(searchActions.performQuickSearch({ quickSearch: { ...searchVersion, requestTerms: [...searchVersion.requestTerms, requestTerm] }, history, updateIsSearchingWhenDone }));
    };
    const onNavigateAway: (location: Location, action: Action) => boolean = (location: Location, action: Action) => {
        if (location.pathname.endsWith(`/quick-searches/`) || shouldNavigateAway.current) {
            return true;
        } else {
            navLocationAction.current = { location, action };
            setIsDiscardChangesDialogOpen(true);
            return false;
        }
    };

    const searchEditPageProps: SearchEditPageDesktopProps = {
        searchVersion: searchVersion,
        logger: props.logger,
        pageDetails: {
            pageTitle: Messages.NEW_QUICK_SEARCH_PAGE_TITLE.getMessage(),
            requestTermsLimit: QUICK_SEARCH_TERMS_LIMIT,
            searchAction: searchAction,
            addTermThenPerformSearchAction: addTermAndThenPeformSearchAction,
            suppressUnsavedDataHandling: suppressUnsavedDataHandling
        }
    };

    useEffect(() => {
        //The only quick searches that should be persisted are errored searches
        if (!isCurrentSearchLoaded && !isFetchingCurrentSearch && searchVersion?.searchRequestMessages && isErroredSearch(searchVersion?.searchRequestMessages)) {
            dispatch(searchActions.fetchLatestSearchVersion({ searchId: id, fetchHitResults: false }));
        }
    }, [id]);

    const navigateWithoutPrompt = ({ location, action }: { location: Location; action: Action }) => {
        shouldNavigateAway.current = true;
        switch (action) {
            case "POP":
                history.goBack();
                return;
            case "REPLACE":
                history.replace(location);
                break;
            case "PUSH":
                history.push(location);
                break;
            default:
                history.push(location);
        }
        shouldNavigateAway.current = false;
    };

    return (
        <>
            <SearchEditPage {...searchEditPageProps}>
                <Prompt when={true} message={onNavigateAway} />
                <MessageDialog
                    open={isDiscardChangesDialogOpen}
                    onClose={() => setIsDiscardChangesDialogOpen(false)}
                    message={Messages.DISCARD_CHANGES_PROMPT.getMessage()}
                    title={Messages.DISCARD_CHANGES_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text="Cancel"
                                color="secondary"
                                onClick={() => {
                                    setIsDiscardChangesDialogOpen(false);
                                }}
                            />
                            <Button
                                text="Leave"
                                onClick={() => {
                                    dispatch(searchActions.clearCurrentSearch()); //So the changes here do not persist if the page is opened again immediately.
                                    initialSearchVersion.current && dispatch(searchActions.updateSearchSummaries(initialSearchVersion.current)); // resetting to persisted state if unsaved changes are cancelled
                                    navigateWithoutPrompt(navLocationAction.current);
                                    setIsDiscardChangesDialogOpen(false);
                                }}
                            />
                        </>
                    }
                />
            </SearchEditPage>
            <Backdrop style={conflictsStyles.backdropStyling} open={isFetchingCurrentSearch}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default QuickSearchEditPageContainer;
