import { OmitStrict } from "@aderant/aderant-web-fw-core";
import { EntityDocument } from "aderant-conflicts-models";

export type IngestedEntityDocument = OmitStrict<EntityDocument, "entityType">;

export enum DeltaType {
    Add = "add",
    Update = "update",
    Remove = "remove"
}

export type RecordBatch = {
    entityType: string;
    action: DeltaType;
    lookupRecords: EntityDocument[];
    searchRecords: IngestedEntityDocument[];
};
