import { assertUnreachable } from "@aderant/aderant-web-fw-core";
import { CurrencyValue } from "../..";

//This will be the label field
type CommonFieldValues = {
    fieldLabel: string; // Label
};
//Currency - Value is defined as CurrencyValue type.
export type CurrencyField = {
    value: CurrencyValue;
    displayFormat: "Currency";
} & CommonFieldValues;
//User - Expert passes the entity userId as a number {field.UNO}, conflicts uses a string.
export type UserField = {
    value: {
        userId: number | string;
        userName?: string;
    };
    displayFormat: "User" | "UserWithAvatar"; //Future proof for with/without avatar
} & CommonFieldValues;
//String
export type StringField = {
    value: string;
    displayFormat: "String";
} & CommonFieldValues;
//Number
export type NumberField = {
    value: number;
    displayFormat: "Number";
} & CommonFieldValues;
//Highlight
export type HighlightStringField = {
    value: string[];
    displayFormat: "Highlight";
} & CommonFieldValues;
//Boolean - Will display as Yes/No text on screen
export type BooleanField = {
    value: boolean;
    displayFormat: "Boolean";
} & CommonFieldValues;
//Date field - Input date as string will be converted to appropriate format.
export type DateField = {
    value: Date;
    displayFormat: "Date" | "DateTime"; //ToDo: Confirm the date/date-time format.
} & CommonFieldValues;
//Custom field - used to render custom type e.g. editable fields or any non standard field/element as JSX
export type CustomField = {
    value: React.ReactNode;
    displayFormat: "Custom";
} & CommonFieldValues;

// Todo: Enum Type - check with UX on this one - it might make sense to style a value that's one of a fixed set of allowed ones slightly differently from something that's just an arbitrary string (like comments/narratives). If that was the case we would want to keep the separate type.

export type Field = CurrencyField | UserField | StringField | NumberField | HighlightStringField | BooleanField | DateField | CustomField;

export function hasValue(field: Field) {
    switch (field.displayFormat) {
        case "Currency":
            return field.value && field.value.amount != null && field.value.amount != undefined;
        case "Highlight":
            return !!field.value && field.value.length > 0;
        case "Boolean":
        case "Number":
            return field.value != null && field.value != undefined;
        case "Custom":
        case "Date":
        case "DateTime":
        case "String":
            return !!field.value;
        case "User":
        case "UserWithAvatar":
            return field.value && !!(field.value.userId || field.value.userName);
        default:
            assertUnreachable(field);
    }
}
