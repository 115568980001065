import { Audit, AuditMessages, getHitStatusDisplayValue, getSearchStatusDisplayValue, SearchMessages, SearchReassignAudit, User } from "aderant-conflicts-models";
import { DataGrid, DataGridCellProps, DataGridColumnDefinition, formatShortDateTimeDisplay } from "@aderant/aderant-react-components";
import { assertUnreachable } from "@aderant/aderant-web-fw-core";
import { RootState } from "MyTypes";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchActions } from "state/actions";
import { GridIds } from "state/reducers/appReducers";
import { AuditWithRowId, getAllUsers, getAuditsWithRowIds, getUserName } from "../../state/selectors/";
import { Messages } from "./Messages";

const searchReassignAudit = (audit: SearchReassignAudit, users: User[]) => {
    switch (audit.reassignType) {
        case "ASSIGN":
            return {
                type: SearchMessages.SEARCH_REQUEST.getMessage(),
                description: audit.isSystemAction
                    ? Messages.AUTOMATICALLY_ASSIGNED_TO.getMessage(getUserName(audit.newUserId, users) ?? audit.newUserId)
                    : audit.message
                    ? Messages.REASSIGNED_TO_WITH_MESSAGE.getMessage(getUserName(audit.newUserId, users) ?? audit.newUserId, audit.message)
                    : Messages.REASSIGNED_TO.getMessage(getUserName(audit.newUserId, users) ?? audit.newUserId)
            };
        case "UNASSIGN":
            return {
                type: SearchMessages.SEARCH_REQUEST.getMessage(),
                description: audit.message
                    ? Messages.UNASSIGNED_WITH_MESSAGE.getMessage(getUserName(audit.originalUserId, users) ?? audit.originalUserId, audit.message)
                    : Messages.UNASSIGNED_FROM.getMessage(getUserName(audit.originalUserId, users) ?? audit.originalUserId)
            };
        default:
            assertUnreachable(audit);
    }
};

export const auditSourceAndDescriptionByActionType = (audit: Audit, searchVersionNumber: string, users: User[]): { type: string; description: string } => {
    let termsMessageBuilder = "";
    const searchNumberWithVersion = audit.searchVersionNumber > 1 ? searchVersionNumber + "." + audit.searchVersionNumber ?? audit.searchId : searchVersionNumber;
    switch (audit.actionType) {
        case "SEARCHREQUEST":
            return {
                type: SearchMessages.SEARCH_REQUEST.getMessage(),
                description: Messages.CREATED_SEARCH_REQUEST.getMessage(searchNumberWithVersion)
            };
        case "SEARCHSUBMIT":
            return {
                type: SearchMessages.SEARCH_REQUEST.getMessage(),
                description: Messages.SUBMITTED_SEARCH_REQUEST.getMessage(searchNumberWithVersion)
            };
        case "SEARCHPERFORM":
            return {
                type: SearchMessages.SEARCH_REQUEST.getMessage(),
                description: Messages.SEARCHED_SEARCH_REQUEST.getMessage(searchNumberWithVersion)
            };
        case "SEARCHSTATUSUPDATE":
            return {
                type: SearchMessages.REPORT.getMessage(),
                description: audit.message
                    ? Messages.UPDATED_REQUEST_STATUS_TO_FROM_WITH_MESSAGE.getMessage(getSearchStatusDisplayValue(audit.originalStatus), getSearchStatusDisplayValue(audit.newStatus), audit.message)
                    : Messages.UPDATED_REQUEST_STATUS_TO_FROM.getMessage(getSearchStatusDisplayValue(audit.originalStatus), getSearchStatusDisplayValue(audit.newStatus))
            };
        case "SEARCHREASSIGN":
            return searchReassignAudit(audit, users);
        case "HITSTATUSUPDATE":
            const numOfAudits = audit.hitActions.length;
            return {
                type: SearchMessages.REPORT.getMessage(),
                description:
                    numOfAudits > 1
                        ? Messages.UPDATED_HIT_RES_STATUS_MANY.getMessage(numOfAudits, getHitStatusDisplayValue(audit.newStatus))
                        : Messages.UPDATED_HIT_RES_STATUS_ONE.getMessage(numOfAudits, getHitStatusDisplayValue(audit.newStatus))
            };
        case "HITCOMMENTADD":
            return {
                type: SearchMessages.COMMENT.getMessage(),
                description: Messages.ADDED_HIT_COMMENT.getMessage(audit.text)
            };
        case "HITCOMMENTEDIT":
            return {
                type: SearchMessages.COMMENT.getMessage(),
                description: Messages.EDITED_HIT_COMMENT.getMessage(audit.text)
            };
        case "HITCOMMENTDELETE":
            return {
                type: SearchMessages.COMMENT.getMessage(),
                description: Messages.DELETED_HIT_COMMENT.getMessage(audit.text)
            };
        case "SEARCHNEWVERSION":
            return {
                type: SearchMessages.NEW_SEARCH_VERSION.getMessage(),
                description: Messages.CREATED_NEW_SEARCH_VERSION.getMessage(searchNumberWithVersion)
            };
        case "REQUESTTERMADD":
            termsMessageBuilder =
                audit.requestTerms.length > 1 ? Messages.ADDED_NEW_REQUEST_TERM_MANY.getMessage(searchNumberWithVersion) : Messages.ADDED_NEW_REQUEST_TERM_ONE.getMessage(searchNumberWithVersion);
            termsMessageBuilder += audit.requestTerms.map((term, index) => {
                return (index ? " " : "") + term.term;
            });
            return {
                type: audit.searchVersionNumber === 0 ? SearchMessages.SEARCH_REQUEST.getMessage() : SearchMessages.NEW_SEARCH_VERSION.getMessage(),
                description: termsMessageBuilder
            };
        case "REQUESTTERMREMOVE":
            termsMessageBuilder =
                audit.requestTerms.length > 1 ? Messages.REMOVED_REQUEST_TERM_MANY.getMessage(searchNumberWithVersion) : Messages.REMOVED_REQUEST_TERM_ONE.getMessage(searchNumberWithVersion);
            termsMessageBuilder += audit.requestTerms.map((term, index) => {
                return (index ? " " : "") + term.term;
            });
            return {
                type: audit.searchVersionNumber === 0 ? SearchMessages.SEARCH_REQUEST.getMessage() : SearchMessages.NEW_SEARCH_VERSION.getMessage(),
                description: termsMessageBuilder
            };
        case "SEARCHTERMADD":
            termsMessageBuilder = "";
            termsMessageBuilder += audit.terms.map((term, index) => {
                if (term.searchTerms.length > 1) {
                    return (index ? " " : "") + Messages.ADDED_NEW_SEARCH_TERM_MANY.getMessage(term.searchTerms.length, term.requestTerm.term);
                }
                return (index ? " " : "") + Messages.ADDED_NEW_SEARCH_TERM_ONE.getMessage(term.searchTerms.length, term.requestTerm.term);
            });
            termsMessageBuilder += " " + Messages.ON_SEARCH_REQUEST.getMessage(searchNumberWithVersion);
            return {
                type: audit.searchVersionNumber === 0 ? SearchMessages.SEARCH_REQUEST.getMessage() : SearchMessages.NEW_SEARCH_VERSION.getMessage(),
                description: termsMessageBuilder
            };
        case "SEARCHTERMREMOVE":
            termsMessageBuilder = "";
            termsMessageBuilder += audit.terms.map((term, index) => {
                if (term.searchTerms.length > 1) {
                    return (index ? " " : "") + Messages.REMOVED_SEARCH_TERM_MANY.getMessage(term.searchTerms.length, term.requestTerm.term);
                }
                return (index ? " " : "") + Messages.REMOVED_SEARCH_TERM_ONE.getMessage(term.searchTerms.length, term.requestTerm.term);
            });
            termsMessageBuilder += " " + Messages.ON_SEARCH_REQUEST.getMessage(searchNumberWithVersion);
            return {
                type: audit.searchVersionNumber === 0 ? SearchMessages.SEARCH_REQUEST.getMessage() : SearchMessages.NEW_SEARCH_VERSION.getMessage(),
                description: termsMessageBuilder
            };
        case "SEARCHDETAILSUPDATE":
            let description = "";
            if (audit.name) {
                description = Messages.UPDATED_NAME_OF_SEARCH.getMessage(searchNumberWithVersion);
            } else if (audit.description) {
                description = Messages.UPDATED_DESC_OF_SEARCH.getMessage(searchNumberWithVersion);
            } else if (audit.newApproverUserId || audit.originalApproverUserId) {
                if (!audit.newApproverUserId && audit.originalApproverUserId) {
                    description = Messages.REMOVED_APPROVER.getMessage(getUserName(audit.originalApproverUserId, users) ?? "", searchNumberWithVersion);
                } else {
                    description = Messages.UPDATED_APPROVER.getMessage(searchNumberWithVersion, getUserName(audit.newApproverUserId, users) ?? "");
                }
            }
            return {
                type: audit.searchVersionNumber === 0 ? SearchMessages.SEARCH_REQUEST.getMessage() : SearchMessages.NEW_SEARCH_VERSION.getMessage(),
                description: description
            };
        case "SEARCHERRORSTATUSCHANGE":
            return {
                type: audit.searchVersionNumber === 0 ? SearchMessages.SEARCH_REQUEST.getMessage() : SearchMessages.NEW_SEARCH_VERSION.getMessage(),
                description: Messages.SEARCH_ERROR_STATUS_CHANGE.getMessage(searchNumberWithVersion)
            };
        default:
            assertUnreachable(audit);
    }
};

const rowMetadata = {
    getRowId: (rowData: AuditWithRowId) => rowData.rowId
};

export const AuditGrid = (props: { searchId: string; searchVersionNumber: string }): JSX.Element => {
    const dispatch = useDispatch();
    const audits = useSelector(getAuditsWithRowIds);
    const allUsers: User[] | undefined = useSelector(getAllUsers);
    const areAuditsLoaded: boolean = useSelector((rootState: RootState) => {
        return rootState.search.areAuditsLoaded;
    });
    useEffect(() => {
        //Ideally we will only fetch this when we know audits have changed instead of each time we visit this page. Could use the etag to identify if the search has changed
        dispatch(searchActions.fetchAudits({ searchVersionId: props.searchId }));
    }, []);

    const columnDefinitions: DataGridColumnDefinition<Audit>[] = useMemo(
        () => [
            {
                columnName: AuditMessages.DATE_AND_TIME.getMessage(),
                allowSort: false,
                Cell: ({ row }: DataGridCellProps<Audit>) => {
                    return <span>{formatShortDateTimeDisplay(row.original.createdDate)}</span>;
                },
                path: (originalRow) => originalRow.createdDate,
                id: "createdDateLocal",
                width: 180
            },
            {
                columnName: AuditMessages.TYPE.getMessage(),
                id: "actionType",
                path: (originalRow: Audit) => auditSourceAndDescriptionByActionType(originalRow, props.searchVersionNumber, allUsers ?? []).type,
                allowSort: false,
                width: 165
            },
            {
                columnName: AuditMessages.DESCRIPTION.getMessage(),
                id: "description",
                path: (originalRow: Audit) => auditSourceAndDescriptionByActionType(originalRow, props.searchVersionNumber, allUsers ?? []).description,
                allowSort: false,
                width: 350
            },
            {
                columnName: AuditMessages.USER.getMessage(),
                path: (originalRow: Audit) => getUserName(originalRow.performedByUserId, allUsers ?? []),
                id: "performedByUserId",
                allowSort: false,
                flexGrow: 1
            }
        ],
        [props.searchVersionNumber, allUsers?.length]
    );

    return (
        <>
            <DataGrid
                data={audits}
                dataIsLoading={!areAuditsLoaded}
                columnDefinitions={columnDefinitions}
                options={{
                    allowMoveColumns: false
                }}
                gridPreferences={{
                    id: GridIds.auditGrid,
                    columnPreferences: [
                        {
                            columnId: "createdDateLocal",
                            sort: "desc"
                        }
                    ]
                }}
                rowMetadata={rowMetadata}
            />
        </>
    );
};
