import { ValuesOf } from "@aderant/aderant-web-fw-core";
import { StatusCodes } from "../../Http";
import { basicConflictsErrorTypes, Forbidden, NotFound, TooManyRequests, Unauthorized, ValidationErrors } from "./BasicErrors";

/**Intent here is to ensure that it's impossible to create a ConflictsError implementation with any of the reserved error types in _conflictserrortype that doesn't conform to the specific implementation in BasicErrors.
 * This means consuming code can safely assume that Validation/NotFound/Unexpected are actually those types.
 */
type ReservedErrorTypes = ValuesOf<typeof basicConflictsErrorTypes>;

/**Internal only error type that isn't restricted to not using VALIDATION, NOT_FOUND, UNEXPECTED (don't put this in the index) */
export type InternalConflictsError<StatusCode extends StatusCodes.Failure, ErrorType extends string> = {
    _conflictserrortype: ErrorType;
    httpStatusCode: StatusCode;
    message: string;
};

/**
 * The type that all Conflicts API/Service errors should implement.
 * Note: it's not encoded in the type system yet, but you should not create any ConflictsError types where
 * the httpStatusCode is 2XX, as there is code around that makes the assumption that a 2XX cannot be a ConflictsError.
 * Likewise don't use a status code of 500, if you need to return a 500 just throw an exception out of your implementation.
 */
export type ConflictsError<StatusCode extends StatusCodes.Failure = StatusCodes.Failure, ErrorType extends string = string> = ErrorType extends "VALIDATION"
    ? ValidationErrors
    : ErrorType extends "NOT_FOUND"
    ? NotFound
    : ErrorType extends "TOO_MANY_REQUESTS"
    ? TooManyRequests
    : ErrorType extends "ACCESS_DENIED"
    ? Forbidden
    : ErrorType extends "UNAUTHORIZED"
    ? Unauthorized
    : ErrorType extends ReservedErrorTypes
    ? never
    : InternalConflictsError<StatusCode, ErrorType>;

export type Result<T, E extends ConflictsError<any> = ConflictsError<any>> = E | T;

export function ok<T, E extends ConflictsError<any>>(value: Result<T, E>): value is T {
    return !isConflictsError(value);
}

export function isConflictsError(err: any): err is ConflictsError<StatusCodes.Failure, string> {
    return typeof err?._conflictserrortype !== "undefined";
}
