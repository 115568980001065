import { APIs } from "aderant-conflicts-common";
import { EtagMismatch, Forbidden, NotFound, ok, QuickSearch, RequestTerm, Result, SearchErrors, SearchVersionUnedited, SynonymMap, ValidationErrors } from "aderant-conflicts-models";
import { Logger } from "@aderant/aderant-web-fw-core";
export class SearchService {
    private readonly logger: Logger;
    private readonly searchServiceAPI: APIs.SearchServiceProxy;

    constructor(logger: Logger, searchServiceAPI: APIs.SearchServiceProxy) {
        this.logger = logger;
        this.searchServiceAPI = searchServiceAPI;
    }

    public async executeSearch(searchId: string, versionId: string, terms: RequestTerm[]): Promise<SearchVersionUnedited> {
        if (!terms || terms.length === 0) {
            //todo: this seems a bit pointless seeing as the search is actually going to be performed on what's saved server side
            //                                UI validation should have stopped this call from getting here already if there's no terms?
            this.logger.error("SearchService.Execute: no term was supplied.");
            return Promise.reject("Missing term");
        }

        const result: Result<
            SearchVersionUnedited,
            | NotFound
            | SearchErrors.InvalidVersionNumber
            | SearchErrors.InvalidId
            | SearchErrors.NotLatestSearchVersion
            | SearchErrors.NoWordOrPhrases
            | SearchErrors.UserCannotViewSearch
            | ValidationErrors
            | SearchErrors.InvalidSearchType
            | EtagMismatch
        > = await this.searchServiceAPI.startPerformSearch({ searchId, versionId });

        if (ok(result)) {
            return result;
        } else {
            throw result;
        }
    }

    public async executeQuickSearch(
        searchId: string,
        versionId: string
    ): Promise<
        Result<
            QuickSearch,
            | NotFound
            | SearchErrors.InvalidVersionNumber
            | SearchErrors.InvalidId
            | SearchErrors.NotLatestSearchVersion
            | SearchErrors.NoWordOrPhrases
            | SearchErrors.UserCannotViewSearch
            | ValidationErrors
            | SearchErrors.InvalidSearchType
            | EtagMismatch
        >
    > {
        return await this.searchServiceAPI.startPerformQuickSearch({ searchId, versionId });
    }

    public async getSynonymMap(): Promise<Result<SynonymMap, NotFound | Forbidden>> {
        return await this.searchServiceAPI.getSynonymMap();
    }

    public async updateSynonymMap(synonymMap: SynonymMap): Promise<Result<SynonymMap, NotFound | ValidationErrors | EtagMismatch | Forbidden>> {
        return await this.searchServiceAPI.updateSynonymMap(synonymMap);
    }

    public async getSynonymsStatus(): Promise<Result<{ synonymsStatus: "ON" | "OFF" }, NotFound | Forbidden>> {
        return await this.searchServiceAPI.getSynonymsStatus();
    }

    public async updateSynonymsStatus(newSynonymStatus: "ON" | "OFF"): Promise<Result<{ synonymsStatus: "ON" | "OFF" }, ValidationErrors | Forbidden>> {
        return await this.searchServiceAPI.updateSynonymsStatus({ synonymsStatus: newSynonymStatus });
    }
}
