import { OmitStrict, ValuesOf } from "@aderant/aderant-web-fw-core";
import * as _ from "lodash";
import { v4 } from "uuid";
import { ConflictsAction, PermissionsContext, PermissionsContextDirect, QuickSearch, SearchSummary, ValidationMessage } from ".";
import { Hit, HitStatus, isCompleteHitStatus } from "./Hit";
import { SearchMessages } from "./Search.messages";

export type EditState = SearchVersionNew["editState"] | SearchVersionEdited["editState"] | SearchVersion["editState"];

// we need to think of a good way to make this always exist when a search is in searching and searched state and can nullable if not
export type SearchRequestMessageStatus = {
    id: string;
    status: "PENDING" | "COMPLETE" | "ERRORED"; //probably would be useful to put some kind of context about the failure in when this is in an error state for use by the UI? - can be added once we know more about what info we would have
    validationErrors?: ValidationMessage[];
};

//important: the only difference between the "New/Edited/Unedited" Search types should be the edit state flag and whether things are readonly or not, they should still serialize to the same set of json field names and types

//Scott: The domain probably shouldn't actually care about the distinction between "Edited" and "Unedited"
//this should probably actually exist in the storage service and get stamped/removed on the searches when they arrive at/leave the client layer
//
//It's just like this for now because this was a simpler change.
export interface SearchVersionEdited {
    readonly searchId: string; //guid
    readonly id: string; //guid
    /* Assigned by the system.  As we already have a guid ID, why have this?*/
    number?: string;
    readonly editState: "UNSAVED";
    /* Display name*/
    name: string;
    /* These are all users, but what are their exact roles? */
    createdByUserId: string;
    requestedByUserId: string;
    assignedToUserId?: string | null;
    approverUserId?: string;
    description?: string;
    createdOn: Date;
    searchDate?: Date;
    lastModified: Date;
    submittedDate?: Date;
    requestTerms: RequestTerm[];
    version: number;
    applyFuzzySearch: boolean;
    apiMetadata?: ApiMetadata;
    /** This is the most recent message that a user provided when changing the status of the search */
    statusNotificationMessage?: string;
    readonly summary?: {
        hitCountByStatus: Readonly<{ [HitStatus: string]: number }>;
    };
    status: SearchStatus;
    searchRequestMessages?: SearchRequestMessageStatus[];
    readonly searchDocumentType: "SearchVersion";
    readonly isLatestVersion: boolean;
    /* A guid string used for concurrency checking when updating the documents in CosmosDB*/
    readonly _etag: string;
    isQuickSearch?: false;
}

export type ApiMetadata = { createdByApi: true; sourceApplication?: string };

export type SearchVersionEtag = { _etag: SearchVersion["_etag"] };

export type SearchVersionNew = OmitStrict<SearchVersionEdited, "id" | "editState" | "status" | "number" | "lastModified" | "_etag"> & {
    //omitting number as this should always be generated server side before save
    id: string; //required, but lets make it editable on new searches?
    readonly editState: "NEW";
    readonly number?: undefined;
    status: SearchStatus;
    lastModified?: undefined;
};

export class SearchVersionChanges {
    assignedToUserId: boolean;
    status: boolean;
    constructor() {
        this.assignedToUserId = false;
        this.status = false;
    }
}

export type SearchVersionUnedited = OmitStrict<Readonly<SearchVersionEdited>, "editState" | "requestTerms" | "lastModified" | "isLatestVersion"> & {
    readonly editState: "CURRENT";
    readonly requestTerms: Readonly<RequestTerm>[];
    lastModified: Date;
    isLatestVersion: boolean;
};

export type SearchPersistedInCosmos = OmitStrict<SearchVersionUnedited, "lastModified"> & {
    _ts: number;
};

export type SearchVersion = SearchVersionEdited | SearchVersionUnedited;
export type SearchRequest = SearchVersion;
export type SearchResult = SearchVersion;

export function createSearch(searchVersion: OmitStrict<SearchVersionNew, "editState" | "createdOn">): SearchVersionNew {
    return { ...searchVersion, editState: "NEW", createdOn: new Date() };
}

export function createNewSearchVersion(searchVersion: OmitStrict<SearchVersionUnedited, "searchDate" | "lastModified" | "editState">): SearchVersionEdited {
    const searchVersionClone = _.cloneDeep(searchVersion);
    const requestTermsClone = _.cloneDeep(searchVersion.requestTerms);
    return { ...searchVersionClone, id: v4(), editState: "UNSAVED", requestTerms: requestTermsClone, lastModified: new Date() };
}

function copyRequestTerm(requestTerm: OmitStrict<RequestTerm, "id" | "hits">): RequestTerm {
    return { ...requestTerm, id: v4(), hits: [] };
}

function copyRequestTerms(requestTerms: RequestTerm[]): RequestTerm[] {
    const newRequestTerms: RequestTerm[] = [];
    requestTerms.forEach((requestTerm) => newRequestTerms.push(copyRequestTerm(requestTerm)));
    return newRequestTerms;
}

export function makeEditable(searchVersion: QuickSearch): QuickSearch;
export function makeEditable(searchVersion: SearchVersionNew): SearchVersionNew;
export function makeEditable(searchVersion: SearchVersionEdited): SearchVersionEdited;
export function makeEditable(searchVersion: SearchVersionUnedited): SearchVersionEdited;
export function makeEditable(searchVersion: SearchVersion): SearchVersionEdited;
export function makeEditable(searchVersion: SearchVersion | SearchVersionNew): SearchVersionEdited | SearchVersionNew;
export function makeEditable(searchVersion: SearchVersion | SearchVersionNew | QuickSearch): SearchVersionEdited | SearchVersionNew | QuickSearch;
export function makeEditable(searchVersion: SearchVersion | SearchVersionNew | QuickSearch): SearchVersionEdited | SearchVersionNew | QuickSearch {
    const searchVersionClone = _.cloneDeep(searchVersion);
    switch (searchVersionClone.editState) {
        case "NEW": {
            return { ...searchVersionClone };
        }
        case "UNSAVED": {
            return { ...searchVersionClone };
        }
        case "CURRENT": {
            return { ...searchVersionClone, editState: "UNSAVED", lastModified: searchVersionClone.lastModified! };
        }
    }
}

//need this helper method for Application code as typescript 3.8.3 seems to be less smart around discriminated unions than the 4.X.X version we're using everywhere else.
export function isNewSearchVersion(searchVersion: SearchVersionNew | SearchVersion | undefined): searchVersion is SearchVersionNew {
    return searchVersion?.editState === "NEW";
}

export type SearchMassEditFields = Partial<Pick<SearchVersionEdited, "status" | "assignedToUserId"> & { reassignMessage?: string; statusNotificationMessage?: string }>;
export type SearchVersionIdentifier = { searchId: string; versionId: string };

const CompleteSearchStatuses = {
    Approved: "APPROVED",
    Rejected: "REJECTED"
} as const;

export const SearchStatuses = {
    Draft: "DRAFT",
    Submitted: "SUBMITTED",
    Searching: "SEARCHING",
    Searched: "SEARCHED",
    InReview: "INREVIEW",
    ConditionalApproval: "CONDITIONALAPPROVAL",
    ...CompleteSearchStatuses
} as const;

export type CompleteSearchStatus = ValuesOf<typeof CompleteSearchStatuses>;

export const createSearchCopy = (existingSearch: SearchVersion | SearchVersionNew, searchId: string, currentUserId: string) => {
    const newSearchVersion: SearchVersionNew = createSearch({
        id: v4(),
        searchId: searchId,
        createdByUserId: currentUserId,
        requestedByUserId: currentUserId,
        requestTerms: copyRequestTerms(existingSearch.requestTerms),
        number: undefined,
        name: "",
        description: "",
        version: 0,
        status: "DRAFT",
        searchDocumentType: "SearchVersion",
        isLatestVersion: true,
        applyFuzzySearch: existingSearch.applyFuzzySearch
    });
    return newSearchVersion;
};

export const createNewSearchVersionFromID = (searchId: string, currentUserId: string, applyFuzzySearch: boolean) => {
    const newSearchVersion: SearchVersionNew = createSearch({
        id: v4(),
        searchId: searchId,
        createdByUserId: currentUserId,
        requestedByUserId: currentUserId,
        requestTerms: [],
        number: undefined,
        name: "",
        description: "",
        version: 0,
        status: "DRAFT",
        searchDocumentType: "SearchVersion",
        isLatestVersion: true,
        applyFuzzySearch: applyFuzzySearch
    });
    return newSearchVersion;
};

/**
 * returns true if the status given represents a request that has not been searched
 */
export const isUnsearchedSearchStatus = (status: SearchStatus) => {
    return [SearchStatuses.Draft, SearchStatuses.Submitted].some((s) => s === status);
};

/**
 * returns true if the status given represents a request that has been searched
 */
export const isSearchedStatus = (status: SearchStatus) => {
    return [SearchStatuses.Searched, SearchStatuses.InReview, SearchStatuses.ConditionalApproval, CompleteSearchStatuses.Approved, CompleteSearchStatuses.Rejected].some((s) => s === status);
};

/**
 * returns true if a request is searched and incomplete
 */
export const isIncompleteSearchedStatus = (status: SearchStatus) => {
    return [SearchStatuses.Searched, SearchStatuses.InReview, SearchStatuses.ConditionalApproval].some((s) => s === status);
};

/**
 * returns true if a request is complete or conditionally approved.
 * This imples the search requester should be notified by email.
 */
export const isEmailSearchStatus = (status: SearchStatus | undefined) => {
    return [CompleteSearchStatuses.Approved, CompleteSearchStatuses.Rejected, SearchStatuses.ConditionalApproval].some((s) => s === status);
};

/**
 * returns true if a search has any ERRORED state in the searchrequestmessagestatus
 */
export const isErroredSearch = (searchRequestMessages: SearchRequestMessageStatus[]) => {
    return searchRequestMessages.some((s) => s.status === "ERRORED") && searchRequestMessages.every((s) => s.status === "ERRORED" || s.status === "COMPLETE");
};

export type SearchStatus = ValuesOf<typeof SearchStatuses>;
//Validate if passed string is SearchStatus, couldn't find a better way to do this.
export const isValidSearchStatus = (value: string): value is SearchStatus => {
    const allStatuses: string[] = Object.values(SearchStatuses);
    return allStatuses.includes(value);
};

/**
 * Sort a list of statuses.  Use this to sort statuses to keep sorts consistent
 * @param statuses Array of statuses to sort
 * @returns Void.  The input array is mutated
 */
export const sortSearchStatuses = (statuses: SearchStatus[]) => {
    return statuses.sort((a: SearchStatus, b: SearchStatus) => +(a.toString() > b.toString()) || -(b.toString() > a.toString()));
};

/**
 * Get SearchStatus from string
 */
export const getSearchStatus = (status: string): SearchStatus => {
    if (!isValidSearchStatus(status)) {
        throw new Error(`${status} is an invalid option for SearchStatus`);
    }
    return status;
};
/**
 *
 * @param status Search Status
 * @returns Formatted search status display value
 */
export const getSearchStatusDisplayValue = (status: SearchStatus): string => {
    switch (status) {
        case "APPROVED":
            return SearchMessages.SEARCH_STATUS_APPROVED.getMessage();
        case "CONDITIONALAPPROVAL":
            return SearchMessages.SEARCH_STATUS_CONDITIONALAPPROVAL.getMessage();
        case "DRAFT":
            return SearchMessages.SEARCH_STATUS_DRAFT.getMessage();
        case "INREVIEW":
            return SearchMessages.SEARCH_STATUS_INREVIEW.getMessage();
        case "REJECTED":
            return SearchMessages.SEARCH_STATUS_REJECTED.getMessage();
        case "SEARCHED":
            return SearchMessages.SEARCH_STATUS_SEARCHED.getMessage();
        case "SEARCHING":
            return SearchMessages.SEARCH_STATUS_SEARCHING.getMessage();
        case "SUBMITTED":
            return SearchMessages.SEARCH_STATUS_SUBMITTED.getMessage();
    }
};

export function isVersionSearched(searchVersion: SearchVersion | SearchVersionNew | SearchSummary | QuickSearch): boolean {
    return isSearchedStatus(searchVersion.status);
}

export function isCompleteSearchStatus(status: SearchStatus): status is CompleteSearchStatus {
    return Object.values(CompleteSearchStatuses).some((chs) => chs === status);
}

export function getNumberDisplayValue(search: SearchSummary | SearchVersion | SearchVersionNew, isNewVersion = false): string {
    const version = isNewVersion ? search.version + 1 : search.version;
    if ((isSearchedStatus(search.status) || search.status === "SEARCHING" || getIsErrored(search)) && search.number) {
        return version > 1 ? search.number + "." + version : search.number ? search.number.toString() : "";
    } else {
        return "";
    }
}
function getIsErrored(search: SearchSummary | SearchVersion | SearchVersionNew): boolean {
    if ("errorStatus" in search && search.errorStatus) {
        return search.errorStatus.isErrored ?? false;
    } else if ("errored" in search) {
        return search.errored ?? false;
    } else if ("searchRequestMessages" in search) {
        return !!search.searchRequestMessages?.find((m) => m.status === "ERRORED");
    } else {
        return false;
    }
}

/**
 * Call this function to determine whether a search version is the latest version of a search
 * @param searchVersion
 */
export function isVersionLatest(searchVersion: SearchVersion): boolean {
    return searchVersion.isLatestVersion;
}
export interface RequestTerm {
    id?: string;
    term: string; //description of the request term
    hits?: Hit[];
    searchTerms: string[]; //For easier consumption
    affiliation?: string;
    partyStatus?: string;
}

export function isSearchVersionComplete(search: SearchVersion | SearchVersionEdited | SearchSummary) {
    const summary = search.summary;
    if (!summary) {
        return false;
    }

    for (const hitStatus in summary.hitCountByStatus) {
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        if (!isCompleteHitStatus(hitStatus as HitStatus) && summary.hitCountByStatus[hitStatus] > 0) {
            return false;
        }
    }

    return true;
}

//Validation for user actions

/**
 * Tests whether the current user is allowed to edit the given search.
 * @param search
 * @param currentUserId
 */
export function userCanEditSearch(search: SearchVersion | SearchVersionNew, currentUserId: string): boolean {
    if (search.status === SearchStatuses.Submitted) {
        return search.assignedToUserId === currentUserId;
    }
    return true;
}

/**
 * Check if any business fields differ between searches.  These fields are checked:
 *  - Name
 *  - Description
 *  - ApplyFuzzySearch
 *  - Request term add/remove
 *  - Request term party status, affiliation, search term text, add, remove
 * @param existingSearchVersion
 * @param updatedSearchVersion
 * @returns
 */
export function hasSearchBusinessDataBeenModified(existingSearchVersion: QuickSearch | SearchVersionUnedited, updatedSearchVersion: QuickSearch | SearchVersionEdited): boolean {
    return (
        existingSearchVersion.name !== updatedSearchVersion.name ||
        existingSearchVersion.description !== updatedSearchVersion.description ||
        existingSearchVersion.applyFuzzySearch !== updatedSearchVersion.applyFuzzySearch ||
        existingSearchVersion.requestTerms.length !== updatedSearchVersion.requestTerms.length ||
        existingSearchVersion.requestTerms.some((existingRequestTerm) => {
            const updatedTerm = updatedSearchVersion.requestTerms.find((t) => t.id === existingRequestTerm.id);
            return (
                !updatedTerm ||
                existingRequestTerm.term !== updatedTerm.term ||
                existingRequestTerm.partyStatus !== updatedTerm.partyStatus ||
                existingRequestTerm.affiliation !== updatedTerm.affiliation ||
                existingRequestTerm.searchTerms.length !== updatedTerm.searchTerms.length ||
                !existingRequestTerm.searchTerms.every((existingSearchTerm: string) => {
                    return updatedTerm?.searchTerms.includes(existingSearchTerm);
                })
            );
        })
    );
}

type SearchStatusPermissions = {
    currentUserId: string;
    canChangeStatusOnCompleteSearches: boolean;
    canSubmitSearch: boolean;
    canPerformSearch: boolean;
};
async function getSearchStatusPermissions(context: PermissionsContext): Promise<SearchStatusPermissions> {
    return {
        currentUserId: context.currentUserId,
        canChangeStatusOnCompleteSearches: await context.currentUserHasPermission(ConflictsAction.ChangeStatusOnCompleteSearches),
        canSubmitSearch: await context.currentUserHasPermission(ConflictsAction.SubmitSearch),
        canPerformSearch: await context.currentUserHasPermission(ConflictsAction.PerformSearch)
    };
}
function getSearchStatusPermissionsDirect(context: PermissionsContextDirect): SearchStatusPermissions {
    return {
        currentUserId: context.currentUserId,
        canChangeStatusOnCompleteSearches: context.currentUserHasPermission(ConflictsAction.ChangeStatusOnCompleteSearches),
        canSubmitSearch: context.currentUserHasPermission(ConflictsAction.SubmitSearch),
        canPerformSearch: context.currentUserHasPermission(ConflictsAction.PerformSearch)
    };
}
/**
 * Returns a list of all of the statuses that a search can be changed to, from its current state.
 * Includes it's current state
 */
function getStatusesCurrentUserCanChangeSearchTo(
    context: SearchStatusPermissions,
    search: SearchVersion | SearchSummary | QuickSearch,
    options?: { allowStatusChangeWhenCurrentUserIsNotAssigned?: boolean }
): SearchStatus[] {
    if (search.isQuickSearch) {
        const availableQuickSearchStatuses: SearchStatus[] = [];
        if (search.status === SearchStatuses.Searching) {
            availableQuickSearchStatuses.push(SearchStatuses.Draft);
            availableQuickSearchStatuses.push(SearchStatuses.Searched);
        }
        if (search.status === SearchStatuses.Draft) {
            availableQuickSearchStatuses.push(SearchStatuses.Searching);
        }
        if (search.status === SearchStatuses.Searched) {
            availableQuickSearchStatuses.push(SearchStatuses.Searching);
            availableQuickSearchStatuses.push(SearchStatuses.Draft);
        }
        return availableQuickSearchStatuses;
    }
    if (isVersionSearched(search)) {
        if (!options?.allowStatusChangeWhenCurrentUserIsNotAssigned && search.assignedToUserId !== context.currentUserId) {
            //console.debug("getStatusesUserCanChangeSearchTo - Assigned user is not the current user, returning no valid statuses.");
            return [];
        }

        if (!context.canChangeStatusOnCompleteSearches && (search.status === "APPROVED" || search.status === "REJECTED")) {
            // console.debug(
            //     "getStatusesUserCanChangeSearchTo - Search status is Approved/Rejected and current user does not have permission to change status on completed searches, returning no valid statuses."
            // );
            return [];
        }

        if (!isSearchVersionComplete(search)) {
            //console.debug("getStatusesUserCanChangeSearchTo - Search version is not complete, returning InReview");
            return search.status !== SearchStatuses.InReview ? [SearchStatuses.InReview] : [];
        }

        return [SearchStatuses.InReview, SearchStatuses.ConditionalApproval, SearchStatuses.Approved, SearchStatuses.Rejected].filter((s) => s !== search.status);
    }

    const availableStatuses: SearchStatus[] = [];

    //Cannot change to Draft

    //Change to submitted
    if ((search.status === SearchStatuses.Draft || isIncompleteSearchedStatus(search.status)) && context.canSubmitSearch) {
        availableStatuses.push(SearchStatuses.Submitted);
    }

    //Change to searching
    if ([SearchStatuses.Draft, SearchStatuses.Submitted].some((s) => s === search.status) && context.canPerformSearch) {
        availableStatuses.push(SearchStatuses.Searching);
        availableStatuses.push(SearchStatuses.Searched);
    }

    //Change to searched or error, should only be done in the backend but included here for validating those backend saves
    if (search.status === SearchStatuses.Searching) {
        availableStatuses.push(SearchStatuses.Draft);
        availableStatuses.push(SearchStatuses.Submitted);
        availableStatuses.push(SearchStatuses.Searched);
    }

    return availableStatuses;
}

/**
 * Some search statuses should only be set server side, this function excludes these
 * @param searchVersion
 * @param currentUser
 * @param newUserId
 */
export async function getStatusesUsersCanManuallyChangeSearchTo(context: PermissionsContext, searchVersion: SearchVersion | SearchSummary, newUserId?: string | null): Promise<SearchStatus[]> {
    const excludedStatuses: SearchStatus[] = [SearchStatuses.Searching, SearchStatuses.Searched, SearchStatuses.Submitted, SearchStatuses.Draft];
    return (await getStatusesUsersCanChangeSearchTo(context, searchVersion, newUserId)).filter((s: SearchStatus) => !excludedStatuses.includes(s));
}
export function getStatusesUsersCanManuallyChangeSearchToDirect(context: PermissionsContextDirect, searchVersion: SearchVersion | SearchSummary, newUserId?: string | null): SearchStatus[] {
    const excludedStatuses: SearchStatus[] = [SearchStatuses.Searching, SearchStatuses.Searched, SearchStatuses.Submitted, SearchStatuses.Draft];
    return getStatusesUsersCanChangeSearchToDirect(context, searchVersion, newUserId).filter((s: SearchStatus) => !excludedStatuses.includes(s));
}

/**
 * Returns a list of status change options available based on current and a user to be assigned in the future.
 */
export async function getStatusesUsersCanChangeSearchTo(
    context: PermissionsContext,
    searchVersion: SearchVersion | SearchSummary | QuickSearch,
    newUserId?: string | null,
    options?: { allowStatusChangeWhenCurrentUserIsNotAssigned?: boolean }
): Promise<SearchStatus[]> {
    const searchStatusPermissions = await getSearchStatusPermissions(context);
    return getStatusesUsersCanChangeSearchToInternal(searchStatusPermissions, searchVersion, newUserId, options);
}
export function getStatusesUsersCanChangeSearchToDirect(context: PermissionsContextDirect, searchVersion: SearchVersion | SearchSummary, newUserId?: string | null): SearchStatus[] {
    const searchStatusPermissions = getSearchStatusPermissionsDirect(context);
    return getStatusesUsersCanChangeSearchToInternal(searchStatusPermissions, searchVersion, newUserId);
}
function getStatusesUsersCanChangeSearchToInternal(
    permissions: SearchStatusPermissions,
    searchVersion: SearchVersion | SearchSummary | QuickSearch,
    newUserId?: string | null,
    options?: { allowStatusChangeWhenCurrentUserIsNotAssigned?: boolean }
): SearchStatus[] {
    const original = getStatusesCurrentUserCanChangeSearchTo(permissions, searchVersion, options);
    const future = newUserId ? getStatusesCurrentUserCanChangeSearchTo(permissions, { ...searchVersion, assignedToUserId: newUserId }, options) : [];
    //Remove duplicates when returning the array.
    return Array.from(new Set([...original, ...future]));
}

export const LuceneSpecialCharacters = ["+", "-", "&&", "||", "!", "(", ")", "{", "}", "[", "]", "^", '"', "~", "*", "?", ":", "\\"];

export const isLuceneSpecialCharacterInString = (value: string) => LuceneSpecialCharacters.some((specialCharacter) => value.includes(specialCharacter));

export const isTermSingleAsterisk = (term: string) => {
    if (term && term.length) {
        return term.trim() === "*";
    } else {
        return false;
    }
};

export const FuzzyEditDistance = {
    medium: 1,
    large: 2
};

export const FuzzyEditBreakpoint = {
    medium: 5,
    large: 10
};

export const SplitCharacters = [" "];

export const regexSplitCharacters = () => new RegExp(SplitCharacters.join("|"));

export function updateSearchWithLatestEtag(searchVersion: SearchVersionNew | SearchVersion, etag: string): SearchVersion | SearchVersionNew {
    if (searchVersion.editState === "UNSAVED" || searchVersion.editState === "CURRENT") {
        return {
            ...searchVersion,
            _etag: etag
        };
    } else {
        return searchVersion;
    }
}

export function showSearchVersionNumber(searchVersion: SearchVersion | QuickSearch): string {
    if (!searchVersion.number || searchVersion.number === "0" || searchVersion.number === "") {
        return "";
    }

    if (searchVersion.version === 1) {
        return ` (${searchVersion?.number ?? ""})`;
    }

    return ` (${searchVersion?.number ?? ""}.${searchVersion?.version})`;
}

export function getPreviousSearchStatusOnError(searchVersion: SearchVersion | QuickSearch): SearchStatus {
    if (searchVersion.version > 1 || searchVersion.submittedDate) {
        return SearchStatuses.Submitted;
    } else {
        return SearchStatuses.Draft;
    }
}
