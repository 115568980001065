import { Link } from "@mui/material";
import { Field } from "aderant-conflicts-models";
import { AderantTheme } from "@aderant/aderant-react-components";
import React, { CSSProperties, useState, useRef, useEffect } from "react";
import { conflictsPalette } from "styles/conflictsPalette";
import { StyledFieldOverrides } from "../DynamicFields/DisplayFields/commonStyles";
import { BooleanInformation } from "../DynamicFields/DisplayFields/BooleanInformation";
import { CurrencyInformation } from "../DynamicFields/DisplayFields/CurrencyInformation";
import { CustomInformation } from "../DynamicFields/DisplayFields/CustomInformation";
import { DateInformation } from "../DynamicFields/DisplayFields/DateInformation";
import { HighlightStringInformation } from "../DynamicFields/DisplayFields/HighlightStringInformation";
import { StringInformation } from "../DynamicFields/DisplayFields/StringInformation";
import { UserInformation } from "../DynamicFields/DisplayFields/UserInformation";
import { styles as fieldStyles } from "../DynamicFields/DisplayFields/commonStyles";
import { Messages } from "./Tiles.Messages";
import { assertUnreachable } from "@aderant/aderant-web-fw-core";
import { NumberInformation } from "../DynamicFields/DisplayFields/NumberInformation";

const sectionHeading: CSSProperties = {
    margin: 0,
    fontWeight: 300,
    fontSize: AderantTheme.typography.pxToRem(16)
};
const flyoutSection: CSSProperties = {
    backgroundColor: conflictsPalette.background.white,
    boxShadow: "0 2px 4px rgb(0 0 0 / 15%)",
    marginBottom: "15px",
    padding: "15px"
};
export interface TileProps {
    title: string;
    fields?: (Field & StyledFieldOverrides)[];
    styles?: React.CSSProperties;
    maxHeightCollapsed?: number;
}
export const Tile = (props: TileProps): JSX.Element => {
    const { title, fields, styles, maxHeightCollapsed = 300 } = props;

    const tileRef = useRef<HTMLDivElement>(null);
    const [tileHeight, setTileHeight] = useState<number>(0);

    useEffect(() => {
        if (tileRef.current) {
            setTileHeight(tileRef.current.clientHeight);
        }
    }, [fields]);

    const collapsible = maxHeightCollapsed && tileHeight >= maxHeightCollapsed;
    const [expanded, setExpanded] = useState(false);

    const renderComponents = (): React.ReactNode[] | null => {
        if (!fields || fields.length === 0) {
            return null;
        }
        const renderNodes = fields.map((field, index) => {
            let node: React.ReactNode;
            switch (field.displayFormat) {
                case "Boolean":
                    node = <BooleanInformation key={`${field.fieldLabel}-${index}`} field={field} />;
                    break;
                case "Currency":
                    node = <CurrencyInformation key={`${field.fieldLabel}-${index}`} field={field} />;
                    break;
                case "Date":
                case "DateTime":
                    node = <DateInformation key={`${field.fieldLabel}-${index}`} field={field} />;
                    break;
                case "String":
                    node = <StringInformation key={`${field.fieldLabel}-${index}`} field={field} />;
                    break;
                case "Number":
                    node = <NumberInformation key={`${field.fieldLabel}-${index}`} field={field} />;
                    break;
                case "User":
                case "UserWithAvatar":
                    node = <UserInformation key={`${field.fieldLabel}-${index}`} field={field} />;
                    break;
                case "Custom":
                    node = <CustomInformation key={`${field.fieldLabel}-${index}`} field={field} />;
                    break;
                case "Highlight":
                    node = <HighlightStringInformation key={`${field.fieldLabel}-${index}`} field={field} />;
                    break;
                default:
                    assertUnreachable(field);
            }
            return (
                <div key={`${field.fieldLabel}-${index}`} style={{ ...fieldStyles.containerStyle, ...field.containerStyle }}>
                    {node}
                </div>
            );
        });
        return renderNodes;
    };

    return (
        <div style={{ ...flyoutSection, ...styles, height: collapsible ? (expanded ? "auto" : maxHeightCollapsed) : "auto", display: "flex", flexDirection: "column" }}>
            <h3 style={{ ...sectionHeading, flex: "0 0 auto" }}>{title}</h3>
            <div style={{ flex: "1 1 auto", overflow: "hidden", WebkitMaskImage: collapsible ? (expanded ? "none" : "linear-gradient(to bottom, #000 75%, #0000 100%)") : "none" }}>
                <div style={{ display: "grid", gridTemplateColumns: "1fr", minWidth: "100%" }} ref={tileRef}>
                    {renderComponents()}
                </div>
            </div>
            {collapsible && (
                <div style={{ flex: "0 0 auto", textAlign: "right" }}>
                    <Link
                        sx={{ "&.MuiLink-button.MuiTypography-root": { verticalAlign: "baseline" } }}
                        fontSize="inherit"
                        fontFamily="inherit"
                        underline="always"
                        component="button"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? `- ${Messages.COLLAPSE.getMessage()}` : `+ ${Messages.EXPAND.getMessage()}`}
                    </Link>
                </div>
            )}
        </div>
    );
};
