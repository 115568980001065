/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getTestUser, HardcodedPermissionsConnector, LaunchDarklyClient, LoggedInUser, PermissionsConnectorDirect, TestAppInsightsClient, UserRole } from "aderant-conflicts-models";
import { MockLogger } from "@aderant/aderant-web-fw-core";
import { FunctionAppContext, KeyAuthFunctionAppContext, PublicKeyAuthFunctionAppContext, UserlessKeyAuthFunctionAppContext } from "../ConflictsContext";
import { getMockLaunchDarklyClient } from "./LaunchDarklyClientMock";

export const getConflictsContext = (user?: LoggedInUser, launchDarklyClient?: LaunchDarklyClient): FunctionAppContext => {
    //Ensure we mock launch darkly client for all function app unit tests
    if (!launchDarklyClient) {
        launchDarklyClient = getMockLaunchDarklyClient({ hitOwner: false, tagging: false, autoAssignTo: false, defaultFuzzySearch: false, stickyRefiners: false });
    }
    return new FunctionAppContext(
        new HardcodedPermissionsConnector(),
        new MockLogger(),
        user ??
            getTestUser({
                name: "Test User",
                email: "Test.user@email.com",
                id: "747d6937-933c-4966-bcc2-dfe3c0a7bad1",
                role: UserRole.Conflicts
            }),
        {} as any,
        new TestAppInsightsClient(),
        launchDarklyClient
    );
};

export const getKeyAuthConflictsContext = (user?: LoggedInUser, launchDarklyClient?: LaunchDarklyClient): KeyAuthFunctionAppContext => {
    //Ensure we mock launch darkly client for all function app unit tests
    if (!launchDarklyClient) {
        launchDarklyClient = getMockLaunchDarklyClient({ hitOwner: false, tagging: false, autoAssignTo: false, defaultFuzzySearch: false, stickyRefiners: false });
    }
    return new KeyAuthFunctionAppContext(
        new MockLogger(),
        new TestAppInsightsClient(),
        {
            "x-aderant-userid": user?.id ?? "",
            "x-aderant-tenantuniquename": user?.tenancy.uniqueName ?? ""
        },
        {} as any,
        launchDarklyClient
    );
};

export const getNonConflictsUserContext = (user?: LoggedInUser, launchDarklyClient?: LaunchDarklyClient): FunctionAppContext => {
    //Ensure we mock launch darkly client for all function app unit tests
    if (!launchDarklyClient) {
        launchDarklyClient = getMockLaunchDarklyClient({ hitOwner: false, tagging: false, autoAssignTo: false, defaultFuzzySearch: false, stickyRefiners: false });
    }
    return new FunctionAppContext(
        new HardcodedPermissionsConnector(),
        new MockLogger(),
        user ??
            getTestUser({
                name: "non conflicts User",
                email: "nonconflicts.user@email.com",
                id: "36df6e2e-ace6-430b-a8fe-2164286d780c",
                role: UserRole.Standard
            }),
        {} as any,
        new TestAppInsightsClient(),
        launchDarklyClient
    );
};

export const getAdminContext = (user?: LoggedInUser, launchDarklyClient?: LaunchDarklyClient): FunctionAppContext => {
    //Ensure we mock launch darkly client for all function app unit tests
    if (!launchDarklyClient) {
        launchDarklyClient = getMockLaunchDarklyClient({ hitOwner: false, tagging: false, autoAssignTo: false, defaultFuzzySearch: false, stickyRefiners: false });
    }
    return new FunctionAppContext(
        new HardcodedPermissionsConnector(),
        new MockLogger(),
        user ??
            getTestUser({
                name: "Test User",
                email: "Test.user@email.com",
                id: "8e22e1ab-0c8d-442d-a293-c9cc987929ee",
                role: UserRole.Admin
            }),
        {} as any,
        new TestAppInsightsClient(),
        launchDarklyClient
    );
};

export const getKeyAuthAdminContext = (launchDarklyClient?: LaunchDarklyClient): KeyAuthFunctionAppContext => {
    //Ensure we mock launch darkly client for all function app unit tests
    if (!launchDarklyClient) {
        launchDarklyClient = getMockLaunchDarklyClient({ hitOwner: false, tagging: false, autoAssignTo: false, defaultFuzzySearch: false, stickyRefiners: false });
    }
    return new KeyAuthFunctionAppContext(
        new MockLogger(),
        new TestAppInsightsClient(),
        {
            "x-aderant-userid": "8e22e1ab-0c8d-442d-a293-c9cc987929ee",
            "x-aderant-tenantuniquename": "aderant"
        },
        {} as any,
        launchDarklyClient
    );
};

export const getAzureContextMock: any = () => {
    return {
        bindings: {},
        log: {
            info: jest.fn(),
            warn: jest.fn()
        }
    };
};

export const getUserlessKeyAuthFunctionAppContext = (azureContext?: any): UserlessKeyAuthFunctionAppContext => {
    const mock = new UserlessKeyAuthFunctionAppContext(new MockLogger(), new TestAppInsightsClient(), azureContext ?? getAzureContextMock());
    mock.getDataFactorySecrets = jest.fn();
    mock.getEntityStoreCosmosSecrets = jest.fn();
    mock.getSearchStoreCosmosSecrets = jest.fn();
    mock.getCognitiveSearchSecrets = jest.fn();
    mock.getRLSStorageConnectionInfo = jest.fn();
    mock.getSharedBlobStorageConnectionString = jest.fn();
    mock.getSharedFunctionHostKey = jest.fn();
    return mock;
};

export const getPublicKeyAuthFunctionAppContext = (
    user?: LoggedInUser,
    permissionsConnector?: PermissionsConnectorDirect,
    launchDarklyClient?: LaunchDarklyClient
): PublicKeyAuthFunctionAppContext => {
    //Ensure we mock launch darkly client for all function app unit tests
    if (!launchDarklyClient) {
        launchDarklyClient = getMockLaunchDarklyClient({ hitOwner: false, tagging: false, autoAssignTo: false, defaultFuzzySearch: false, stickyRefiners: false });
    }
    return new PublicKeyAuthFunctionAppContext(
        permissionsConnector ?? new HardcodedPermissionsConnector(),
        new MockLogger(),
        new TestAppInsightsClient(),
        user ??
            getTestUser({
                name: "Test User",
                email: "Test.user@email.com",
                id: "8e22e1ab-0c8d-442d-a293-c9cc987929ee",
                role: UserRole.Conflicts
            }),
        {} as any,
        launchDarklyClient
    );
};
