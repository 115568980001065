import { unexpectedError } from "aderant-conflicts-models";
import { Logger } from "@aderant/aderant-web-fw-core";
import { AxiosResponseHeaders, AxiosResponseTransformer } from "axios";

/**
 * Transforms an HTTP response into an object.  This adds additional handling to JSON parsing to deserialize dates back into date objects instead of strings.
 * @param data The data to parse into an object.
 * @param headers HTTP headers.
 */
export function getResponseTransformer(logger: Logger, dateReviverOptions?: DateReviverOptions): AxiosResponseTransformer {
    return (data, headers) => {
        return transformHttpResponse(logger, data, headers, dateReviverOptions);
    };
}

export function transformHttpResponse(logger: Logger, data: any, headers?: AxiosResponseHeaders, dateReviverOptions?: DateReviverOptions) {
    const contentType = headers?.["content-type"];
    if (typeof contentType === "string" && contentType.startsWith("application/json")) {
        try {
            return JSON.parse(data, (key: string, value: unknown) => dateReviver(key, value, dateReviverOptions));
        } catch (e) {
            //PLEASE READ IF YOU'VE SEEN THIS MESSAGE IN LOGS
            //If you need to call something that sets its content type to application/json but returns malformed JSON please fix via an endpoint specific workaround,
            //don't try to generically work around buggy third parties here, just error out.
            throw unexpectedError(e, "Error encountered when parsing JSON in response with content type of application/json.");
        }
    } else {
        if (data) {
            logger.warn(
                //PLEASE READ IF YOU'VE SEEN THIS MESSAGE IN LOGS
                //If you're calling an endpoint that actually does return something that isn't JSON or an empty response intentionally, then carry on there's nothing to worry about.
                //(The AzureFunctionDefinition infrastructure is written with the sole intent of sending and receiving JSON objects though, so you might have a little bit of extra work to do)
                //
                //If that is /not/ the case, then please read the following:
                //If this branch is being hit, you're probably calling an endpoint that doesn't set its content type headers properly.
                //
                //Browsers will happily treat raw json as if it was an actual JS object, but anything complex (arrays, objects etc)
                //will be missing their prototype methods (e.g. filter, map etc on arrays), so you might have some subtle bugs even if everything seems fine at first glance.
                //
                //If you are not in control of the misbehaving API and you need its response parsed as json, please put in a specific workaround for just that endpoint rather than modifying this code.
                //Parsing all things that say they aren't JSON as JSON will cause pain for others even if it solves your immediate problem.
                "Received a non empty response that did not have a content-type of application/json, returning it as is. This may cause unexpected behaviour if it is supposed to be a JSON object."
            );
        }
        return data;
    }
}

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1,3}Z?$/;
// refers to dates that come from Expert database, using this naming convention as this is not a standard date format.
const businessDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

export type DateReviverOptions = Partial<{
    transformBusinesssDateToDateObject: boolean;
}>;

/**
 * Revives date properties that were serialized as strings into date objects.
 * @param key The key of the property.
 * @param value The value of the property.
 * @returns If a date is found in a string property it is converted into a date object, otherwise @param value is returned.
 */
function dateReviver(key: string, value: unknown, options?: DateReviverOptions) {
    if (typeof value === "string" && dateFormat.test(value)) {
        return new Date(value);
    } else if (typeof value === "string" && businessDateFormat.test(value)) {
        //For expert dates that do not contain a timezone, we do not want to create a Date object until rendered in the client app.
        //This is because the value can be passed between other functions first before reaching the UI and if it is made to be UTC BEFORE it reaches the client, it will be converted
        //to the users locale and will display the incorrect date
        if (options?.transformBusinesssDateToDateObject) {
            return new Date(value);
        }

        return value;
    }

    return value;
}
