import { ConsoleLogger } from "@aderant/aderant-web-fw-applications";
import { Logger, MockLogger } from "@aderant/aderant-web-fw-core";
import { ConnectionContext, PermissionsContextDirect } from "../ConflictsContextModel";
import { BlobStorageSecrets, CognitiveSearchSecrets, CosmosSecrets, DataFactorySecrets, RLSStorageConnectionInfo } from "../ConnectionDetails";
import { HardcodedPermissionsConnector, PermissionsConnectorDirect } from "../Permissions/PermissionsConnector";
import { ConflictsAction } from "../Permissions/UserPermission";
import { LoggedInUser, Subscription, aderantTenants } from "../User";
import { isNonEmpty, NonEmptyArray } from "../UtilityTypes";
import { AppInsightsClient, Telemetry } from "@aderant/aderant-web-fw-azfunctions";
import { getProductionSubscription } from "./Data";

/**Full context implementation is defined outside of models, we need something to call from inside models tests though.
 * This is still calling off to the actual PermissionsConnector internally so we aren't being too silly and testing test only code,
 * ensure you don't put any actual logic in this file.
 */
export class TestPermissionsContext implements PermissionsContextDirect {
    private readonly currentUser: Pick<LoggedInUser, "id" | "role" | "tenancy">;
    readonly currentUserId: string;
    readonly connector: PermissionsConnectorDirect;
    readonly logger: Logger;
    readonly appInsightsClient: AppInsightsClient;

    public constructor(user: Pick<LoggedInUser, "id" | "role" | "tenancy">) {
        this.currentUser = user;
        this.currentUserId = user.id;
        this.connector = new HardcodedPermissionsConnector();
        this.logger = new MockLogger();
        this.appInsightsClient = {} as any;
    }
    currentUserHasPermission(action: ConflictsAction): boolean {
        return this.connector.userHasPermission(this.currentUser, action);
    }
    currentUserHasAllPermissions(actions: NonEmptyArray<ConflictsAction>): boolean {
        return this.connector.userHasAllPermissions(this.currentUser, actions);
    }
    currentUserHasAnyPermission(actions: NonEmptyArray<ConflictsAction>): false | NonEmptyArray<ConflictsAction> {
        return this.connector.userHasAnyPermission(this.currentUser, actions);
    }
    currentUserIsAderantUser(): boolean {
        return this.connector.userIsAderantUser(this.currentUser);
    }
}

export class TestConnectionContext implements ConnectionContext {
    logger: Logger;
    appInsightsClient: AppInsightsClient;
    currentUser: Pick<LoggedInUser, "tenancy">;
    public constructor(tenancyName = "aderant", tenancyId = "", subscription: Subscription = getProductionSubscription(), logger: Logger = new ConsoleLogger()) {
        this.logger = logger;
        this.currentUser = {
            tenancy: {
                id: tenancyId,
                uniqueName: tenancyName,
                displayName: tenancyName,
                subscription: subscription
            }
        };
        this.appInsightsClient = {
            trackPageView(telemetry: Telemetry.PageView): void {},
            trackEvent(telemetry: Telemetry.Event): void {},
            trackTrace(telemetry: Telemetry.Trace): void {},
            trackMetric(telemetry: Telemetry.Metric): void {},
            trackException(telemetry: Telemetry.Exception): void {},
            getTelemetryCorrelationInfo(): { operationId: string; operationParentId?: string | undefined } {
                return { operationId: "" };
            },
            setTelemetryCorrelationInfo(correlationInfo: { operationId: string; operationParentId?: string | undefined }): void {}
        } as any;
    }
    getSharedFunctionHostKey(endpoint: "AdminApi" | "SearchStoreApi" | "SearchApi" | "EntityStoreApi" | "FirmConnectorDataProcessor" | "MonitoringApi"): Promise<string> {
        throw new Error("getSharedFunctionHostKey method not implemented.");
    }
    getSharedBlobStorageConnectionString(): Promise<string> {
        throw new Error("getSharedBlobStorageConnectionString method not implemented.");
    }
    getEntityStoreCosmosSecrets(): Promise<CosmosSecrets> {
        throw new Error("getEntityStoreCosmosSecrets method not implemented.");
    }
    getSearchStoreCosmosSecrets(): Promise<CosmosSecrets> {
        throw new Error("getSearchStoreCosmosSecrets method not implemented.");
    }
    getDataFactorySecrets(): Promise<DataFactorySecrets> {
        throw new Error("getDataFactorySecrets method not implemented.");
    }
    getCognitiveSearchSecrets(): Promise<CognitiveSearchSecrets> {
        throw new Error("getCognitiveSearchSecrets method not implemented.");
    }
    getBlobStorageSecrets(): Promise<BlobStorageSecrets> {
        throw new Error("getBlobStorageSecrets method not implemented.");
    }
    getRLSStorageConnectionInfo(): Promise<RLSStorageConnectionInfo> {
        throw new Error("getRLSStorageConnectionInfo method not implemented.");
    }
}

export function createMockContext(currentUser: Pick<LoggedInUser, "id" | "role" | "tenancy">): PermissionsContextDirect {
    return new TestPermissionsContext(currentUser);
}

export function getMockedPermissionsConnector(actionsToFail: ConflictsAction[]): PermissionsConnectorDirect {
    return {
        userIsAderantUser(user: Pick<LoggedInUser, "id" | "tenancy">): boolean {
            return aderantTenants.some((tenant) => tenant === user.tenancy.uniqueName);
        },
        userHasAllPermissions(user: Pick<LoggedInUser, "id" | "role">, actions: ConflictsAction[]): boolean {
            return actions.every((action) => !actionsToFail.includes(action));
        },
        userHasAnyPermission(user: Pick<LoggedInUser, "id" | "role">, actions: ConflictsAction[]): NonEmptyArray<ConflictsAction> | false {
            const allowedActions = actions.filter((action) => !actionsToFail.includes(action));
            if (isNonEmpty(allowedActions)) {
                return allowedActions;
            } else {
                return false;
            }
        },
        userHasPermission(user: Pick<LoggedInUser, "id" | "role">, action: ConflictsAction): boolean {
            if (actionsToFail.includes(action)) {
                return false;
            }
            return true;
        }
    };
}
