import { assertUnreachable, OmitStrict, ValuesOf } from "@aderant/aderant-web-fw-core";
import _ = require("lodash");
import { BooleanField, CurrencyField, CustomField, DateField, Field, HighlightStringField, NumberField, StringField, UserField } from "../..";
import { EntityDocument } from "../../EntityDocument";

// #region AggregateFieldDefinition
/**
 * Used to define a field that contains a calculated value based on the given properties.
 * @aggregateType defines how values will be aggregated together. Supported values include: sum, count, min & max
 * @conditionType only supports "equal" but can be expanded to support lessThan/greaterThan later on.
 * @conditionValue is used as the comparison value when evaluating the condition type.
 */
export type AggregateFieldDefinition = FieldDefinition & {
    aggregateType: AggregateType;
    conditionType?: ConditionType;
    conditionValue?: any;
};
const AggregateTypes = {
    sum: "sum",
    count: "count",
    max: "max",
    min: "min"
} as const;

export type AggregateType = ValuesOf<typeof AggregateTypes>;

/**
 * Aggregates values by type
 * @param values
 * @param aggregateType @see AggregateType
 */
export function aggregateValuesByType(values: any[], aggregateType: AggregateType) {
    switch (aggregateType) {
        case "sum":
            if (values.length > 0) {
                return _.sum(values);
            } else {
                return null;
            }
        case "count":
            return values.length;
        case "max":
            return _.max(values);
        case "min":
            return _.min(values);
        default:
            assertUnreachable(aggregateType);
    }
}
// #endregion

// #region ConditionType
const ConditionTypes = {
    equal: "equal"
} as const;
/**
 * Can only be used in AggregateFieldDefinitions where calculations are required.
 */
export type ConditionType = ValuesOf<typeof ConditionTypes>;

/**
 * Evaluates whether the passed in value passes the given conditionType & value.
 * For example: if value = "Bob", conditionType = "equal" and conditionValue = "Bob", this function will return true.
 * @param value the value to be evaluated
 * @param conditionType @see ConditionType for available options
 * @param conditionValue used to determine if the passed in value meets the specified condition
 */
export function evaluateConditionType<T>(value: T, conditionType: ConditionType, conditionValue: T) {
    switch (conditionType) {
        case "equal":
            return _.isEqual(value, conditionValue);
        default:
            assertUnreachable(conditionType);
    }
}
// #endregion

//This will be the label field
type CommonFieldDefinitionProps = {
    propertyLabel: string;
    propertyName: string; //property name from which to retrive the value for each field
};
//Currency - Value is defined as CurrencyValue type.
export type CurrencyFieldDefinition = {
    symbol: string;
    decimalPlaces: string;
    displayFormat: "Currency";
    aggregateType?: "max" | "min" | "sum";
} & CommonFieldDefinitionProps;
//User
export type UserFieldDefinition = {
    userId: string;
    userName: string;
    displayFormat: "User" | "UserWithAvatar"; //Future proof for with/without avatar
} & OmitStrict<CommonFieldDefinitionProps, "propertyName">;
//String
export type StringFieldDefinition = {
    displayFormat: "String";
} & CommonFieldDefinitionProps;
//Number
export type NumberFieldDefinition = {
    displayFormat: "Number";
    aggregateType?: "count";
} & CommonFieldDefinitionProps;
//Highlight
export type HighlightStringFieldDefinition = {
    displayFormat: "Highlight";
} & CommonFieldDefinitionProps;
//Boolean - Will display as Yes/No text on screen
export type BooleanFieldDefinition = {
    displayFormat: "Boolean";
} & CommonFieldDefinitionProps;
//Date field - Input date as string will be converted to appropriate format.
export type DateFieldDefinition = {
    displayFormat: "Date" | "DateTime"; //ToDo: Confirm the date/date-time format.
    aggregateType?: "max" | "min";
} & CommonFieldDefinitionProps;
//Custom field - used to render custom type e.g. editable fields or any non standard field/element as JSX
export type CustomFieldDefinition = {
    value: React.ReactNode;
    displayFormat: "Custom";
} & CommonFieldDefinitionProps;

export type FieldDefinition =
    | CurrencyFieldDefinition
    | UserFieldDefinition
    | NumberFieldDefinition
    | StringFieldDefinition
    | HighlightStringFieldDefinition
    | BooleanFieldDefinition
    | DateFieldDefinition
    | CustomFieldDefinition;

/**
 * Maps the entity data to a list of Fields (@see Field) based on the passed in fieldDefinitions
 * @param entity
 * @param fieldDefinitions
 */
export function convertFieldDefinitions(entity: EntityDocument, fieldDefinitions: FieldDefinition[]) {
    const convertedFields = fieldDefinitions.map((field) => mapFieldDefinitionToField(field, entity));
    return convertedFields;
}

/**
 * Maps a FieldDefinition to its corresponding Field with the given entity data.
 * @param fieldDefinition
 * @param entity
 */
export function mapFieldDefinitionToField(fieldDefinition: CurrencyFieldDefinition, entity: EntityDocument): CurrencyField;
export function mapFieldDefinitionToField(fieldDefinition: StringFieldDefinition, entity: EntityDocument): StringField;
export function mapFieldDefinitionToField(fieldDefinition: UserFieldDefinition, entity: EntityDocument): UserField;
export function mapFieldDefinitionToField(fieldDefinition: DateFieldDefinition, entity: EntityDocument): DateField;
export function mapFieldDefinitionToField(fieldDefinition: NumberFieldDefinition, entity: EntityDocument): NumberField;
export function mapFieldDefinitionToField(fieldDefinition: BooleanFieldDefinition, entity: EntityDocument): BooleanField;
export function mapFieldDefinitionToField(fieldDefinition: HighlightStringFieldDefinition, entity: EntityDocument): HighlightStringField;
export function mapFieldDefinitionToField(fieldDefinition: CustomFieldDefinition, entity: EntityDocument): CustomField;
export function mapFieldDefinitionToField(fieldDefinition: FieldDefinition, entity: EntityDocument): Field;
export function mapFieldDefinitionToField(fieldDefinition: FieldDefinition, entity: EntityDocument): Field {
    switch (fieldDefinition.displayFormat) {
        case "Currency": {
            const currencyField: CurrencyField = {
                displayFormat: fieldDefinition.displayFormat,
                fieldLabel: fieldDefinition.propertyLabel,
                value: { amount: entity[fieldDefinition.propertyName], symbol: entity[fieldDefinition.symbol], decimalPlaces: entity[fieldDefinition.decimalPlaces] }
            };
            return currencyField;
        }
        case "Custom": {
            const customField: CustomField = {
                fieldLabel: fieldDefinition.propertyLabel,
                value: fieldDefinition.value,
                displayFormat: fieldDefinition.displayFormat
            };
            return customField;
        }
        case "Boolean":
        case "Date":
        case "DateTime":
        case "Highlight":
        case "Number":
        case "String":
            return {
                displayFormat: fieldDefinition.displayFormat,
                fieldLabel: fieldDefinition.propertyLabel,
                value: entity[fieldDefinition.propertyName]
            };
        case "User":
        case "UserWithAvatar":
            return {
                displayFormat: fieldDefinition.displayFormat,
                fieldLabel: fieldDefinition.propertyLabel,
                value: { userId: entity[fieldDefinition.userId], userName: entity[fieldDefinition.userName] }
            };
        default:
            assertUnreachable(fieldDefinition);
    }
}
