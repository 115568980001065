import { HitStatus, isCompleteHitStatus } from "aderant-conflicts-models";
import { GridRowValues } from "@aderant/aderant-react-components";
import { Logger } from "@aderant/aderant-web-fw-core";

export function getSearchProgress(hitCountByStatus?: { [HitStatus: string]: number }): { progress: number; total: number } {
    let progress = 0;
    let total = 0;
    if (!hitCountByStatus) {
        //Setting total to -1 here for searches with statuses without hits (Draft/Submitted) so grid ordering by progress
        //is accurate for non-hit statused search requests
        return { progress: 0, total: -1 };
    }
    Object.keys(hitCountByStatus).forEach((status) => {
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const hitStatus = status as HitStatus;
        if (isCompleteHitStatus(hitStatus)) {
            progress += hitCountByStatus[status];
        }
        total += hitCountByStatus[status];
    });
    return { progress: progress, total: total };
}

function splitSearchIdAndVersion(searchNumber: string, logger: Logger): { searchId: number; searchVersion: number } {
    try {
        if (!searchNumber) {
            return { searchId: 0, searchVersion: 0 };
        }
        const splits = searchNumber.split("-");
        switch (splits.length) {
            case 1:
                return { searchId: parseInt(splits[0]), searchVersion: 1 };
            case 2:
                return { searchId: parseInt(splits[0]), searchVersion: parseInt(splits[1]) };
            default:
                return { searchId: 0, searchVersion: 0 };
        }
    } catch (error) {
        logger.warn("An error occurred trying to interpret a search ID/version number.", error);
        return { searchId: 0, searchVersion: 0 };
    }
}

export function compareSearchNumbers(rowA: GridRowValues, rowB: GridRowValues, logger: Logger): number {
    const searchNumberA: string = rowA.values["formattedNumber"]?.toString() ?? "";
    const searchNumberB: string = rowB.values["formattedNumber"]?.toString() ?? "";
    const a = splitSearchIdAndVersion(searchNumberA, logger);
    const b = splitSearchIdAndVersion(searchNumberB, logger);
    if (a.searchId === b.searchId) {
        if (a.searchVersion === b.searchVersion) {
            return 0;
        }
        return a.searchVersion > b.searchVersion ? 1 : -1;
    }
    return a.searchId > b.searchId ? 1 : -1;
}
