import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, DataGridPreference, updateViewByPreference } from "@aderant/aderant-react-components";
import { refinerActions } from "state/actions/RefinerActions";
import { Messages } from "./Messages";
import { ViewByNames, VIEW_BY_FILTERS, ViewByRefiner } from "state/reducers/ReducerStateTypes";
import { getGridPreferences, getSelectedSubscription, viewByRefinerSelector } from "state/selectors";
import { gridPreferencesEqualityFunction } from "components/DataGridWithRefiner/DataGridWithRefiner";
import { appActions } from "state/actions";
import { RootState } from "MyTypes";

type ViewByCheckboxesProps = { gridId: string; currentUserId: string; disabled: boolean; persistViewByRefiners: boolean | undefined };
type CheckboxState = { [key: string]: boolean };

export function numberOfRefinersSelected(viewByRefiners?: ViewByRefiner[]): number {
    //count number of selected view by refiners with at least 1 id selected
    let selectedCount = 0;
    viewByRefiners?.forEach((viewBy) => {
        if ((viewBy.ids?.length ?? 0) > 0) {
            selectedCount++;
        }
    });
    return selectedCount;
}

export function numberOfCheckboxesSelected(checkboxState: CheckboxState): number {
    //count number of selected view by refiners with at least 1 id selected
    //undefined defaults to selected
    let selectedCount = 0;
    Object.keys(checkboxState).forEach((key) => {
        if (checkboxState[key]) {
            selectedCount++;
        }
    });
    return selectedCount;
}

export function getCheckBoxState(viewByRefiners?: ViewByRefiner[]): CheckboxState {
    const createdByIds = viewByRefiners?.find((v) => v.name === ViewByNames.CREATED_BY)?.ids;
    const requestedByIds = viewByRefiners?.find((v) => v.name === ViewByNames.REQUESTED_BY)?.ids;
    const assignedToIds = viewByRefiners?.find((v) => v.name === ViewByNames.ASSIGNED_TO)?.ids;
    return {
        [ViewByNames.CREATED_BY]: createdByIds?.length === 0 ? false : true,
        [ViewByNames.REQUESTED_BY]: requestedByIds?.length === 0 ? false : true,
        [ViewByNames.ASSIGNED_TO]: assignedToIds?.length === 0 ? false : true
    };
}

const ViewByCheckboxes = (props: ViewByCheckboxesProps) => {
    const { gridId, currentUserId, disabled, persistViewByRefiners } = props;
    const dispatch = useDispatch();
    const subscription = useSelector(getSelectedSubscription);
    const gridPreferences = useSelector(getGridPreferences(gridId), gridPreferencesEqualityFunction);
    const numberSelected = useRef(3);
    const viewByRefiners: ViewByRefiner[] = useSelector((rootState: RootState) => {
        return viewByRefinerSelector(rootState);
    });
    const [state, setState] = useState<CheckboxState>(getInitialCheckboxState);

    function getInitialCheckboxState(): CheckboxState {
        //load checkboxes from grid preferences
        if (persistViewByRefiners) {
            let selectedState = getCheckBoxState(gridPreferences?.viewByPreferences);

            //At least one checkbox should be selected
            //count number of selected checkboxes
            let selectedCount = numberOfCheckboxesSelected(selectedState);
            // if all are unselected then select all
            if (selectedCount === 0) {
                selectedState = {
                    [ViewByNames.CREATED_BY]: true,
                    [ViewByNames.REQUESTED_BY]: true,
                    [ViewByNames.ASSIGNED_TO]: true
                };
                selectedCount = 3;
            }
            numberSelected.current = selectedCount;
            return selectedState;
        } else {
            numberSelected.current = 3;
            return {
                [ViewByNames.CREATED_BY]: true,
                [ViewByNames.REQUESTED_BY]: true,
                [ViewByNames.ASSIGNED_TO]: true
            };
        }
    }

    function updateGridPreference(newGridPreferences: DataGridPreference) {
        if (!subscription?.id) {
            console.error("Subscription id not found in app state. Unable to update grid preferences.");
        } else {
            dispatch(appActions.updateGridPreference({ preference: newGridPreferences, subscriptionId: subscription.id }));
        }
    }

    useEffect(() => {
        //persist the change in viewByRefiners to grid preferences
        //Do not persist if no refiners are selected - this just means refiners have not yet been loaded into state, as user cannot unselect all view by refiners
        if (persistViewByRefiners && numberOfRefinersSelected(viewByRefiners) > 0) {
            updateViewByPreference(gridPreferences, updateGridPreference, viewByRefiners);
        }
    }, [viewByRefiners]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newNumber = event.target.checked ? numberSelected.current + 1 : numberSelected.current - 1;
        const { id, checked } = event.target;
        const viewByObj = VIEW_BY_FILTERS.find((obj) => obj.name === id);
        if (newNumber > 0 && viewByObj) {
            numberSelected.current = newNumber;
            setState((prevState) => {
                return { ...prevState, [id]: checked };
            });
            dispatch(
                refinerActions.updateViewByRefiners({
                    viewByRefiner: { name: viewByObj.name, path: viewByObj.path, ids: checked ? [currentUserId] : [] }
                })
            );
        }
    };

    return (
        <div style={{ paddingLeft: "13px" }}>
            {VIEW_BY_FILTERS.map((view) => {
                const messageKey = `${view.name}_ME`;

                return <Checkbox label={Messages[messageKey].getMessage()} id={view.name} checked={state[view.name]} compact onChange={handleChange} disabled={disabled} key={view.name} />;
            })}
        </div>
    );
};

export default React.memo(ViewByCheckboxes);
