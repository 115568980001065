import { GridColumnConfiguration, GridConfiguration, SearchMessages, getHitStatusDisplayValue, HitIdentifier, Hit } from "aderant-conflicts-models";
import { DataGridCellProps, DataGridColumnDefinition, formatShortDateDisplay, GridCellValue, GridRowInstance as DataGridRowInstance, DisplayChip } from "@aderant/aderant-react-components";
import { getHitStatusDisplayName } from "dataProviders/hitStatusList";
import { getAffiliationDisplayName, getPartyStatusDisplayName } from "dataProviders/lookupList";
import React, { ReactElement } from "react";
import { HitResult } from "./HitResult";
import { RootState } from "MyTypes";
import { useSelector } from "react-redux";
import { Highlighter } from "components/Highlighter/Highlighter";
import { conflictsPalette } from "styles/conflictsPalette";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
import { CommentIcon } from "components/Icons/CommentIcon";
import { ConsoleLogger } from "@aderant/aderant-web-fw-applications";
import { Logger } from "@aderant/aderant-web-fw-core";
import { getHitStatusColors } from "Functions/getHitStatusColors";
import { defaultCellDisplayValue } from "./ResultsPage";
import { SecuredRowPadlockIcon } from "components/Icons/SecuredRowPadlockIcon";
import { UserNameAndId } from "pages/Shared/userUtils";

export const replaceHighlightedFragment = (stringWithHighlight: string, actualValue: string) => {
    if (actualValue === stringWithHighlight) {
        return actualValue;
    }
    //Identify the string fragment that needs to be replaced
    const searchString = stringWithHighlight.replace(/<em>/g, "").replace(/<\/em>/g, "");
    //We don't want to replace searchString if it already has a highlight in actualString, so split by stringWithHighlight to ignore that and only check rest of string
    const parts = actualValue.split(stringWithHighlight);
    for (let i = 0; i < parts.length; i++) {
        const highlightedPart = parts[i].replace(searchString, stringWithHighlight);
        if (highlightedPart != parts[i]) {
            //we found the searchString and highlighted it
            parts[i] = highlightedPart;
            //only want to highlight the first occurrance
            break;
        }
    }
    return parts.join(stringWithHighlight);
};

export const getHighlighter = (hitResult: HitResult, sourceFieldName: string, resultFieldName: string): ReactElement => {
    const logger: Logger = new ConsoleLogger();
    const value = hitResult[resultFieldName];
    if (sourceFieldName == "hitText") {
        if (typeof value !== "string") {
            logger.warn("Type not supported, only strings can be highlighted.");
            return <Highlighter text={"-"} />;
        }
        const highlights = hitResult.hit.hitLocations.flatMap((h) => h.highlights);
        let stringToRender = value;
        highlights.forEach((highlight) => {
            //Note: If multiple highlights are present with the same text fragment - this will only render the first found highlight of that fragment
            stringToRender = replaceHighlightedFragment(highlight, stringToRender);
        });
        return <Highlighter text={stringToRender} />;
    } else if (sourceFieldName) {
        const highlights: string[] = hitResult.hit.hitLocations.filter((h) => h.location === sourceFieldName).flatMap((h) => h.highlights);
        let stringToRender = highlights[0];
        if (value) {
            if (typeof value === "string") {
                stringToRender = value;
                //Iterate through each highlight and replace the original fragment value with highlighted fragment
                highlights.forEach((highlight) => {
                    //Note: If multiple highlights are present with the same text fragment - this will only render the first found highlight of that fragment
                    stringToRender = replaceHighlightedFragment(highlight, stringToRender);
                });
            } else {
                logger.warn("Type not supported, only strings can be highlighted.");
            }
        }

        if (highlights.length > 0) {
            return <Highlighter text={stringToRender} />;
        }
    }
    let displayValue: string | number | boolean = "";
    if (value === undefined) {
        displayValue = "N/A";
    } else if (value === "" || value === null) {
        displayValue = "-";
    } else if (typeof value === "object") {
        if (value instanceof Date) {
            displayValue = formatShortDateDisplay(value);
        } else {
            //Need to determine type, to decide what to display
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const user = value as UserNameAndId;
            if (user) {
                displayValue = user.name ?? "";
            } else {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const hitIdentifier = value as HitIdentifier;
                if (hitIdentifier) {
                    displayValue = `${hitIdentifier.requestTermId} - ${hitIdentifier.hitId}`;
                } else {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const hit = value as Hit;
                    if (hit) {
                        displayValue = `${hit.sourceType}:${hit.id}`;
                    }
                }
            }
        }
    } else {
        displayValue = value;
    }
    return <span>{displayValue}</span>;
};
export const getGroupHeader = (subRows: DataGridRowInstance<HitResult>[]) => {
    //Get Lookup lists
    const affiliationList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.affiliationList;
    });
    const partyStatusList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.partyStatusList;
    });

    const { requestTerm, searchRequestPartyStatus, affiliation } = subRows[0].original;
    if (!searchRequestPartyStatus && !affiliation) {
        return (
            <span>
                <b>{`${requestTerm} `}</b>
                <b>({subRows.length})</b>
            </span>
        );
    } else if (!searchRequestPartyStatus || !affiliation) {
        return (
            <span>
                <b>{`${requestTerm} `}</b>
                <i>{`(${getPartyStatusDisplayName(searchRequestPartyStatus, partyStatusList) || getAffiliationDisplayName(affiliation, affiliationList)}) `}</i>
                <b>({subRows.length})</b>
            </span>
        );
    }
    return (
        <span>
            <b>{`${requestTerm} `}</b>
            <i>{`(${getAffiliationDisplayName(affiliation, affiliationList)}-${getPartyStatusDisplayName(searchRequestPartyStatus, partyStatusList)}) `}</i>
            <b>({subRows.length})</b>
        </span>
    );
};

export const getHighlightDisplayForRow = (propertyName: string) => {
    return ({ row: { original: hit } }: DataGridCellProps<HitResult, GridCellValue>): ReactElement => {
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const hitResult: HitResult = hit as HitResult;
        return getHighlighter(hit, propertyName === "description" ? hitResult.descriptionFieldName : propertyName, propertyName);
    };
};

export const getContextIconCell = ({ row: { original: hit } }: DataGridCellProps<HitResult, GridCellValue>): ReactElement => {
    const redactedIcon = (
        <div style={{ visibility: hit.isRedacted ? "inherit" : "hidden" }}>
            <SecuredRowPadlockIcon />
        </div>
    );
    const commentIcon = (
        <div style={{ visibility: hit.hit.commentCount ? "inherit" : "hidden", display: "flex" }}>
            <CommentIcon />
        </div>
    );
    const conflictingPartyIcon = <PriorityHigh style={{ visibility: hit.conflictingParty ? "inherit" : "hidden", color: conflictsPalette.status.red }} />;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {commentIcon}
            {conflictingPartyIcon}
            {redactedIcon}
        </div>
    );
};

const getContextIconSortString = (originalRow: HitResult): string => {
    //If the user sorts we want to show conflicting + not redacted rows at the top, followed by conflicting + redacted, followed by not conflicting + redacted, followed by neither
    //e.g.
    //C_
    //CR
    //_R
    //__
    if (originalRow.conflictingParty) {
        if (!originalRow.isRedacted) {
            return "A";
        } else {
            return "B";
        }
    } else {
        if (originalRow.isRedacted) {
            return "C";
        } else {
            return "D";
        }
    }
};

export const getHitStatusDisplayChip = ({ row: { original: hit } }): JSX.Element => {
    const statusColors = getHitStatusColors(hit.hitStatus);
    return DisplayChip({
        text: getHitStatusDisplayValue(hit.hitStatus),
        chipColor: statusColors.default.backgroundColor,
        chipBorderColor: statusColors.default.borderColor,
        textColor: statusColors.default.color
    });
};

const frozenColumns: DataGridColumnDefinition<HitResult>[] = [
    {
        isNeverVisible: true,
        isGrouped: true,
        groupHeaderRow: getGroupHeader,
        columnName: SearchMessages.REQUEST_TERM.getMessage(),
        path: "requestTermId"
    },
    {
        columnName: SearchMessages.NAME.getMessage(),
        path: "name",
        Cell: getHighlightDisplayForRow("name"),
        fixed: true,
        flexGrow: 2
    },
    {
        columnName: "",
        //getContextIconCell handles display, this value from path just determines sorting behaviour
        path: getContextIconSortString,
        id: "contextIcons",
        Cell: getContextIconCell,
        width: 61,
        fixed: true
    },
    {
        columnName: SearchMessages.HIT_ID.getMessage(),
        path: "id",
        width: 85,
        align: "right",
        fixed: true
    }
];

const hitColumns: DataGridColumnDefinition<HitResult>[] = [
    {
        columnName: SearchMessages.HIT_STATUS.getMessage(),
        path: (originalRow: HitResult) => getHitStatusDisplayName(originalRow.hitStatus),
        id: "hitStatus",
        Cell: getHitStatusDisplayChip,
        cellStyle: { textOverflow: "clip" }
    },
    {
        columnName: SearchMessages.HIT_OWNER.getMessage(),
        path: (originalRow: HitResult) => originalRow.hitOwner?.name ?? null,
        id: "hitOwner",
        isVisible: false
    },
    {
        columnName: SearchMessages.HIT_TYPE.getMessage(),
        path: "sourceTypeDisplayValue"
    },
    {
        columnName: SearchMessages.HIT_LOCATION.getMessage(),
        path: "hitLocationsCommaSeparated"
    },
    {
        columnName: SearchMessages.HIT_TERM.getMessage(),
        path: "searchTermsCommaSeparated"
    },
    // #region These columns are included in the list of columns (although not visible) because this information is needed when actioning rows
    {
        columnName: "HitId",
        path: "hitId",
        isNeverVisible: true
    }
    //#endregion
];

export const getGridDefinition = (gridConfiguration: GridConfiguration): DataGridColumnDefinition<HitResult>[] => {
    const columns: DataGridColumnDefinition<HitResult>[] = [];
    columns.push(...frozenColumns);
    columns.push(...hitColumns);

    gridConfiguration.configurations.forEach((userDefinedColumn: GridColumnConfiguration) => {
        const frozenColumn = frozenColumns.find((column) => {
            return userDefinedColumn.propertyName == column.path || userDefinedColumn.propertyName == column.id;
        });
        if (frozenColumn) {
            //You cannot change anything on a frozen column
        } else {
            const hitColumn = hitColumns.find((column) => {
                return userDefinedColumn.propertyName == column.path || userDefinedColumn.propertyName == column.id;
            });
            if (hitColumn) {
                //User can only hide and align hit columns. They can't change anything else about their definition
                //They also can't make isNeverVisible columns visible
                if (!hitColumn.isNeverVisible) {
                    //in below code, no isVisible property in hitColumn definition implies isVisible is true, but no isVisible in userDefinedColumn means don't change
                    if (
                        ((hitColumn.isVisible === undefined || hitColumn.isVisible === true) && userDefinedColumn.isVisible === false) ||
                        (hitColumn.isVisible === false && userDefinedColumn.isVisible === true)
                    ) {
                        hitColumn.isVisible = userDefinedColumn.isVisible;
                    }
                    //need check for userDefinedColumn.align exists, as no definition in hit definition means align left, whereas no definition in userDefined means don't change,
                    //so don't overwrite a hit column unless it's expressly specified
                    if (userDefinedColumn.align) {
                        hitColumn.align = userDefinedColumn.align;
                    }
                }
            } else {
                const column: DataGridColumnDefinition<HitResult> = {
                    columnName: userDefinedColumn.columnName,
                    path: userDefinedColumn.propertyName,
                    isVisible: userDefinedColumn.isVisible,
                    align: userDefinedColumn.align,
                    Cell: userDefinedColumn.displayFormat === "Highlight" ? getHighlightDisplayForRow(userDefinedColumn.propertyName) : undefined
                };
                //The splice adds additional columns before the invisible HitId column defined in hitColumns
                columns.splice(columns.length - 1, 0, column);
            }
        }
    });
    return columns;
};

const ResultColumnDefinitions = (
    displayPreviousHitStatusColumn: boolean,
    displayHitStatusColumn: boolean,
    displayHitOwnerColumn: boolean,
    gridConfiguration: GridConfiguration,
    hitOwnerFeatureFlag?: boolean
): readonly DataGridColumnDefinition<HitResult>[] => {
    const columns: DataGridColumnDefinition<HitResult>[] = getGridDefinition(gridConfiguration);

    if (!displayHitStatusColumn) {
        columns.splice(
            columns.findIndex((column: DataGridColumnDefinition<HitResult>) => column.columnName == SearchMessages.HIT_STATUS.getMessage()),
            1
        );
    } else {
        if (displayPreviousHitStatusColumn) {
            const previousHitStatusRefinerGroup: DataGridColumnDefinition<HitResult> = {
                columnName: SearchMessages.PREVIOUS_HIT_STATUS.getMessage(),
                path: (originalRow: HitResult) => (originalRow.previousVersionStatus ? getHitStatusDisplayName(originalRow.previousVersionStatus) : ""),
                id: "previousVersionStatus"
            };
            columns.splice(columns.findIndex((column: DataGridColumnDefinition<HitResult>) => column.columnName == SearchMessages.HIT_STATUS.getMessage()) + 1, 0, previousHitStatusRefinerGroup);
        }
    }
    if (!hitOwnerFeatureFlag || !displayHitOwnerColumn) {
        const hitOwnerPos = columns.findIndex((column: DataGridColumnDefinition<HitResult>) => column.columnName == SearchMessages.HIT_OWNER.getMessage());
        if (hitOwnerPos >= 0) {
            columns.splice(hitOwnerPos, 1);
        }
    }
    return columns.map((column) =>
        wrapColumnWithRlsCheck(
            column,
            gridConfiguration.configurations.find((c) => c.propertyName === column.path || c.propertyName === column.id)
        )
    );
};

/**
 * Wraps the Cell rendering function for a column with a check for whether it should be displayed as redacted.
 * Note that this is just for configuring cells to display correctly for data that has already been redacted on the backend
 * @param column
 * @param columnConfiguration
 * @returns
 */
function wrapColumnWithRlsCheck(column: DataGridColumnDefinition<HitResult>, columnConfiguration?: GridColumnConfiguration): DataGridColumnDefinition<HitResult> {
    //if there is no column configuration for this column, it should never be visible on redacted rows - needs to be explicitly asked for.
    if (columnConfiguration?.isVisibleOnRedactedRows) {
        return column;
    } else {
        const result = { ...column };

        //We want to make sure all custom Cell display formats still use the redacted value rendering from the default display value function.
        //If there is a cell already, that means we have provided a custom one and it wont use defaultCellDisplayValue, so we need to wrap it.
        if (result.Cell) {
            const internalCell = result.Cell;
            result.Cell = (cellProps) => {
                if (cellProps.cell.value === "**Redacted**") {
                    return defaultCellDisplayValue(cellProps.cell.value);
                } else {
                    return internalCell(cellProps);
                }
            };
        }

        return result;
    }
}

export default ResultColumnDefinitions;
