import { Container, CosmosClient, CreateOperationInput, DeleteOperationInput, ReplaceOperationInput, Response, StatusCodes } from "@azure/cosmos";
import { getEntityStoreCosmosSecrets } from "../SecretConnectors/TenantSecret/TenantSecretConnector";
import { emptyOkResponse, EmptyOkResponse, MultiResponse } from "../Http/HttpResponse";
import { CosmosConnector } from "../CosmosConnector/CosmosConnector";
import { CosmosSecrets, EntityDocument, EntityIdentifier, notFound, NotFound, UnexpectedError, unexpectedError } from "aderant-conflicts-models";
import { Logger } from "@aderant/aderant-web-fw-core";
import { GetEntityDocumentsResults } from "../APIs/EntityStore";
import _ from "lodash";
// Justification:  Does not seem to be exported in a way that lets import work
// eslint-disable-next-line @typescript-eslint/no-var-requires
const retry = require("async-retry");

export interface LookupDataConnector {
    doesEntityExist(recordId: string, partitionKey: string): Promise<boolean>;
    getEntityDocuments(entityIdentifiers: EntityIdentifier[]): Promise<GetEntityDocumentsResults>;
    createEntities(records: any[], entityType: string): Promise<EmptyOkResponse | UnexpectedError>;
    updateEntities(records: any[], entityType: string): Promise<EmptyOkResponse | UnexpectedError | NotFound>;
    deleteEntities(recordIds: string[], entityType: string): Promise<EmptyOkResponse | UnexpectedError | NotFound>;
    createContainerIfNotExist(entityType: string): Promise<void>;
    deleteContainer(): Promise<void>;
}

const HTTP_ERROR_BATCHOP_NOT_PROCESSED = 424; //Thrown when another request in the batch fails.

const batchResponseErrors = (expectedCodes: number[], responses: { statusCode: number; requestCharge: number }[]) => {
    return responses.filter((response) => !expectedCodes.some((code) => response.statusCode === code) && response.statusCode !== HTTP_ERROR_BATCHOP_NOT_PROCESSED);
};

/**
 * This class writes to and/or deletes from the lookup data container in batches of up to 100.
 */
export class CosmosLookupDataConnector extends CosmosConnector implements LookupDataConnector {
    private readonly client: CosmosClient;
    private readonly containerName: string = "EntityLookup";
    private readonly entityTypePropertyName: string = "entityType";
    private container: Container | null = null;
    private cosmosSecrets: CosmosSecrets | undefined;

    private constructor(logger: Logger, client: CosmosClient, cosmosSecrets?: CosmosSecrets) {
        super(logger);

        this.client = client;
        this.cosmosSecrets = cosmosSecrets;
    }

    async getEntityDocuments(entityIdentifiers: EntityIdentifier[]): Promise<GetEntityDocumentsResults> {
        try {
            const entitiesByGroup = _.groupBy(entityIdentifiers, (h) => h.entityType);
            const responses: MultiResponse.Item<EntityDocument, 200, NotFound>[] = [];
            const entityTypes = Object.keys(entitiesByGroup);

            for (const entityType of entityTypes) {
                const entityIds: string[] = entitiesByGroup[entityType].map((entId: EntityIdentifier) => entId.id);
                const cosmosResponse = await this.queryEntitiesByType<EntityDocument & { _ts: number }>(entityIds, entityType);
                const entityDocumentById = new Map<string, EntityDocument & { _ts: number }>(cosmosResponse.map((entityDoc) => [entityDoc.id, entityDoc]));

                const entityResults: MultiResponse.Item<EntityDocument, 200, NotFound>[] = entityIds.map((id: string) => {
                    const entity = entityDocumentById.has(id) ? entityDocumentById.get(id) : undefined;
                    if (entity) {
                        return {
                            id: id,
                            status: 200,
                            body: { id: entity.id, entityType: entityType, deltaServiceTimeStamp: entity.deltaServiceTimeStamp, lastModified: new Date(entity._ts * 1000) },
                            entityType: entityType
                        };
                    }
                    return {
                        id: id,
                        status: 404,
                        body: notFound(),
                        entityType: entityType
                    };
                });
                responses.push(...entityResults);
            }
            return responses;
        } catch (e) {
            this.wrapWithUnexpectedErrorAndThrow(e);
        }
    }

    async createContainerIfNotExist(entityType: string): Promise<void> {
        await this.getContainer();
    }

    static async openConnection(logger: Logger, firmSettings: CosmosSecrets, client?: CosmosClient): Promise<CosmosLookupDataConnector> {
        logger.info("Opening connector: Cosmos");
        try {
            const cosmosClient = client ?? this.createCosmosClient(logger, firmSettings);
            return new CosmosLookupDataConnector(logger, cosmosClient, firmSettings);
        } catch (e) {
            throw unexpectedError(e, "Unexpected error when opening Cosmos connection in LookupDataConnector.openConnection().");
        }
    }

    private async getContainer() {
        if (this.container) {
            this.logger.debug(`Container already opened.`);
            return this.container;
        }
        if (!process.env.TENANCY_NAME) {
            throw unexpectedError("No tenancyName in process environment.", "get Container in LookupDataConnector.");
        }
        this.logger.debug(`Opening connection to container.`);
        try {
            const container = await CosmosLookupDataConnector.getContainer(this.client, this.cosmosSecrets ?? (await getEntityStoreCosmosSecrets(process.env.TENANCY_NAME, this.logger)), {
                id: this.containerName,
                partitionKey: `/${this.entityTypePropertyName}`
            });
            return container;
        } catch (e: any) {
            throw unexpectedError(e, `Unexpected error when opening container ${this.containerName} in LookupDataConnector.getContainer().`);
        }
    }

    async doesEntityExist(recordId: string, entityType: string): Promise<boolean> {
        const container = await this.getContainer();

        const response = await container.item(recordId, entityType).read<any>();
        if (response.statusCode === StatusCodes.NotFound) {
            return false;
        }
        if (response.statusCode === StatusCodes.Ok) {
            return true;
        }
        throw unexpectedError(response, "LookupDataConnector.doesEntityExist() failed with unexpected error code " + response.statusCode);
    }

    private async queryEntitiesByType<T>(ids: string[], entityType: string) {
        const results: T[] = [];
        const container = await this.getContainer();
        const stringIds = ids.map((id) => id.toString()); // Justification:  Cosmos DB requires string ids - this was coming in as a number array, even when it has a type of string array
        const query = container.items.query<T>(
            {
                query: `SELECT s.id, s.entityType, s.deltaServiceTimeStamp, s._ts FROM items s where ARRAY_CONTAINS(@ids, s.id)`,
                parameters: [{ name: "@ids", value: stringIds }]
            },
            { partitionKey: entityType }
        );

        while (query.hasMoreResults()) {
            const continuationResults = await query.fetchNext();
            results.push(...continuationResults.resources);
        }

        return results;
    }

    async createEntities(records: EntityDocument[], entityType: string): Promise<EmptyOkResponse> {
        const container = await this.getContainer();

        const actions: CreateOperationInput[] = records.map((record: any) => {
            return {
                operationType: "Create",
                resourceBody: record
            };
        });

        return await this.executeBatchWithRetry(container, actions, entityType, "create");
    }

    async updateEntities(records: EntityDocument[], entityType: string): Promise<EmptyOkResponse | NotFound> {
        const container = await this.getContainer();

        const actions: ReplaceOperationInput[] = records.map((record: any) => {
            return {
                operationType: "Replace",
                resourceBody: record,
                id: record.id.toString()
            };
        });

        return await this.executeBatchWithRetry(container, actions, entityType, "update");
    }

    async deleteEntities(recordIds: string[], entityType: string): Promise<EmptyOkResponse | NotFound> {
        const container = await this.getContainer();

        const actions: DeleteOperationInput[] = recordIds.map((recordId: string) => {
            return {
                operationType: "Delete",
                id: recordId
            };
        });

        return await this.executeBatchWithRetry(container, actions, entityType, "delete");
    }

    async deleteContainer(): Promise<void> {
        const container = await this.getContainer();
        await container.delete();
    }

    private async executeBatchWithRetry(container: Container, actions: any, entityType: string, operation: "create" | "update" | "delete"): Promise<EmptyOkResponse> {
        return await retry(
            async () => {
                const response: Response<any> = await container.items.batch(actions, entityType);

                return this.handleResponse(response, operation);
            },
            {
                retries: 5
            }
        );
    }

    private handleResponse(response: Response<any>, operation: "create" | "update" | "delete") {
        const expectedStatusCodes = (() => {
            switch (operation) {
                case "create":
                    return [StatusCodes.Created, StatusCodes.Ok];
                case "update":
                    return [StatusCodes.Accepted, StatusCodes.Ok];
                case "delete":
                    return [StatusCodes.NoContent, StatusCodes.Ok];
                default:
                    return [StatusCodes.Ok];
            }
        })();

        const errors = batchResponseErrors(expectedStatusCodes, response.result);
        if (errors.length > 0) {
            throw unexpectedError(response, `LookupDataConnector Cosmos container responded with unexpected response code (${errors[0].statusCode}) when performing batch ${operation}`);
        }

        return emptyOkResponse();
    }
}
