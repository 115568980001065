import { Context } from "@azure/functions";
import { QueueClient, QueueServiceClient } from "@azure/storage-queue";
import { BasicCurrentUserContext, ConnectionContext, validateQueueMessage, QueueMessage, Result, unexpectedError, WithId } from "aderant-conflicts-models";
import { AzureFunctionLogger, AppInsightsClient } from "@aderant/aderant-web-fw-azfunctions";
import { v4 as uuid } from "uuid";
import { HttpBody } from "../..";
import { QueueTriggerFunctionAppContext } from "../ConflictsContext";
import { InvalidQueueMessage, messageTooBig } from "./AzureQueueErrors";
import { extractSearchLogContext } from "./shared/server/extractSearchLogContext";
import { buildDependencies, Unbuilt } from "./shared/server/FunctionAppDependency";

export class AzureQueueFunctionDefinition<Message extends Extract<HttpBody, Record<string, any>> & { id?: string }> {
    private queueName: string;
    private bindingVariableName: string;
    private queueClient?: QueueClient;

    //Note: only use the optional queueClient parameter for testing to pass a mockQueueClient
    /**
     * @param input Requires queueName and bindingVariableName. bindingVariableName should match the input binding name in the function.json of the queue trigger
     * and will be used to fetch the queue message contents from the azure context
     * @param queueClient
     */
    constructor(input: { queueName: string; bindingVariableName: string }, queueClient?: QueueClient) {
        this.queueName = input.queueName;
        this.bindingVariableName = input.bindingVariableName;
        this.queueClient = queueClient;
    }

    /**
     * Function to wrap a business logic implementation with necessary Azure Functions result handling, instrumentation etc
     * @param azureContext Azure context from your Azure Function entry point
     * @param internalImpl ```(context: ConflictsContext, input: In, ...dependencies: Deps) => Promise<Success | Err>``` - the implementation of your business logic.
     * @returns ```(input: In, ...dependencies: Deps) => Promise<void>``` - this function should be called in your Azure Function entry point, handles setting Azure context response, instrumentation etc for you.
     */
    public getImplementation<Deps extends Array<unknown>>(
        azureContext: Context,
        appInsightsClient: AppInsightsClient,
        internalImpl: (context: QueueTriggerFunctionAppContext, message: WithId<Message>, ...dependencies: Deps) => Promise<void>,
        logContext?: Record<string, string>
    ): (...dependencies: Unbuilt<Deps, QueueTriggerFunctionAppContext>) => Promise<void> {
        const logger = new AzureFunctionLogger(azureContext.log, appInsightsClient);
        logContext = logContext || {};
        logger.setLogProperties(logContext); //Set initial properties, tenancyName comes after we parse it
        logger.debug("Running initial context setup (AzureQueueFunctionDefinition.getImplementation).");

        const message = validateQueueMessage(azureContext.bindings[this.bindingVariableName], this.bindingVariableName, logger);

        let rehydratedMessage: WithId<unknown>;
        //rehydrate user provided message body with id
        if (typeof message.input === "object") {
            rehydratedMessage = { ...message.input, id: message.id };
        } else {
            throw unexpectedError("Expected message body to be an object", "AzureQueueFunctionDefinition.getImplementation");
        }

        appInsightsClient.setTelemetryCorrelationInfo(message); //we need to manually populate log correlation stuff here as it's not automatically carried across message queue boundaries

        logger.setLogProperty("tenancy", message.requestingUniqueTenancyName);
        logger.setLogProperties(extractSearchLogContext(rehydratedMessage));
        const context: QueueTriggerFunctionAppContext = new QueueTriggerFunctionAppContext(logger, message, azureContext, appInsightsClient);

        return async (...dependencies: Unbuilt<Deps, QueueTriggerFunctionAppContext>) => {
            try {
                //TODO(896, 2692): standardize/automate type correctness validation on Azure functions
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                await internalImpl(context, rehydratedMessage as WithId<Message>, ...(await buildDependencies(dependencies, context)));
            } catch (e) {
                //there's probably more instrumentation/stuff we should be doing here, but lets just warn for now (not error, reserve that for when it fails in the poison queue).
                context.logger.warn("Uncaught error when processing queue message, rethrowing so the message can be added back to the queue and retried.");
                throw e;
            }
        };
    }

    private async enqueueMessage(
        context: ConnectionContext & BasicCurrentUserContext,
        queueClient: QueueClient,
        queueName: string
    ): Promise<(message: Message) => Promise<Result<void, InvalidQueueMessage>>> {
        const telemetryCorrelationInfo = context.appInsightsClient.getTelemetryCorrelationInfo();

        return async (message: Message) => {
            const withUserContext: QueueMessage<Message> = {
                id: message.id ?? uuid(),
                operationId: telemetryCorrelationInfo.operationId,
                requestingUserId: context.currentUser.id,
                requestingUniqueTenancyName: context.currentUser.tenancy.uniqueName,
                input: { ...message, id: undefined } //strip caller provided id out of the message object if it's there so we don't bloat message size by including it twice
            };
            const payload = Buffer.from(JSON.stringify(withUserContext)).toString("base64");
            const payloadLength = payload.length;
            if (payloadLength > 64000) {
                return messageTooBig(withUserContext, queueName, payloadLength);
            }
            context.logger.debug("Sending message with length %i on queue named %s.", payloadLength, queueName);
            queueClient.sendMessage(payload);
            return;
        };
    }

    public async getAppEnqueueMessage(context: ConnectionContext & BasicCurrentUserContext): Promise<(message: Message) => Promise<Result<void, InvalidQueueMessage>>> {
        // Do not cache these queues as me may end up placing a message on the wrong queue (i.e this.queueClient = ....)
        const queueClient = this.queueClient ?? (await getAppQueueClient(context, this.queueName));
        return this.enqueueMessage(context, queueClient, this.queueName);
    }

    public async getFirmEnqueueMessage(context: ConnectionContext & BasicCurrentUserContext): Promise<(message: Message) => Promise<Result<void, InvalidQueueMessage>>> {
        // Do not cache these queues as me may end up placing a message on the wrong queue (i.e this.queueClient = ....)
        const queueClient = await getFirmQueueClient(context, this.queueName);
        return this.enqueueMessage(context, queueClient, this.queueName);
    }
}

async function getAppQueueClient(context: ConnectionContext, queueName: string): Promise<QueueClient> {
    context.logger.debug("Creating queue client for queue named %s.", queueName);
    const queueServiceClient = QueueServiceClient.fromConnectionString(await context.getSharedBlobStorageConnectionString());
    const queueClient = queueServiceClient.getQueueClient(queueName);
    await queueClient.createIfNotExists();
    return queueClient;
}

async function getFirmQueueClient(context: ConnectionContext, queueName: string): Promise<QueueClient> {
    context.logger.debug("Creating queue client for queue named %s.", queueName);
    const queueServiceClient = QueueServiceClient.fromConnectionString((await context.getBlobStorageSecrets()).connectionString);
    const queueClient = queueServiceClient.getQueueClient(queueName);
    await queueClient.createIfNotExists();
    return queueClient;
}
