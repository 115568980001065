import { DefaultAzureCredential } from "@azure/identity";
import { SecretClient } from "@azure/keyvault-secrets";
import { Logger } from "@aderant/aderant-web-fw-core";

function getKeyVaultName() {
    return process.env.SUPPORTAL_KEYVAULT_NAME ?? "";
}

export type MonitorApiConnectionDetails = {
    baseUrl: URL;
    functionKey: string;
};

async function getSecretAsJSONFromKeyVault(secretName: string, logger: Logger) {
    const credential = new DefaultAzureCredential();

    const keyVaultName = getKeyVaultName();

    const url = `https://${keyVaultName}.vault.azure.net`;
    const client = new SecretClient(url, credential);
    logger.debug(`getSecretAsJSONFromKeyVault - keyVaultName: ${keyVaultName}, secretName: ${secretName}`);

    const secretString = (await client.getSecret(secretName)).value;

    if (!secretString || secretString === "") {
        throw "Value of secret object is null";
    }
    return JSON.parse(secretString);
}

export async function getMonitorApiDetailsAsJSON(regionResourceGroupName: string, logger: Logger): Promise<MonitorApiConnectionDetails> {
    logger.debug(`getMonitorApiDetailsAsJSON - regionResourceGroupName: ${regionResourceGroupName}`);
    const secretName = `${regionResourceGroupName}-monitor-api-connectionDetails`;

    return getSecretAsJSONFromKeyVault(secretName, logger);
}
