import React from "react";
import { aderantColors, formatShortDateTimeDisplay, InputText, PickList, SingleSelectValue } from "@aderant/aderant-react-components";
import { User, SearchMessages, SearchVersion, SearchStatuses } from "aderant-conflicts-models";
import { DataChanged } from "./ResultDetailDialog";
import { useSelector } from "react-redux";
import { RootState } from "MyTypes";
import { getUserName } from "state/selectors/appSelectors";
import { nameof } from "@aderant/aderant-web-fw-core";
import { Grid } from "@mui/material";
import { AssignedToUserPickList } from "components/AssignedToUserPickList/AssignedToUserPickList";
import { messages as searchEditMessages } from "pages/SearchEditPage/SearchUtils";
import { isMessageInvalid } from "components/ReassignUserModal/ReassignUser";
import { useFirmSetting } from "state/selectors";
export interface DetailsSelectionProps {
    style?: React.CSSProperties;
    searchVersion: SearchVersion;
    onChange: (dirty: DataChanged) => void;
    canChangeAssignedTo: boolean;
    canChangeRequestStatus: boolean;
    searchStatusOptions: {
        label: string;
        value: string;
    }[];
    newAssignedToUser?: User | null;
    newSearchRequestStatus: SingleSelectValue | null;
    focusedPropertyName?: string;
    reassignMessage?: string;
    statusNotificationMessage?: string;
    isReassignMessageFieldVisible?: boolean;
    isStatusNotificationMessageVisible?: boolean;
}

export const DetailsSelection = ({
    searchVersion,
    style,
    onChange,
    canChangeAssignedTo,
    canChangeRequestStatus,
    searchStatusOptions,
    newAssignedToUser,
    newSearchRequestStatus,
    reassignMessage,
    statusNotificationMessage,
    focusedPropertyName,
    isReassignMessageFieldVisible,
    isStatusNotificationMessageVisible
}: DetailsSelectionProps): JSX.Element => {
    const handleAssignedToUserChange = (user?: User | null) => {
        onChange({
            assignedToUserId: user,
            searchStatus: newSearchRequestStatus,
            reassignMessage: "", //Clear out the reassign message when assigned to user changes
            statusNotificationMessage: statusNotificationMessage
        });
    };

    const handleRequestStatusChange = (requestStatus: SingleSelectValue | null) => {
        onChange({
            assignedToUserId: newAssignedToUser,
            searchStatus: requestStatus ? { label: requestStatus.label, value: requestStatus.value } : null,
            reassignMessage: reassignMessage,
            statusNotificationMessage: requestStatus?.value == newSearchRequestStatus?.value ? statusNotificationMessage : "" //Clear out the status change message when status changes
        });
    };

    const handleReassignMessageChange = (message: string | null) => {
        onChange({
            assignedToUserId: newAssignedToUser,
            searchStatus: newSearchRequestStatus,
            reassignMessage: message || undefined,
            statusNotificationMessage: statusNotificationMessage
        });
    };

    const handleStatusNotificationMessageChange = (message: string | null) => {
        onChange({
            assignedToUserId: newAssignedToUser,
            searchStatus: newSearchRequestStatus,
            reassignMessage: reassignMessage,
            statusNotificationMessage: message || undefined
        });
    };

    const createdByUser = useSelector((rootState: RootState) => {
        return getUserName(searchVersion.createdByUserId, rootState.app.users ?? []);
    });

    const requestedByUser = useSelector((rootState: RootState) => {
        return getUserName(searchVersion.requestedByUserId, rootState.app.users ?? []);
    });

    const approverUser = useSelector((rootState: RootState) => {
        return getUserName(searchVersion.approverUserId ?? undefined, rootState.app.users ?? []);
    });

    const displayApproverField = useFirmSetting("firm-options/general/isApproverFieldEnabled", false);

    const leftHalfWidthFieldStyle = { marginLeft: -14, marginRight: 28 };
    const rightHalfWidthFieldStyle = { marginLeft: 14 };
    const fullWidthFieldStyle = { width: "calc(100% + 14px)", marginLeft: -14 };

    // Both of these change if both message fields are visible at the same time
    const messageFieldWidth = isStatusNotificationMessageVisible && isReassignMessageFieldVisible ? 6 : 12;
    const reassignMessageFieldStyle = isStatusNotificationMessageVisible && isReassignMessageFieldVisible ? { ...leftHalfWidthFieldStyle, width: "100%" } : fullWidthFieldStyle;
    const statusMessageFieldStyle = isStatusNotificationMessageVisible && isReassignMessageFieldVisible ? { ...rightHalfWidthFieldStyle, width: "100%" } : fullWidthFieldStyle;

    const detailsStyles = {
        detail: { display: "flex", marginRight: 28 },
        label: { minWidth: "180px", color: aderantColors.DeathStar },
        value: { color: aderantColors.DarthVader },
        description: { display: "flex", marginTop: "20px" },
        descriptionValue: { color: aderantColors.DarthVader, overflowY: "auto", height: 200 }
    } as const;

    const getDescriptionWithNewLines = (description: string): JSX.Element[] => {
        return description.split("\n").map((paragraph, index) => (
            <p style={{ marginTop: 0 }} key={index}>
                {paragraph}
            </p>
        ));
    };
    return (
        <div style={{ margin: 30, marginTop: 0 }}>
            <Grid container>
                <Grid container style={{ ...style, display: "flex", justifyContent: "space-between" }}>
                    <Grid item xs={6}>
                        <AssignedToUserPickList
                            id="details-selection-select-assigned-to-user"
                            disabled={!canChangeAssignedTo}
                            label={SearchMessages.ASSIGNED_TO.getMessage()}
                            style={leftHalfWidthFieldStyle}
                            onUserChanged={(user) => handleAssignedToUserChange(user)}
                            selectedUserId={newAssignedToUser?.id}
                            autofocus={focusedPropertyName == nameof<SearchVersion>("assignedToUserId")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PickList
                            id="details-selection-select-request-status"
                            value={newSearchRequestStatus}
                            disabled={!canChangeRequestStatus}
                            style={{ ...rightHalfWidthFieldStyle, textTransform: "capitalize" }}
                            selectOptions={searchStatusOptions}
                            label={SearchMessages.REQUEST_STATUS.getMessage()}
                            onChange={(e, value) => (value ? handleRequestStatusChange(value) : null)}
                            autofocus={focusedPropertyName == nameof<SearchVersion>("status")}
                        />
                    </Grid>
                    <Grid item xs={messageFieldWidth} display={isReassignMessageFieldVisible ? "inherit" : "none"}>
                        <InputText
                            id="details-selection-reassign-message"
                            label={searchEditMessages.reassignMessage}
                            value={reassignMessage || ""}
                            disabled={!isReassignMessageFieldVisible}
                            multiLine
                            hasError={isMessageInvalid(reassignMessage || "")}
                            maxLines={5}
                            errorMessage={isMessageInvalid(reassignMessage || "") ? searchEditMessages.messageTooLong : ""}
                            onChange={(e, value) => (value ? handleReassignMessageChange(value) : null)}
                            placeholder={newAssignedToUser ? searchEditMessages.enterMessageToBeEmailedOnReassign : searchEditMessages.enterMessageToBeSavedToAudit}
                            style={reassignMessageFieldStyle}
                        />
                    </Grid>
                    <Grid item xs={messageFieldWidth} display={isStatusNotificationMessageVisible ? "inherit" : "none"}>
                        <InputText
                            id="details-selection-status-notification-message"
                            label={searchEditMessages.statusNotificationMessage}
                            value={statusNotificationMessage || ""}
                            disabled={!isStatusNotificationMessageVisible}
                            multiLine
                            hasError={isMessageInvalid(statusNotificationMessage || "")}
                            maxLines={5}
                            errorMessage={isMessageInvalid(statusNotificationMessage || "") ? searchEditMessages.messageTooLong : ""}
                            onChange={(e, value) => (value ? handleStatusNotificationMessageChange(value) : null)}
                            placeholder={
                                newSearchRequestStatus?.value === SearchStatuses.InReview ? searchEditMessages.enterMessageToBeSavedToAudit : searchEditMessages.enterMessageToBeEmailedOnStatusChange
                            }
                            style={statusMessageFieldStyle}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} style={{ marginLeft: 2, marginRight: -28 }}>
                        <div style={detailsStyles.detail}>
                            <div data-testid="search-request-number-label" style={detailsStyles.label}>
                                {SearchMessages.SEARCH_REQUEST_NUMBER.getMessage()}:
                            </div>
                            <div data-testid="search-request-number-value" style={detailsStyles.value}>
                                {searchVersion.number}
                            </div>
                        </div>
                        <div style={detailsStyles.detail}>
                            <div data-testid="version-number-label" style={detailsStyles.label}>
                                {SearchMessages.VERSION_NUMBER.getMessage()}:
                            </div>
                            <div data-testid="version-number-value" style={detailsStyles.value}>
                                {searchVersion.version}
                            </div>
                        </div>
                        <div style={detailsStyles.detail}>
                            <div data-testid="created-on-label" style={detailsStyles.label}>
                                {SearchMessages.CREATED_ON.getMessage()}:
                            </div>
                            <div data-testid="created-on-value" style={detailsStyles.value}>
                                {formatShortDateTimeDisplay(searchVersion.searchDate)}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} style={{ marginLeft: 57, marginRight: -56 }}>
                        <div style={detailsStyles.detail}>
                            <div data-testid="requested-by-label" style={detailsStyles.label}>
                                {SearchMessages.REQUESTED_BY.getMessage()}:
                            </div>
                            <div data-testid="requested-by-value" style={detailsStyles.value}>
                                {requestedByUser}
                            </div>
                        </div>
                        <div style={detailsStyles.detail}>
                            <div data-testid="created-by-label" style={detailsStyles.label}>
                                {SearchMessages.CREATED_BY.getMessage()}:
                            </div>
                            <div data-testid="created-by-value" style={detailsStyles.value}>
                                {createdByUser}
                            </div>
                        </div>
                        {displayApproverField && (
                            <div style={detailsStyles.detail}>
                                <div data-testid="approver-label" style={detailsStyles.label}>
                                    {SearchMessages.APPROVER.getMessage()}:
                                </div>
                                <div data-testid="approver-value" style={detailsStyles.value}>
                                    {approverUser}
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Grid container style={{ marginLeft: 2 }}>
                    <Grid item xs={12}>
                        <div style={detailsStyles.description}>
                            <div data-testid="description-label" style={detailsStyles.label}>
                                {SearchMessages.DESCRIPTION.getMessage()}:
                            </div>
                            <div data-testid="description-value" style={detailsStyles.descriptionValue}>
                                {searchVersion.description ? getDescriptionWithNewLines(searchVersion.description) : ""}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
