import { Logger } from "@aderant/aderant-web-fw-core";
import { getContext, GetContextEffect } from "redux-saga/effects";
import { SearchService } from "services/searchService";
import { Services } from "services/Services";
import { SearchStorageService } from "services/storageService";
import { UserService } from "services/userService";

export type ConflictsSagaContext = {
    logger: Logger;
    services: Services;
};

function* getSagaContext() {
    const context: ConflictsSagaContext = yield getContext("conflictsSagaContext");
    if (!context || !context?.services) {
        throw new Error(
            "Tried to access saga context, but it was not there. Make sure it was initialized before any sagas started running. (Should currently be initialized in onMount of root App component if this log message isn't out of date :) )"
        );
    }
    return context;
}

export function* getStorageService(): Generator<GetContextEffect, SearchStorageService, ConflictsSagaContext> {
    const context: ConflictsSagaContext = yield* getSagaContext();
    return context.services.searchStorageService;
}

export function* getSearchService(): Generator<GetContextEffect, SearchService, ConflictsSagaContext> {
    const context: ConflictsSagaContext = yield* getSagaContext();
    return context.services.searchService;
}

export function* getUserService(): Generator<GetContextEffect, UserService, ConflictsSagaContext> {
    const context: ConflictsSagaContext = yield* getSagaContext();
    return context.services.userService;
}

export function* getLogger(): Generator<GetContextEffect, Logger, ConflictsSagaContext> {
    const context: ConflictsSagaContext = yield* getSagaContext();
    return context.logger;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getEntityStorageService() {
    const context: ConflictsSagaContext = yield* getSagaContext();
    return context.services.entityStorageService;
}

export function* getAdminService() {
    const context: ConflictsSagaContext = yield* getSagaContext();
    return context.services.adminService;
}
