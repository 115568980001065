import { RefinerGroup, RefinerOption, DateRange } from "@aderant/aderant-react-components";
import { PageRefinerPayload, ViewByRefiner } from "state/reducers/ReducerStateTypes";
import { action } from "typesafe-actions";

export const refinerActionTypes = {
    SET_REFINER_GROUPS: "SET_REFINER_GROUPS",
    SET_VIEW_BY_REFINERS: "SET_VIEW_BY_REFINERS",
    UPDATE_REFINER_SELECTION: "UPDATE_REFINER_SELECTION",
    REFRESH_REFINER_SELECTION: "REFRESH_REFINER_SELECTION",

    CLEAR_SELECTED_REFINERS: "CLEAR_SELECTED_REFINERS",
    CLEAR_REFINED_RESULTS: "CLEAR_REFINED_RESULTS",

    UPDATE_VIEW_BY_REFINERS: "UPDATE_VIEW_BY_REFINERS"
} as const;

export const refinerActions = {
    setRefinerGroups: (refinerGroups: RefinerGroup[]): { type: "SET_REFINER_GROUPS"; payload: RefinerGroup[] } => action(refinerActionTypes.SET_REFINER_GROUPS, refinerGroups),
    setViewByRefiners: (payload: ViewByRefiner[]): { type: "SET_VIEW_BY_REFINERS"; payload: ViewByRefiner[] } => action(refinerActionTypes.SET_VIEW_BY_REFINERS, payload),
    updateRefinerSelection: (payload: {
        refinerOption: RefinerOption | DateRange;
        path: string;
        isSelected: boolean;
        pageRefiner: PageRefinerPayload;
    }): { type: "UPDATE_REFINER_SELECTION"; payload: { refinerOption: RefinerOption | DateRange; path: string; isSelected: boolean; pageRefiner: PageRefinerPayload } } =>
        action(refinerActionTypes.UPDATE_REFINER_SELECTION, payload),
    //This is for updating the numbers without having to go through an onclick event.
    refreshRefinerSelection: (payload: PageRefinerPayload): { type: "REFRESH_REFINER_SELECTION"; payload: PageRefinerPayload } => action(refinerActionTypes.REFRESH_REFINER_SELECTION, payload),
    clearSelectedRefiners: (payload: PageRefinerPayload): { type: "CLEAR_SELECTED_REFINERS"; payload: PageRefinerPayload } => action(refinerActionTypes.CLEAR_SELECTED_REFINERS, payload),
    clearRefinedResults: (): { type: "CLEAR_REFINED_RESULTS" } => action(refinerActionTypes.CLEAR_REFINED_RESULTS),

    updateViewByRefiners: (payload: {
        viewByRefiner: ViewByRefiner;
    }): {
        type: "UPDATE_VIEW_BY_REFINERS";
        payload: {
            viewByRefiner: ViewByRefiner;
        };
    } => action(refinerActionTypes.UPDATE_VIEW_BY_REFINERS, payload)
};
