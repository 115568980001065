import { OmitStrict } from "@aderant/aderant-web-fw-core";
import { QuickSearch } from "./QuickSearch";
import { SearchSummary, getSummaryErrorStatus } from "./SearchSummary";

export type QuickSearchSummary = OmitStrict<SearchSummary, "isQuickSearch"> & {
    isQuickSearch: true;
    isLatestVersion: true;
};

export function getQuickSearchSummary(searchSummaryName: string, searchVersion: QuickSearch): QuickSearchSummary {
    return {
        name: searchSummaryName,
        number: searchVersion.number,
        version: searchVersion.version,
        id: searchVersion.searchId,
        versionId: searchVersion.id,
        createdByUserId: searchVersion.createdByUserId,
        requestedByUserId: searchVersion.requestedByUserId,
        assignedToUserId: searchVersion.assignedToUserId,
        status: searchVersion.status,
        isLatestVersion: searchVersion.isLatestVersion,
        editState: searchVersion.editState,
        searchDate: searchVersion.searchDate,
        createdOn: searchVersion.createdOn,
        submittedDate: searchVersion.submittedDate,
        summary: searchVersion.summary,
        searchEtag: searchVersion._etag,
        errorStatus: getSummaryErrorStatus(searchVersion),
        lastModified: searchVersion.lastModified,
        apiMetadata: searchVersion.apiMetadata,
        isQuickSearch: true
    };
}
