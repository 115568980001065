import { CircularProgress, Link } from "@mui/material";
import { isSearchedStatus, SearchStatus, SearchStatuses } from "aderant-conflicts-models";
import { aderantColors, DataGridCellProps, DataGridColumnDefinition, DisplayChip, GridCellValue, GridRowValues } from "@aderant/aderant-react-components";
import { ConsoleLogger } from "@aderant/aderant-web-fw-applications";
import { Logger } from "@aderant/aderant-web-fw-core";
import { FailedSearchIcon } from "components/Icons/FailedSearchIcon";
import { ResolutionStatusProgressBar } from "components/ResolutionStatusProgressBar/ResolutionStatusProgressBar";
import { SearchOptionsComboBox } from "components/SearchOptionsComboBox/SearchOptionsComboBox";
import { compareSearchNumbers } from "Functions/search";
import { default as React, ReactElement } from "react";
import { useHistory } from "react-router";
import { conflictsPalette } from "styles/conflictsPalette";
import { SearchRequestRow } from "./SearchRequestRow";
import { Messages } from "./Messages";

const logger: Logger = new ConsoleLogger();

//This is the temporary name of the search until we have a name property
const getTitleForRow = ({ row: { original: search } }: DataGridCellProps<SearchRequestRow, GridCellValue>): ReactElement => {
    const history = useHistory();
    const openSearch = (search?: SearchRequestRow, event?: React.MouseEvent) => {
        if (event) {
            event.stopPropagation();
        }
        if (search) {
            if (isSearchedStatus(search.statusEnum)) {
                history.push(`/results/${search.id}`);
            } else if (search.isQuickSearch) {
                history.push(`/search/${search.id}/quick-search`);
            } else {
                history.push(`/search/${search.id}`);
            }
        } else {
            console.error("Unable to open search, search is either null or undefined.");
        }
    };

    let failedSearchText: string | undefined;
    const showFailedSearchIcon = search.errorStatus && search.errorStatus.isErrored;
    if (showFailedSearchIcon && search.errorStatus.errorCode === "VLD_INVALID_SEARCH_QUERY") {
        failedSearchText = Messages.SEARCH_FAILED_INCORRECT_SYNTAX.getMessage();
    }
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Link
                disabled={search.statusEnum === SearchStatuses.Searching}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => openSearch(search, e)}
                underline="hover"
                component="button"
                fontFamily="inherit"
                style={{
                    width: "100%",
                    height: "100%",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                    fontSize: "0.812rem",
                    color: conflictsPalette.text.hyperlink,
                    whiteSpace: "nowrap"
                }}
            >
                {search.name}
            </Link>
            {showFailedSearchIcon && <FailedSearchIcon title={failedSearchText} />}
            <SearchOptionsComboBox
                searchInfo={{ searchId: search.id, versionId: search.versionId, isQuickSearch: search.isQuickSearch, assignedToUserId: search.assignedToUser.id, searchStatus: search.statusEnum }}
                iconSize={4}
                variant={"plain"}
            />
        </div>
    );
};

const getProgressDisplayForRow = ({ row: { original: search } }: DataGridCellProps<SearchRequestRow, GridCellValue>): ReactElement => {
    const searchRow: SearchRequestRow = search;
    return (
        <ResolutionStatusProgressBar
            style={{ width: "10.312rem", display: isSearchedStatus(searchRow.statusEnum) ? "inline-block" : "none", verticalAlign: "middle", lineHeight: "1rem" }}
            total={searchRow?.totalHits ?? 0}
            progress={searchRow?.progressedHits ?? 0}
            alignment="left"
        />
    );
};

const getSearchStatusColours = (status: SearchStatus) => {
    switch (status) {
        case "REJECTED":
            return { displayChipColor: conflictsPalette.status.redLight, displayChipBorderColor: conflictsPalette.status.red };
        case "SEARCHED":
            return { displayChipColor: conflictsPalette.status.blueLight, displayChipBorderColor: conflictsPalette.status.blue };
        case "APPROVED":
            return { displayChipColor: conflictsPalette.status.greenLight, displayChipBorderColor: conflictsPalette.status.green };
        case "INREVIEW":
            return { displayChipColor: conflictsPalette.status.yellowLight, displayChipBorderColor: conflictsPalette.status.yellow };
        case "SUBMITTED":
            return { displayChipColor: conflictsPalette.status.greyLight, displayChipBorderColor: conflictsPalette.status.grey };
        case "DRAFT":
            return { displayChipColor: conflictsPalette.status.orangeLight, displayChipBorderColor: conflictsPalette.status.orange };
        case "CONDITIONALAPPROVAL":
            return { displayChipColor: conflictsPalette.status.purpleLight, displayChipBorderColor: conflictsPalette.status.purple };
        case "SEARCHING":
            return { displayChipColor: conflictsPalette.status.greyLight, displayChipBorderColor: conflictsPalette.text.black }; //Shouldn't be rendered in the grid
    }
};
const searchStatusSearching = (
    <div>
        <CircularProgress aria-label="Search in progress" size={20} thickness={8} style={{ color: aderantColors.DeathStar, marginTop: "7%" }} />{" "}
    </div>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getSearchStatusDisplayChip = ({ row: { original: search } }): JSX.Element => {
    if (search.status === "Searching") {
        return searchStatusSearching;
    } else {
        const displayChipColours = getSearchStatusColours(search.statusEnum);
        return DisplayChip({ text: search.status, chipColor: displayChipColours.displayChipColor, chipBorderColor: displayChipColours.displayChipBorderColor });
    }
};

const getQuickSearchResults = ({ value, row: { original: search } }): JSX.Element => {
    if (search.status === "Searching") {
        return searchStatusSearching;
    } else if (value === -1) {
        return <span>{"-"}</span>;
    } else {
        return <span>{`${value} hits`}</span>;
    }
};

export const SearchColumnDefinitions: { [key: string]: DataGridColumnDefinition<SearchRequestRow> } = {
    assignedTo: {
        columnName: "Assigned To",
        path: (originalRow: SearchRequestRow) => originalRow.assignedToUser?.name,
        id: "assignedToUserId"
    },
    createdBy: {
        columnName: "Created By",
        path: (originalRow: SearchRequestRow) => originalRow.createdByUser.name,
        id: "createdByUserId",
        isVisible: false
    },
    createdOn: {
        columnName: "Created On",
        path: "createdOn",
        align: "right",
        width: 200
    },
    currentTask: {
        columnName: "Current Task",
        path: "currentTask"
    },
    name: {
        columnName: "Name",
        path: "name",
        Cell: getTitleForRow,
        flexGrow: 2,
        fixed: true
    },
    number: {
        columnName: "Search Request #",
        sortComparer: (rowA: GridRowValues, rowB: GridRowValues) => compareSearchNumbers(rowA, rowB, logger),
        path: "formattedNumber",
        //need to use cell function here to avoid getting the default empty value ("-"/"N/A")
        //we just want to display nothing at all for searches that don't have numbers yet
        Cell: ({ row: { original: search } }) => search.formattedNumber,
        align: "right"
    },
    progress: {
        columnName: "Progress",
        path: "progressPercentage",
        Cell: getProgressDisplayForRow,
        maxWidth: 200,
        minWidth: 200
    },
    requestedBy: {
        columnName: "Requested By",
        path: (originalRow: SearchRequestRow) => originalRow.requestedBy?.name,
        id: "requestedBy"
    },
    lastModified: {
        columnName: "Last Modified",
        path: "lastModified",
        align: "right"
    },
    status: {
        columnName: "Request Status",
        path: "status",
        Cell: getSearchStatusDisplayChip,
        cellStyle: { textOverflow: "clip" }
    },
    searchDate: {
        columnName: "Last Searched",
        path: "searchDate",
        width: 200,
        align: "right"
    },
    resultCount: {
        columnName: "Results",
        path: "totalHits",
        Cell: getQuickSearchResults,
        width: 200
    },
    type: {
        columnName: "Type",
        path: "type"
    },
    // #region These columns are included in the list of columns (although not visible) because this information is needed when actioning rows
    searchId: {
        columnName: "SearchId",
        path: "id",
        isNeverVisible: true
    },
    _etag: {
        columnName: "_etag",
        path: "_etag",
        isNeverVisible: true
    }
};
