import { Assignment } from "@mui/icons-material";
import { Link, LinkProps, styled } from "@mui/material";
import { getSearchStatusDisplayValue, QuickSearch, SearchMessages, SearchStatus, SearchVersion, showSearchVersionNumber } from "aderant-conflicts-models";
import { Button, formatShortDateTimeDisplay, RefinerGroup } from "@aderant/aderant-react-components";
import { nameof } from "@aderant/aderant-web-fw-core";
import { SearchOptionsComboBox } from "components/SearchOptionsComboBox/SearchOptionsComboBox";
import { format } from "date-fns";
import { RootState } from "MyTypes";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions, searchActions } from "state/actions";
import { getAllUsers } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import { HitResult } from "./HitResult";
import { Messages } from "./Messages";
import { ResultDetailDialog } from "./ResultDetailDialog";
import { generatePdf } from "./Utils/generatePdf";
import { getUser } from "pages/Shared/userUtils";

export type ReportSummaryProps = {
    currentUserId: string;
    results?: HitResult[];
    gridColumns: Map<string, { displayName: string; sequence?: number }>;
    refiners?: RefinerGroup[];
} & ReportSummaryPropsBySearchType;

type ReportSummaryPropsBySearchType =
    | {
          searchVersion?: QuickSearch;
          isRequestStatusLink?: false;
          isAssignedToLink?: false;
          showRequestStatus?: false;
          showAssignedTo?: false;
          showSearchNumber?: false;
      }
    | {
          searchVersion?: SearchVersion;
          isRequestStatusLink?: boolean;
          isAssignedToLink?: boolean;
          showRequestStatus?: boolean;
          showAssignedTo?: boolean;
          showSearchNumber?: boolean;
      };

const labelColor = conflictsPalette.text.disabled;
const valueColor = conflictsPalette.text.black;

//Styling the icon button - Making it Smedium size (between small and medium, couldn't come up with anything shorter) overriding the material defaults.
const classes = {
    mediumButton: "mediumButton"
};
const MediumButton = styled(Button)(({ theme }) => ({
    [`&.${classes.mediumButton}`]: {
        padding: 6
    }
}));

export const ReportSummary = (props: ReportSummaryProps): JSX.Element => {
    const {
        searchVersion,
        isRequestStatusLink = false,
        isAssignedToLink = false,
        currentUserId,
        results,
        refiners,
        gridColumns,
        showRequestStatus = true,
        showAssignedTo = true,
        showSearchNumber = true
    } = props;
    const dispatch = useDispatch();
    const [propertyToFocus, setPropertyToFocus] = useState<string | undefined>();
    const isDetailDialogOpen = useSelector((rootState: RootState) => {
        return rootState.search?.isResultDetailDialogOpen;
    });
    //Get Lookup lists
    const affiliationList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.affiliationList;
    });
    const partyStatusList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.partyStatusList;
    });
    const openResultDetailDialog = () => {
        dispatch(searchActions.openResultDetailDialog());
    };

    //Fetch all users
    const allUsers = useSelector(getAllUsers);
    //Style for parent element
    const styles: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        margin: "1rem auto",
        alignItems: "center",
        maxWidth: "60%", //Title width restricted to 60%.
        flexWrap: "wrap",
        textAlign: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    };

    const labelStyle: React.CSSProperties = {
        color: labelColor,
        marginRight: 3
    };
    //Format Date
    const formattedDate = (date?: Date, shortDate = false) => (!date ? "-" : shortDate ? format(new Date(date), "d LLL yyyy") : formatShortDateTimeDisplay(new Date(date)));
    //Format Status text
    const formattedStatus = (status?: SearchStatus) => (!status ? "-" : getSearchStatusDisplayValue(status));
    //Get assigned to user name from assignedToUserId
    const getAssignedToUser = () => {
        if (searchVersion && searchVersion.assignedToUserId && allUsers) {
            const user = getUser(allUsers, searchVersion.assignedToUserId);
            return user?.name ?? searchVersion.assignedToUserId;
        }
        return "-";
    };
    //Get requested by user name from requestedByUserId
    const getRequestedByUser = () => {
        if (searchVersion && searchVersion.requestedByUserId && allUsers) {
            const user = getUser(allUsers, searchVersion.requestedByUserId);
            return user?.name ?? searchVersion.requestedByUserId;
        }
        return "-";
    };

    const handleLinkClick = (e: React.SyntheticEvent, propertyName: string) => {
        e.preventDefault();
        setPropertyToFocus(propertyName);
        openResultDetailDialog();
    };

    //Common Link props
    const linkProps: LinkProps<"button"> = {
        sx: { "&.MuiLink-button.MuiTypography-root": { verticalAlign: "baseline" } },
        fontSize: "inherit",
        fontFamily: "inherit",
        underline: "hover"
    };

    const printReport = () => {
        if (searchVersion && results && gridColumns) {
            try {
                generatePdf(searchVersion, results, getRequestedByUser(), gridColumns, affiliationList, partyStatusList, refiners);
            } catch (e: any) {
                dispatch(appActions.showError(e.message));
            }
        }
    };

    return (
        <>
            <div style={styles}>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center", maxWidth: "100%" }}>
                    <h1
                        data-testid="search-name"
                        style={{
                            fontWeight: 300,
                            fontSize: "1.5rem",
                            marginRight: 10,
                            marginBottom: 5,
                            marginTop: 0,
                            textAlign: "center",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis"
                        }}
                    >
                        {searchVersion && `${searchVersion?.name}`}
                        {searchVersion && showSearchNumber && showSearchVersionNumber(searchVersion)}
                    </h1>
                    <MediumButton
                        iconButton
                        startIcon={<Assignment />}
                        title="Profile"
                        aria-label="Profile"
                        rounded
                        className={classes.mediumButton}
                        onClick={() => openResultDetailDialog()}
                        showActiveState={isDetailDialogOpen}
                    />
                    <SearchOptionsComboBox
                        searchInfo={{
                            searchId: searchVersion?.searchId,
                            versionId: searchVersion?.id,
                            assignedToUserId: searchVersion?.assignedToUserId,
                            searchStatus: searchVersion?.status,
                            onPrintReport: printReport,
                            results: results,
                            isQuickSearch: searchVersion?.isQuickSearch
                        }}
                        iconSize={3}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        flexWrap: "wrap",
                        maxWidth: "100%"
                    }}
                >
                    <div style={{ marginRight: 2, maxWidth: "100%", alignSelf: "center", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                        <span style={labelStyle}>{Messages.SEARCH_RESULTS.getMessage()}:</span>
                        <span data-testid="search-date" style={{ color: valueColor }}>
                            {formattedDate(searchVersion?.searchDate)}
                        </span>
                        <span style={{ margin: "0 10px", color: "grey", ...(!showRequestStatus && !showAssignedTo && { display: "none" }) }}>|</span>
                    </div>

                    {showRequestStatus && (
                        <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            <span style={labelStyle}>{SearchMessages.REQUEST_STATUS.getMessage()}:</span>
                            {isRequestStatusLink ? (
                                <Link {...linkProps} component="button" onClick={(e) => handleLinkClick(e, nameof<SearchVersion>("status"))} data-testid="search-status-hyperlink">
                                    {formattedStatus(searchVersion?.status)}
                                </Link>
                            ) : (
                                <span data-testid="search-status-text" style={{ color: valueColor }}>
                                    {formattedStatus(searchVersion?.status)}
                                </span>
                            )}

                            <span style={{ margin: "0 10px", color: "grey" }}>|</span>
                        </div>
                    )}
                    {showAssignedTo && (
                        <div style={{ minWidth: 3, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            <span style={labelStyle}>{SearchMessages.ASSIGNED_TO.getMessage()}:</span>
                            {isAssignedToLink ? (
                                <Link {...linkProps} component="button" onClick={(e) => handleLinkClick(e, nameof<SearchVersion>("assignedToUserId"))} data-testid="assigned-to-user-hyperlink">
                                    {getAssignedToUser()}
                                </Link>
                            ) : (
                                <span data-testid="assigned-to-user-text" style={{ color: valueColor }}>
                                    {getAssignedToUser()}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {searchVersion && (
                <ResultDetailDialog
                    canChangeAssignedTo={isAssignedToLink}
                    open={isDetailDialogOpen}
                    onClose={() => {
                        setPropertyToFocus(undefined);
                        dispatch(searchActions.closeResultDetailDialog());
                    }}
                    searchVersion={searchVersion}
                    currentUserId={currentUserId}
                    focusedPropertyName={propertyToFocus}
                />
            )}
        </>
    );
};
