import {
    AlteredSearchWordOrPhrase,
    DiagnosticInformation,
    EtagMismatch,
    Forbidden,
    Hit,
    InputSearchWordOrPhrase,
    NotFound,
    QuickSearch,
    SearchErrors,
    SearchVersionIdentifier,
    SearchVersionUnedited,
    SynonymMap,
    ValidationErrors
} from "aderant-conflicts-models";
import { OmitStrict } from "@aderant/aderant-web-fw-core";
import { AzureFunctionDefinition, AzureKeyAuthFunctionDefinition, AzureQueueFunctionDefinition } from "../../AzureFunctionDefinition";
import { getFunctionAppUrl } from "../../Config/EnvironmentService";
import { TokenAuthContext, UserlessKeyAuthFunctionAppContext } from "../../ConflictsContext";
import { OkResponse } from "../../Http/HttpResponse";

export type SearchRequestMessage = {
    id: string;
    searchId: SearchVersionIdentifier;
    applyFuzzySearch: boolean;
    terms: InputSearchWordOrPhrase[];
};

export interface SearchRequest {
    wordOrPhrases: InputSearchWordOrPhrase[];
    applyFuzzySearch: boolean;
}

export interface SearchHitsBySearchTerm {
    [id: `${string}:${string}`]: OmitStrict<Hit, "id">[];
}

export type SearchResult = {
    hitsById: SearchHitsBySearchTerm;
    searchedWordsOrPhrases: AlteredSearchWordOrPhrase[];
};

export type StartPerformSearchErrors =
    | NotFound
    | SearchErrors.InvalidVersionNumber
    | SearchErrors.InvalidId
    | SearchErrors.InvalidSearchType
    | SearchErrors.NoWordOrPhrases
    | SearchErrors.UserCannotViewSearch
    | SearchErrors.NotLatestSearchVersion
    | ValidationErrors
    | EtagMismatch;

export const SearchService = {
    startPerformSearch: new AzureFunctionDefinition<
        SearchVersionIdentifier, //     Input type
        SearchVersionUnedited, //                   Output type
        OkResponse<SearchVersionUnedited>, //       Possible Http Response codes of Output (union for multiple)
        StartPerformSearchErrors // Possible error types (union for multiple)
    >({
        httpVerb: "POST",
        expectedErrors: ["ETAG_MISMATCH", "NOT_FOUND", "INVALID_ID", "INVALID_VERSION_NUMBER", "NOT_LATEST_SEARCH_VERSION", "NO_WOPS", "VALIDATION", "USER_CANNOT_VIEW_SEARCH", "INVALID_SEARCH_TYPE"],
        getUrlEnd: (input) => `./api/Searches/${input.searchId}/Versions/${input.versionId}/StartPerformSearch`
    }),
    startPerformQuickSearch: new AzureFunctionDefinition<
        SearchVersionIdentifier, //     Input type
        QuickSearch, //                   Output type
        OkResponse<QuickSearch>, //       Possible Http Response codes of Output (union for multiple)
        StartPerformSearchErrors //    Possible error types (union for multiple)
    >({
        httpVerb: "POST",
        expectedErrors: ["ETAG_MISMATCH", "NOT_FOUND", "INVALID_ID", "INVALID_VERSION_NUMBER", "NOT_LATEST_SEARCH_VERSION", "NO_WOPS", "VALIDATION", "USER_CANNOT_VIEW_SEARCH", "INVALID_SEARCH_TYPE"],
        getUrlEnd: (input) => `./api/QuickSearches/${input.searchId}/Versions/${input.versionId}/StartPerformSearch`
    }),
    getSynonymMap: new AzureFunctionDefinition<void, SynonymMap, OkResponse<SynonymMap>, NotFound | Forbidden>({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND", "ACCESS_DENIED"],
        getUrlEnd: () => `./api/SynonymMap`
    }),
    updateSynonymMap: new AzureFunctionDefinition<SynonymMap, SynonymMap, OkResponse<SynonymMap>, ValidationErrors | EtagMismatch | NotFound | Forbidden>({
        httpVerb: "PUT",
        expectedErrors: ["VALIDATION", "ETAG_MISMATCH", "NOT_FOUND", "ACCESS_DENIED"],
        getUrlEnd: () => `./api/SynonymMap`
    }),
    updateSynonymsStatus: new AzureFunctionDefinition<{ synonymsStatus: "ON" | "OFF" }, { synonymsStatus: "ON" | "OFF" }, OkResponse<{ synonymsStatus: "ON" | "OFF" }>, ValidationErrors | Forbidden>({
        httpVerb: "PUT",
        expectedErrors: ["VALIDATION", "ACCESS_DENIED"],
        getUrlEnd: () => `./api/SynonymsStatus`
    }),
    getSynonymsStatus: new AzureFunctionDefinition<void, { synonymsStatus: "ON" | "OFF" }, OkResponse<{ synonymsStatus: "ON" | "OFF" }>, NotFound | Forbidden>({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND", "ACCESS_DENIED"],
        getUrlEnd: () => `./api/SynonymsStatus`
    }),
    getHealth: new AzureKeyAuthFunctionDefinition<undefined, DiagnosticInformation, OkResponse<DiagnosticInformation>, never>({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: () => `./api/Health`
    }),
    queueTriggerFunctions: {
        processPerformSearchMessage: new AzureQueueFunctionDefinition<SearchRequestMessage>({ queueName: "search-request-messages", bindingVariableName: "searchRequestMessage" }),
        processPerformSearchMessagePoison: new AzureQueueFunctionDefinition<SearchRequestMessage>({ queueName: "search-request-messages", bindingVariableName: "searchRequestMessage" }),
        performSearchMessage: new AzureQueueFunctionDefinition<SearchRequestMessage>({ queueName: "perform-search-messages", bindingVariableName: "performSearchMessage" }),
        performSearchMessagePoison: new AzureQueueFunctionDefinition<SearchRequestMessage>({ queueName: "perform-search-messages", bindingVariableName: "performSearchMessage" })
    }
};

export type SearchServiceProxy = ReturnType<typeof getSearchServiceProxy>;

//justification: return type is necessarily defined by the function itself - to explicitly define it in the function signature
//would be unnecessarily verbose (just like this comment)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getSearchServiceProxy(context: TokenAuthContext) {
    const baseUrl = getFunctionAppUrl("SearchApi");
    return {
        startPerformSearch: SearchService.startPerformSearch.getProxy(baseUrl, context),
        startPerformQuickSearch: SearchService.startPerformQuickSearch.getProxy(baseUrl, context),
        getSynonymMap: SearchService.getSynonymMap.getProxy(baseUrl, context),
        updateSynonymMap: SearchService.updateSynonymMap.getProxy(baseUrl, context),
        updateSynonymsStatus: SearchService.updateSynonymsStatus.getProxy(baseUrl, context),
        getSynonymsStatus: SearchService.getSynonymsStatus.getProxy(baseUrl, context)
    };
}

export type UserlessSearchServiceProxy = Awaited<ReturnType<typeof getUserlessSearchServiceProxy>>;

//justification: return type is necessarily defined by the function itself - to explicitly define it in the function signature
//would be unnecessarily verbose (just like this comment)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getUserlessSearchServiceProxy(context: UserlessKeyAuthFunctionAppContext) {
    const baseUrl = getFunctionAppUrl("SearchApi");
    const hostKey = await context.getSharedFunctionHostKey("SearchApi");
    return {
        getHealth: SearchService.getHealth.getKeyAuthProxyForMonitoring(baseUrl, context.logger, hostKey)
    };
}
