import { createNewSearchVersionFromID, SearchStatuses, SearchVersion, SearchVersionNew } from "aderant-conflicts-models";
import { Button, MessageDialog } from "@aderant/aderant-react-components";
import { Logger } from "@aderant/aderant-web-fw-core";
import { Action, Location } from "history";
import { RootState } from "MyTypes";
import { default as React, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { searchActions } from "state/actions";
import { getCurrentSearchVersion, getFirmSetting, getIsNewSearch, usePermissionsContext } from "state/selectors";
import { navigateWithoutPrompt } from "utilities/navigation";
import { Messages } from "./Messages";
import SearchEditPage, { SearchEditPageDesktopProps } from "./SearchEditPage";

function SearchEditPageContainer(props: { logger: Logger }) {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const searchVersion: SearchVersion | SearchVersionNew | undefined = useSelector((rootState: RootState) => getCurrentSearchVersion(rootState), shallowEqual);
    const history = useHistory();
    const [isSaveAsDraftDialogOpen, setSaveAsDraftDialogOpen] = useState(false);
    const [isPendingChangesDialogOpen, setIsPendingChangesDialogOpen] = useState(false);
    const isCopyFailedDialogOpen = useSelector((rootState: RootState) => rootState.search.isCopyFailureDialogOpen, shallowEqual);
    const navLocationAction = useRef<{ location: Location; action: Action }>({ location: history.location, action: history.action });
    const shouldNavigateAway = useRef(false);
    const suppressUnsavedDataHandling = useRef(false);
    const permissions = usePermissionsContext();
    const isNewSearch: boolean = useSelector(getIsNewSearch);
    const applyFuzzyDefaultValue = useSelector((rootState: RootState) => getFirmSetting(rootState, "firm-options/searchRequests/applyFuzzySearchByDefaultForSearchRequests", false), shallowEqual);

    useEffect(() => {
        if (isNewSearch) {
            dispatch(searchActions.addSearch({ newSearchVersion: createNewSearchVersionFromID(id, permissions.currentUserId, applyFuzzyDefaultValue) }));
        }
    }, [id]);

    const searchAction = (searchVersion, history, updateIsSearchingWhenDone) => {
        if (!searchVersion) {
            return;
        }
        suppressUnsavedDataHandling.current = true;
        dispatch(searchActions.performSearch({ searchVersion, history, updateIsSearchingWhenDone }));
    };

    const addTermThenPerformSearchAction = (searchVersion, history, requestTerm, updateIsSearchingWhenDone) => {
        suppressUnsavedDataHandling.current = true;
        dispatch(searchActions.addTermThenPerformSearch({ searchVersion, history, requestTerm, updateIsSearchingWhenDone }));
    };

    const onNavigateAway: (location: Location, action: Action) => boolean = (location: Location, action: Action) => {
        if (location.pathname.endsWith(`/results/${searchVersion?.searchId}`) || shouldNavigateAway.current || isCopyFailedDialogOpen) {
            //if we're navigating to results we've just performed a search, and we shouldn't prompt
            dispatch(searchActions.closeCopyFailureDialog());
            return true;
        } else {
            navLocationAction.current = { location, action };
            if (searchVersion?.editState === "CURRENT") {
                if (searchVersion?.status === SearchStatuses.Draft && !suppressUnsavedDataHandling.current) {
                    //search currently has no unsaved changes, so show the "do you want to save this Search Request in Draft" prompt
                    setSaveAsDraftDialogOpen(true);
                } else {
                    return true;
                }
            } else {
                //search has some unsaved changes, so show the "are you sure you want to exit with unsaved changes" prompt
                !isNewSearch && setIsPendingChangesDialogOpen(true);
            }
            return false;
        }
    };

    const searchEditPageProps: SearchEditPageDesktopProps = {
        searchVersion: searchVersion,
        logger: props.logger,
        pageDetails: {
            pageTitle: Messages.NEW_SEARCH_REQUEST_PAGE_TITLE.getMessage(),
            isNewVersion: false,
            searchAction: searchAction,
            addTermThenPerformSearchAction: addTermThenPerformSearchAction,
            suppressUnsavedDataHandling: suppressUnsavedDataHandling
        }
    };

    return (
        <>
            <SearchEditPage {...searchEditPageProps}>
                <Prompt when={true} message={onNavigateAway} />
                <MessageDialog
                    open={isSaveAsDraftDialogOpen}
                    onClose={() => setSaveAsDraftDialogOpen(false)}
                    message={Messages.SAVE_AS_DRAFT_PROMPT.getMessage()}
                    title={Messages.SAVE_AS_DRAFT_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text="Delete"
                                color="secondary"
                                onClick={() => {
                                    if (searchVersion) {
                                        dispatch(searchActions.deleteSearches({ searchVersionIdentifiers: [{ searchId: searchVersion.searchId, versionId: searchVersion.id }] }));
                                    } else {
                                        window.alert("Search version is undefined.");
                                    }
                                    //Clear the current search so an autosave is not fired when the component dismounts
                                    dispatch(searchActions.clearCurrentSearch());
                                    navigateWithoutPrompt(navLocationAction.current, history, shouldNavigateAway);
                                    setSaveAsDraftDialogOpen(false);
                                }}
                            />
                            <Button
                                text="Save"
                                onClick={() => {
                                    navigateWithoutPrompt(navLocationAction.current, history, shouldNavigateAway);
                                    setSaveAsDraftDialogOpen(false);
                                }}
                            />
                        </>
                    }
                />
                <MessageDialog
                    open={isPendingChangesDialogOpen}
                    onClose={() => setIsPendingChangesDialogOpen(false)}
                    message={Messages.PENDING_CHANGES_PROMPT.getMessage()}
                    title={Messages.PENDING_CHANGES_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text="No"
                                color="secondary"
                                onClick={() => {
                                    setIsPendingChangesDialogOpen(false);
                                }}
                            />
                            <Button
                                text="Yes"
                                onClick={() => {
                                    navigateWithoutPrompt(navLocationAction.current, history, shouldNavigateAway);
                                    setIsPendingChangesDialogOpen(false);
                                }}
                            />
                        </>
                    }
                />
                <MessageDialog
                    open={isCopyFailedDialogOpen}
                    onClose={() => dispatch(searchActions.closeCopyFailureDialog())}
                    message={Messages.COPY_FAILED_PROMPT.getMessage()}
                    title={Messages.COPY_FAILED_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text="Close"
                                color="secondary"
                                onClick={() => {
                                    history.goBack();
                                }}
                            />
                        </>
                    }
                />
            </SearchEditPage>
        </>
    );
}

export default SearchEditPageContainer;
