import { Context } from "@azure/functions";
import { ConflictsError, isConflictsError, isUnexpectedError } from "aderant-conflicts-models";
import { Logger } from "@aderant/aderant-web-fw-core";
import { EnvironmentService, HeaderBuilder } from "../../../..";
import { HttpBody, HttpResponse, SuccessResponse } from "../../../Http/HttpResponse";
import { httpResponseFromError, isBasicConflictsError } from "../errorHandling";

export async function callImplementationAndHandleHttpResult<Out extends HttpBody, Success extends SuccessResponse<Out>, Err extends ConflictsError>(
    azureContext: Context,
    logger: Logger,
    implementation: () => Promise<Success | Err>
): Promise<void> {
    let result: Err | Success;
    try {
        logger.debug("Calling passed in Azure Function implementation");
        result = await implementation();
        let response: HttpResponse;
        if (isConflictsError(result)) {
            logConflictsError(logger, result);
            response = httpResponseFromError(result);
        } else {
            logger.info("Implementation produced a success (%s) result.", result.status);
            if (EnvironmentService.isDevelopmentEnvironment()) {
                logger.debug(
                    "Printing response content as this is a development environment. \n Extra Headers: %s\n Body: %s",
                    result.status,
                    JSON.stringify(result.headers ?? "none", null, 2),
                    JSON.stringify(result.body, null, 2)
                );
            }
            response = result;
        }
        azureContext.res = addContentTypeHeader(response);
    } catch (e) {
        if (EnvironmentService.isDevelopmentEnvironment()) {
            //if we're a dev deployment we want to return full error context in the response.
            //Unfortunately without doing *fancy things* with the Azure Insights library I don't think we can
            //have Azure treat the function as "failed" *and* return a response body, this is not a big deal in dev though.
            logger.warn("NODE_ENV is set to 'development', returning full error context in response.");
            logger.error("Unhandled error caught, returning 500 response", e);
            // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            azureContext.res = addContentTypeHeader({ body: e as HttpBody, status: 500 });
            return;
        } else {
            //we're in a prod deployment, rely on default Azure behaviour for uncaught errors
            //(will mark the request as "failed" in Azure instrumentation and respond with a 500 and *empty* body)
            //need to log before throwing, as Azure will not include the information in App Insights logs otherwise (unfortunately we get a duplicate log in filesystem logs this way, but no way around it afaik)
            const errorString: string = isUnexpectedError(e) ? e.message : JSON.stringify(e);
            logger.error("Unhandled error caught, rethrowing: %s", errorString);
            throw e;
        }
    }
}

export function addContentTypeHeader(response: HttpResponse): HttpResponse {
    return { ...response, headers: new HeaderBuilder(response.headers).addContentTypeHeader(response.body).build() };
}

function logConflictsError(logger: Logger, error: ConflictsError): void {
    //note: these are not logged at warning/error level as this function is only hit when the error is one of the expected ones on the API definition.
    //Unexpected error types would not be valid return values and would be thrown instead of returned.
    if (isBasicConflictsError(error)) {
        switch (error._conflictserrortype) {
            case "VALIDATION": {
                logger.info("Azure Function implementation produced a Validation error (%s) response: %s", error.httpStatusCode, error.message);
                return;
            }
            case "NOT_FOUND": {
                logger.info("Azure Function implementation produced a Not Found error (%s) response: %s", error.httpStatusCode, error.message);
                return;
            }
        }
    } else {
        logger.info("Azure Function implementation produced an error (status: %s, _conflictserrortype: %s) response: %s", error.httpStatusCode, error._conflictserrortype, error.message);
        return;
    }
}
