import { Logger } from "@aderant/aderant-web-fw-core";
import { init as initLaunchDarkly } from "@launchdarkly/node-server-sdk";
import { fallbackLDClient } from "./fallbackLDClient";
import { LaunchDarklyClient } from "aderant-conflicts-models";
import { getSharedLaunchDarklySdkKey } from "../FunctionApps/SecretConnectors/TenantSecret/TenantSecretConnector";

//this isn't tenant specific - safe to cache a single instance for the life of the app.
let launchDarklyClient: LaunchDarklyClient | undefined = undefined;
export async function getOrCreateLaunchDarklyClient(logger: Logger): Promise<LaunchDarklyClient> {
    if (launchDarklyClient) {
        return launchDarklyClient;
    }
    let sdkKey: string;
    try {
        sdkKey = await getSharedLaunchDarklySdkKey();
    } catch (e) {
        logger.error("[FeatureFlags] Error getting LaunchDarkly SDK Key", e);
        sdkKey = "";
    }
    if (!sdkKey) {
        if (process.env.NODE_ENV === "development") {
            logger.warn("[FeatureFlag] NODE_ENV is 'development' and shared-launchdarkly-sdk-key was not found in key vault. Using fallback LDClient (all variations will have their fallback value).");
            launchDarklyClient = fallbackLDClient;
            return launchDarklyClient;
        }

        // Separate handling here as console logs slow down test runs significantly
        if (process.env.NODE_ENV === "test") {
            logger.warn("[FeatureFlag] NODE_ENV is 'test' and shared-launchdarkly-sdk-key was not found in key vault. Using fallback LDClient (all variations will have their fallback value).");
            launchDarklyClient = fallbackLDClient;
            return launchDarklyClient;
        }

        logger.error("[FeatureFlag] shared-launchdarkly-sdk-key was not found in key vault. Using fallback LDClient (all variations will have their fallback value).");
        launchDarklyClient = fallbackLDClient;
        return launchDarklyClient;
    }
    launchDarklyClient = initLaunchDarkly(sdkKey);
    return launchDarklyClient;
}
