import { StatusCodes } from "@azure/cosmos";
import { AzureKeyCredential, IndexDocumentsResult, IndexingResult, SearchClient, SearchIndex } from "@azure/search-documents";
import { sendRequest } from "../AzureFunctionDefinition/shared/proxy/sendRequest";
import { AzureSettings } from "../AzureCognitiveSearchClient/AzureSettings";
import { doesIndexExist, getAllFieldsForIndexName, getSearchableFieldsForIndexName } from "../AzureCognitiveSearchClient/AzureCognitiveSearchClient";
//The below import needs to be from the root folder in Common, or the mock in processDelta.test.ts will fail.
import { createSearchIndexClient } from "../..";
import { CognitiveSearchSecrets, notFound, NotFound, unexpectedError } from "aderant-conflicts-models";
import { Logger } from "@aderant/aderant-web-fw-core";
import retry from "async-retry";
export interface SearchIndexer {
    indexRecords(records: any[], indexName: string): Promise<void>;
    removeIndexedRecords(records: any[], indexName: string): void;
    getFieldsForIndex(indexName: string): Promise<string[]>;
    getSearchableFieldsForIndex(indexName: string): Promise<string[]>;
}

export interface SearchIndexManager {
    deleteIndex(indexName: string, logger: Logger): Promise<void | NotFound>;
    createIndex(indexDefinition: SearchIndex, logger: Logger): Promise<void>;
}

export class SearchIndexConnector implements SearchIndexer {
    private azureSettings: AzureSettings;
    private indexFields: { [indexName: string]: string[] } | undefined = undefined;
    private searchableIndexFields: { [indexName: string]: string[] } | undefined = undefined;

    constructor(connectionDetails: CognitiveSearchSecrets) {
        this.azureSettings = new AzureSettings(connectionDetails);
    }

    async indexRecords(records: any[], indexName: string, delay?: number) {
        const client = new SearchClient(this.azureSettings.getBaseUrl(), indexName, new AzureKeyCredential(this.azureSettings.getAdminApiKey()));

        return await this.executeWithRetry(async () => {
            return await client.mergeOrUploadDocuments(records, {
                throwOnAnyFailure: true
            });
        });
    }

    async removeIndexedRecords(recordIds: any[], indexName: string) {
        const client = new SearchClient(this.azureSettings.getBaseUrl(), indexName, new AzureKeyCredential(this.azureSettings.getAdminApiKey()));
        //The client api needs objects to delete, instead of just taking the ids like a reasonable person
        //This seems to be related to how the REST request is structured bechind the scenes.
        //https://docs.microsoft.com/en-us/rest/api/searchservice/addupdate-or-delete-documents
        const records = recordIds.map((id) => {
            return {
                id: id
            };
        });

        return await this.executeWithRetry(async () => {
            return await client.deleteDocuments(records, {
                throwOnAnyFailure: true
            });
        });
    }

    private async executeWithRetry(func: () => Promise<any>) {
        return await retry(
            async () => {
                // if anything throws, we retry
                const response = await func();
                this.handleResponse(response);
            },
            {
                retries: 3
            }
        );
    }

    handleResponse(response: IndexDocumentsResult) {
        const erroredDocuments = response.results.filter((result: IndexingResult) => {
            return result.statusCode !== StatusCodes.Ok && result.statusCode !== StatusCodes.Created;
        });
        if (erroredDocuments.length > 0) {
            throw unexpectedError("One or more documents failed to index", JSON.stringify(erroredDocuments));
        }
    }

    async getFieldsForIndex(indexName: string): Promise<string[]> {
        const searchIndexClient = createSearchIndexClient(this.azureSettings);
        if (!(await doesIndexExist(searchIndexClient, indexName))) {
            throw unexpectedError(`Index ${indexName} does not exist in the search instance.`, "");
        }

        if (!this.indexFields) {
            this.indexFields = await getAllFieldsForIndexName(indexName, searchIndexClient);
        }
        const fields = this.indexFields[indexName];
        return fields || [];
    }

    async getSearchableFieldsForIndex(indexName: string): Promise<string[]> {
        const searchIndexClient = createSearchIndexClient(this.azureSettings);
        if (!(await doesIndexExist(searchIndexClient, indexName))) {
            throw unexpectedError(`Index ${indexName} does not exist in the search instance.`, "");
        }

        if (!this.searchableIndexFields) {
            this.searchableIndexFields = await getSearchableFieldsForIndexName(indexName, searchIndexClient);
        }
        const searchableFields = this.searchableIndexFields[indexName];
        return searchableFields || [];
    }
}

export class SearchIndexManager implements SearchIndexManager {
    private azureSettings: AzureSettings;

    constructor(connectionDetails: CognitiveSearchSecrets) {
        this.azureSettings = new AzureSettings(connectionDetails);
    }

    private getRequestHeaders(): Record<string, string> {
        return {
            "api-key": this.azureSettings.getAdminApiKey(),
            "Content-Type": "application/json"
        };
    }

    /**
     * Delete the tenants index on the cognitive search instance.  This action is irreversible.  Does not use the azure client, it doesn't delete the index at all :(
     * @param indexName The name of the index we are deleting
     * @param logger Context logger.
     */
    async deleteIndex(indexName: string, logger: Logger): Promise<void | NotFound> {
        const url = `${this.azureSettings.getBaseUrl()}indexes/${indexName}?api-version=${this.azureSettings.getApiVersion()}`;
        try {
            await sendRequest(logger, {
                url: url,
                httpVerb: "DELETE",
                headers: this.getRequestHeaders()
            });
        } catch (e: any) {
            if (e?.response && e?.response.status === 404 && e.response.data?.error?.details[0]?.code === "IndexNotFoundInService") {
                return notFound();
            } else {
                throw unexpectedError(e, "Error creating index in SearchIndexManager.createIndex()");
            }
        }
    }

    /**
     * Create an index.  This does NOT use the azure search node package, because there are serialization issues with the custom analyzers
     * @param indexDefinition The index definition, as an object.
     * @param logger Context logger.
     */
    async createIndex(indexDefinition: SearchIndex, logger: Logger): Promise<void> {
        const url = `${this.azureSettings.getBaseUrl()}indexes?api-version=${this.azureSettings.getApiVersion()}`;
        const body = JSON.stringify(indexDefinition);
        try {
            await sendRequest(logger, {
                url: url,
                httpVerb: "POST",
                body: body,
                headers: this.getRequestHeaders()
            });
        } catch (e: any) {
            throw unexpectedError(e, "Error creating index in SearchIndexManager.createIndex()");
        }
    }
}
