import React from "react";
import { HitStatuses, SearchVersion, CompleteHitStatus } from "aderant-conflicts-models";
import { ValuesOf } from "@aderant/aderant-web-fw-core";
import { DisplayTile, DisplayTileProps } from "components/DisplayTile/DisplayTile";
import { Messages } from "./Messages";
import { getSearchProgress } from "Functions/search";
import { conflictsPalette } from "styles/conflictsPalette";

const AdditionalTilesToDisplay = {
    Unresolved: "UNRESOLVED",
    Total: "TOTAL"
} as const;

type AdditionalHitsForTiles = ValuesOf<typeof AdditionalTilesToDisplay>;

export type HitTypesForTiles = AdditionalHitsForTiles | CompleteHitStatus;

type DataToDisplayType = {
    [K in HitTypesForTiles]: DisplayTileProps;
};

export const HitCountTiles = ({ searchVersion }: { searchVersion: SearchVersion }): JSX.Element => {
    const hitCountByStatus = searchVersion.summary?.hitCountByStatus || {};
    const progress = getSearchProgress(hitCountByStatus);

    const dataToDisplay: DataToDisplayType = {
        [AdditionalTilesToDisplay.Unresolved]: {
            text: Messages[AdditionalTilesToDisplay.Unresolved].getMessage(),
            colour: conflictsPalette.primary.Ahsoka,
            count: progress.total - progress.progress
        },
        [HitStatuses.SeekingWaiver]: {
            text: Messages[HitStatuses.SeekingWaiver].getMessage(),
            colour: conflictsPalette.status.yellow,
            count: hitCountByStatus[HitStatuses.SeekingWaiver]
        },
        [HitStatuses.WaiverOnFile]: {
            text: Messages[HitStatuses.WaiverOnFile].getMessage(),
            colour: conflictsPalette.status.green2,
            count: hitCountByStatus[HitStatuses.WaiverOnFile]
        },
        [HitStatuses.Conflict]: {
            text: Messages[HitStatuses.Conflict].getMessage(),
            colour: conflictsPalette.status.red,
            count: hitCountByStatus[HitStatuses.Conflict]
        },
        [HitStatuses.NoConflict]: {
            text: Messages[HitStatuses.NoConflict].getMessage(),
            colour: conflictsPalette.status.green,
            count: hitCountByStatus[HitStatuses.NoConflict]
        },
        [HitStatuses.NotApplicable]: {
            text: Messages[HitStatuses.NotApplicable].getMessage(),
            colour: conflictsPalette.status.grey2,
            count: hitCountByStatus[HitStatuses.NotApplicable]
        },
        [AdditionalTilesToDisplay.Total]: {
            text: Messages[AdditionalTilesToDisplay.Total].getMessage(),
            colour: conflictsPalette.text.black,
            count: progress.total
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            {Object.keys(dataToDisplay).map((data) => {
                return <DisplayTile count={dataToDisplay[data].count ?? 0} text={dataToDisplay[data].text} colour={dataToDisplay[data].colour} key={dataToDisplay[data].text} />;
            })}
        </div>
    );
};
