import { Context } from "@azure/functions";
import { BasicCurrentUserContext, ConflictsError } from "aderant-conflicts-models";
import { AzureFunctionLogger, AppInsightsClient } from "@aderant/aderant-web-fw-azfunctions";
import { AzureFunctionProxy, HttpBody, SuccessResponse } from "../..";
import { KeyAuthFunctionAppContext, UserlessKeyAuthFunctionAppContext } from "../ConflictsContext";
import { HttpVerb } from "../Http/HttpVerb";
import { processOrThrowErrorResponse } from "./shared/proxy/processOrThrowErrorResponse";
import { HttpRequestOptions, HttpQueryParams, sendRequest } from "./shared/proxy/sendRequest";
import { addContentTypeHeader, callImplementationAndHandleHttpResult } from "./shared/server/callImplementationAndHandleHttpResult";
import { extractSearchLogContext } from "./shared/server/extractSearchLogContext";
import { buildDependencies, Unbuilt } from "./shared/server/FunctionAppDependency";
import { Logger } from "@aderant/aderant-web-fw-core";

type AuthHeaders = { "x-functions-key": string };
export type UserHeaders = { "x-aderant-userid": string; "x-aderant-tenantuniquename": string };
export function isUserHeaders(value: unknown): value is UserHeaders {
    // justification: casting for safety accessing members in type guard
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const maybe = value as UserHeaders;

    return typeof maybe["x-aderant-userid"] === "string" && !!maybe["x-aderant-userid"] && typeof maybe["x-aderant-tenantuniquename"] === "string" && !!maybe["x-aderant-tenantuniquename"];
}

export class AzureKeyAuthFunctionDefinition<In extends HttpBody, Out extends HttpBody, Success extends SuccessResponse<Out>, Err extends ConflictsError, QueryParams extends HttpQueryParams = never> {
    public readonly httpVerb: HttpVerb;
    private readonly getUrlEnd: (input: In) => string;
    private readonly expectedErrorTypes: Set<Err["_conflictserrortype"]>;

    constructor(input: {
        getUrlEnd: (input: In) => string;
        httpVerb: HttpVerb;
        /**
         * ```Err["_conflictserrortype"]``` means 'the type of the field _conflictserrortype on type Err'.
         * This will resolve to a Union of _conflictserrortype strings when ```Err``` is a Union of ```ConflictsError``` implementations.
         *
         * Unfortunately we need this *as well as* the ```Err extends ConflictsError``` generic param as you cannot get a list/tuple of strings out of a string union type.
         * It will still be constrained to just values that satisfy ```Err``` however, so potential mistakes are limited.
         */
        expectedErrors: Err["_conflictserrortype"][];
    }) {
        this.httpVerb = input.httpVerb;
        this.getUrlEnd = input.getUrlEnd;
        this.expectedErrorTypes = new Set<Err["_conflictserrortype"]>(input.expectedErrors);
    }

    /**
     * Function to wrap a business logic implementation with necessary Azure Functions result handling, instrumentation etc
     * @param azureContext Azure context from your Azure Function entry point
     * @param internalImpl ```(context: ConflictsContext, input: In, ...dependencies: Deps) => Promise<Success | Err>``` - the implementation of your business logic.
     * @returns ```{ impl: (input: In, ...dependencies: Deps) => Promise<void>, validatedInput: KeyAuthFunctionInput<unknown>```
     * The impl function should be called in your Azure Function entry point, handles setting Azure context response, instrumentation etc for you.
     * validatedInput contains the input you should pass in to impl after performing type validation yourself in the httpTrigger.
     */
    public getImplementation<Deps extends Array<unknown>>(
        azureContext: Context,
        appInsightsClient: AppInsightsClient,
        internalImpl: (context: KeyAuthFunctionAppContext, input: In, ...dependencies: Deps) => Promise<Success | Err>,
        logContext?: Record<string, string>
    ): (input: In, ...dependencies: Unbuilt<Deps, KeyAuthFunctionAppContext>) => Promise<void> {
        const logger = new AzureFunctionLogger(azureContext.log, appInsightsClient);
        logContext = logContext || {};
        logger.setLogProperties(logContext); //Set initial properties, tenancyName comes after we parse it
        logger.debug("Running initial context setup (AzureKeyAuthFunctionDefinition.getImplementation).");

        return async (input: In, ...dependencies: Unbuilt<Deps, KeyAuthFunctionAppContext>) => {
            const headers = azureContext.req?.headers;
            if (!isUserHeaders(headers)) {
                azureContext.res = addContentTypeHeader({
                    body: "Expected 'x-aderant-userid' and 'x-aderant-tenantuniquename' headers to be populated",
                    status: 400
                });
                return;
            }

            logger.setLogProperty("tenancy", headers["x-aderant-tenantuniquename"]);
            logger.setLogProperties(extractSearchLogContext(input));

            const context: KeyAuthFunctionAppContext = new KeyAuthFunctionAppContext(logger, appInsightsClient, headers, azureContext);

            await callImplementationAndHandleHttpResult(context.azureContext, context.logger, async () => internalImpl(context, input, ...(await buildDependencies(dependencies, context))));
        };
    }

    /**
     * Function to wrap a userless key authible function implementation with necessary Azure Functions result handling, instrumentation etc.
     * This is useful for functions that do not require a distinct user such as a getHealth function call.
     * @param azureContext Azure context from your Azure Function entry point
     * @param internalImpl ```(context: UserlessKeyAuthFunctionAppContext, input: In, ...dependencies: Deps) => Promise<Success | Err>``` - the implementation of your business logic.
     * @returns ```{ impl: (input: In, ...dependencies: Deps) => Promise<void>, validatedInput: KeyAuthFunctionInput<unknown>```
     * The impl function should be called in your Azure Function entry point, handles setting Azure context response, instrumentation etc for you.
     * validatedInput contains the input you should pass in to impl after performing type validation yourself in the httpTrigger.
     */
    public getUserlessImplementation<Deps extends Array<unknown>>(
        azureContext: Context,
        appInsightsClient: AppInsightsClient,
        internalImpl: (context: UserlessKeyAuthFunctionAppContext, input: In, ...dependencies: Deps) => Promise<Success | Err>
    ): (input: In, ...dependencies: Unbuilt<Deps, UserlessKeyAuthFunctionAppContext>) => Promise<void> {
        const logger = new AzureFunctionLogger(azureContext.log);
        logger.debug("Running initial context setup (AzureKeyAuthFunctionDefinition.getUserlessImplementation).");

        return async (input: In, ...dependencies: Unbuilt<Deps, UserlessKeyAuthFunctionAppContext>) => {
            const context: UserlessKeyAuthFunctionAppContext = new UserlessKeyAuthFunctionAppContext(logger, appInsightsClient, azureContext);
            await callImplementationAndHandleHttpResult(context.azureContext, context.logger, async () => internalImpl(context, input, ...(await buildDependencies(dependencies, context))));
        };
    }

    /**This will return a proxy function for calling this Azure Function App.
     *
     * On error handling: this will return Err as part of the regular response type of the function.
     * Anything with a non 2XX response will first be compared with the expectedErrors of the function and returned.
     * if one of those. If not it will be wrapped in an UnexpectedError with context info (currently the context is just the url of this azure function).
     *
     * You should rely on this context wrapping - don't write manual error handling just to do more logging/context for unexpected errors - it's probably not neccessary.
     * BOILERPLATE BAD, AUTOMATIC INSTRUMENTATION GOOD
     
     * @param baseUrl this should be the base url of the function app with none of the function specific stuff on the end (i.e. up to the .com and no more)
     * @returns an ```(input: In) => Promise<Result<Out, Err>>)``` (the AzureFunctionProxy type alias mostly just exists because this might eventually have some extra context fields on it)
     */
    public getProxy(baseUrl: URL, context: BasicCurrentUserContext, hostKey: string): AzureFunctionProxy<In, Out, Err, QueryParams> {
        return async (input: In, requestOptions: HttpRequestOptions = {}, queryParams?: QueryParams) => {
            const fullUrl: URL = new URL(this.getUrlEnd(input), baseUrl);
            try {
                const headers = { ...this.getAuthHeaders(hostKey), ...this.getUserHeaders(context) };
                const response = await sendRequest<Out>(context.logger, { url: fullUrl.toString(), httpVerb: this.httpVerb, headers: headers, body: input, params: queryParams }, requestOptions);
                return response.data;
            } catch (err: unknown) {
                return processOrThrowErrorResponse(err, this.expectedErrorTypes, `${this.httpVerb} to ${fullUrl.toString()}`);
            }
        };
    }

    /**
     * ONLY USE FOR MONITORING API (Or calling it from ADMIN API)
     */
    public getKeyAuthProxyForMonitoring(baseUrl: URL, logger: Logger, hostKey: string): AzureFunctionProxy<In, Out, Err, QueryParams> {
        return async (input: In, requestOptions: HttpRequestOptions = {}, queryParams?: QueryParams) => {
            const fullUrl: URL = new URL(this.getUrlEnd(input), baseUrl);
            try {
                const headers = { ...this.getAuthHeaders(hostKey) };
                const response = await sendRequest<Out>(logger, { url: fullUrl.toString(), httpVerb: this.httpVerb, headers: headers, body: input, params: queryParams }, requestOptions);
                return response.data;
            } catch (err: unknown) {
                return processOrThrowErrorResponse(err, this.expectedErrorTypes, `${this.httpVerb} to ${fullUrl.toString()}`);
            }
        };
    }

    private getAuthHeaders(hostKey: string): AuthHeaders {
        return { "x-functions-key": hostKey };
    }

    private getUserHeaders(context: BasicCurrentUserContext): UserHeaders {
        const headers: UserHeaders = { "x-aderant-tenantuniquename": context.currentUser.tenancy.uniqueName, "x-aderant-userid": context.currentUser.id };
        return headers;
    }
}
