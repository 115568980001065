import {
    AllLookups,
    alwaysYesPermissionsContext,
    BasicCurrentUserContext,
    ok,
    PermissionsContext,
    QuickSearch,
    Result,
    SearchVersionChanges,
    SearchVersionEdited,
    SearchVersionNew,
    SearchVersionUnedited,
    ValidationErrors
} from "aderant-conflicts-models";
import { Logger } from "@aderant/aderant-web-fw-core";
import { validateCore, validateForExistingSave } from "./inputValidator";

/**Prevalidated search input type, intended to always be created via passing unvalidated input into the static validate function. */
export class ValidatedSaveSearchInput {
    /**Obviously someone could still just manually an object that satisfies this type without actually calling validate.
     * The intent behind including this internal looking private field anyway is so that it's hopefully very obvious that handrolling this data instead of calling validate is the *wrong thing to do*, as well as being a little more difficult.
     */
    private _validatedSaveSearchInput: true;
    changes: SearchVersionChanges;
    search:
        | { existingSearchVersion: SearchVersionUnedited; searchVersion: SearchVersionEdited; reassignMessage?: string; statusNotificationMessage?: string }
        | { existingSearchVersion?: undefined; searchVersion: SearchVersionNew; reassignMessage?: string; statusNotificationMessage?: string }
        | { existingSearchVersion: QuickSearch; searchVersion: QuickSearch; reassignMessage?: undefined; statusNotificationMessage?: undefined };
    private constructor(
        search:
            | { existingSearchVersion: SearchVersionUnedited; searchVersion: SearchVersionEdited; reassignMessage?: string; statusNotificationMessage?: string }
            | { existingSearchVersion?: undefined; searchVersion: SearchVersionNew; reassignMessage?: string; statusNotificationMessage?: string }
            | { existingSearchVersion: QuickSearch; searchVersion: QuickSearch; reassignMessage?: undefined; statusNotificationMessage?: undefined },
        changes: SearchVersionChanges
    ) {
        this._validatedSaveSearchInput = true;
        this.search = search;
        this.changes = changes;
    }

    static async validate(
        context: PermissionsContext,
        input:
            | {
                  existingSearchVersion: SearchVersionUnedited;
                  searchVersion: SearchVersionEdited;
                  lookups: AllLookups;
                  reassignMessage?: string;
                  statusNotificationMessage?: string;
              }
            | {
                  existingSearchVersion?: undefined;
                  searchVersion: SearchVersionNew;
                  lookups: AllLookups;
                  reassignMessage?: string;
                  statusNotificationMessage?: string;
              }
            | {
                  existingSearchVersion: QuickSearch;
                  searchVersion: QuickSearch;
                  lookups: AllLookups;
                  reassignMessage?: undefined;
                  statusNotificationMessage?: undefined;
              },
        options?: { allowStatusChangeWhenCurrentUserIsNotAssigned?: boolean }
    ): Promise<Result<ValidatedSaveSearchInput, ValidationErrors>> {
        let result: Result<SearchVersionChanges, ValidationErrors>;
        if (input.existingSearchVersion !== undefined) {
            result = await validateForExistingSave(context, input.lookups, input.existingSearchVersion, input.searchVersion, input.reassignMessage, input.statusNotificationMessage, options);
        } else {
            result = validateCore(input.lookups, input.searchVersion);
        }
        if (!ok(result)) {
            return result;
        } else {
            return new ValidatedSaveSearchInput(input, result);
        }
    }

    static async validateAutomaticChanges(
        context: BasicCurrentUserContext & { logger: Logger },
        input:
            | { existingSearchVersion: SearchVersionUnedited; searchVersion: SearchVersionEdited; lookups: AllLookups }
            | { existingSearchVersion?: undefined; searchVersion: SearchVersionNew; lookups: AllLookups }
            | { existingSearchVersion: QuickSearch; searchVersion: QuickSearch; lookups: AllLookups },
        options?: { allowStatusChangeWhenCurrentUserIsNotAssigned?: boolean }
    ): Promise<Result<ValidatedSaveSearchInput, ValidationErrors>> {
        return ValidatedSaveSearchInput.validate(alwaysYesPermissionsContext(context), input, options);
    }

    static isValidatedSaveSearchInput(value: unknown): value is ValidatedSaveSearchInput {
        // justification: type guard
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const result = value as ValidatedSaveSearchInput;

        return (
            result._validatedSaveSearchInput === true &&
            typeof result.search === "object" &&
            typeof result.search.searchVersion === "object" &&
            (!result.search.existingSearchVersion || typeof result.search.existingSearchVersion === "object")
        );
    }
}
