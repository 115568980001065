import { getSearchStatusDisplayValue, HitStatus, HitStatuses, Lookup, QuickSearch, RequestTerm, SearchStatus, SearchVersion } from "aderant-conflicts-models";
import { aderantColors, formatShortDateTimeDisplay, RefinerGroup, RefinerGroupType } from "@aderant/aderant-react-components";
import { assertUnreachable } from "@aderant/aderant-web-fw-core";
import { getSearchProgress } from "Functions/search";
import jsPDF from "jspdf";
import autoTable, { CellHookData } from "jspdf-autotable";
import _ from "lodash";
import "npm-font-open-sans";
import { HitResult } from "../HitResult";
import { openSansBoldBase64Encoded } from "./opensans-bold";
import { openSansRegularBase64Encoded } from "./opensans-regular";
import { openSansItalicBase64Encoded } from "./opensans-italic";
import { getAffiliationDisplayName, getPartyStatusDisplayName } from "dataProviders/lookupList";
import { getProgressPercentage } from "pages/SearchesPage/SearchRequestRow";
import { Messages } from "./pdfMessages";
import { isValid as isValidDate } from "date-fns";

let currentPage = 0;
const pixelFactor = 1.4;
const leftMargin = 15;
const rightMargin = 15;
const topMargin = 15;
const lineHeight = 5.75;
const defaultFontSize = 9;

export const pdfMessages = {
    HEADER_SEARCH_DATE: Messages.HEADER_SEARCH_DATE.getMessage(),
    HEADER_REQUEST_STATUS: Messages.HEADER_REQUEST_STATUS.getMessage(),
    HEADER_REQUESTED_BY: Messages.HEADER_REQUESTED_BY.getMessage(),
    HEADER_DESCRIPTION: Messages.HEADER_DESCRIPTION.getMessage(),
    NO_DATA: Messages.NO_DATA.getMessage(),
    PAGE: Messages.PAGE.getMessage(),
    FROM: Messages.FROM.getMessage(),
    TO: Messages.TO.getMessage(),
    N_A: Messages.N_A.getMessage(),
    RESOLVED: Messages.RESOLVED.getMessage(),
    HITS: Messages.HITS.getMessage(),
    TERMS: Messages.TERMS.getMessage(),
    REQUEST_TERMS: Messages.REQUEST_TERMS.getMessage(),
    SEARCH_TERMS: Messages.SEARCH_TERMS.getMessage(),
    TERM: Messages.TERM.getMessage(),
    REQUEST_TERM: Messages.REQUEST_TERM.getMessage(),
    SEARCH_TERM: Messages.SEARCH_TERM.getMessage(),
    APPLIED_REFINERS: Messages.APPLIED_REFINERS.getMessage(),
    REFINED_VALUES: Messages.REFINED_VALUES.getMessage(),
    REDACTED: Messages.REDACTED.getMessage(),
    CONFLICTING_PARTY: Messages.CONFLICTING_PARTY.getMessage(),
    IS_REDACTED: Messages.IS_REDACTED.getMessage(),
    NOTE: Messages.NOTE.getMessage(),
    REFINER_NOTE: Messages.REFINER_NOTE.getMessage(),
    TOO_MANY_RESULTS: Messages.TOO_MANY_RESULTS.getMessage()
};

function getRequestTermWithAffiliationPartyStatus(term: string, requestTerms: RequestTerm[], affliationList: Lookup[], partyStatusList: Lookup[]) {
    const requestTerm = requestTerms.find((requestTerm) => requestTerm.term === term);
    if (!requestTerm) {
        return term;
    }
    const affiliation = requestTerm.affiliation ? getAffiliationDisplayName(requestTerm.affiliation, affliationList) : "";
    const partyStatus = requestTerm.partyStatus ? getPartyStatusDisplayName(requestTerm.partyStatus, partyStatusList) : "";
    const affiliationPartyStatus = affiliation && partyStatus ? `${affiliation}-${partyStatus}` : affiliation ? `${affiliation}` : partyStatus ? `${partyStatus}` : "";
    const requestTermString = affiliationPartyStatus ? `${requestTerm.term} (${affiliationPartyStatus})` : `${requestTerm.term}`;
    return requestTermString;
}

function getRequestAndSearchTermRows(searchVersion: SearchVersion | QuickSearch, affiliationList: Lookup[], partyStatusList: Lookup[]): string[][] {
    const requestAndSearchTerms: string[][] = [];
    searchVersion.requestTerms.forEach((requestTerm: RequestTerm) => {
        const term = getRequestTermWithAffiliationPartyStatus(requestTerm.term, searchVersion.requestTerms, affiliationList, partyStatusList);
        const requestTermString = requestTerm.hits ? `${term} (${requestTerm.hits.length})` : `${term} (0)`;
        requestAndSearchTerms.push([requestTermString, requestTerm.searchTerms.toString()]);
    });

    return requestAndSearchTerms;
}

function getRefinerRows(refiners: RefinerGroup[]): string[][] {
    const refinerRows: string[][] = [];
    refiners.forEach((refiner: RefinerGroup) => {
        let refinerName: string;
        let refinerValue: string;
        if (refiner.selectedSequence !== -1) {
            switch (refiner.refinerGroupType) {
                case RefinerGroupType.ListRefiner:
                    refinerName = refiner.name;
                    refinerValue = refiner.refinerOptions
                        .filter((ro) => ro.isSelected)
                        .map((ro) => ro.value)
                        .join("; ");
                    break;
                case RefinerGroupType.DateRange:
                    refinerName = refiner.name;
                    refinerValue =
                        pdfMessages.FROM +
                        " " +
                        (refiner.dateRange?.fromDate ? refiner.dateRange.fromDate.toDateString() : pdfMessages.N_A) +
                        "    " +
                        pdfMessages.TO +
                        " " +
                        (refiner.dateRange?.toDate ? refiner.dateRange.toDate.toDateString() : pdfMessages.N_A);
                    break;
            }
            refinerRows.push([refinerName, refinerValue]);
        }
    });
    return refinerRows;
}

function generatePageNumbersAndHitProgress(doc: jsPDF, progress: { progress: number; total: number }, isQuickSearch: boolean): void {
    //This can only be called after all pages are generated, as we need the page count
    const pageCount = doc.internal.pages.length;
    const width = doc.internal.pageSize.getWidth();
    //jspdf adds a blank page to doc.internal.pages[0] which is ignored during rendering
    for (let i = 1; i < pageCount; i++) {
        doc.setPage(i);
        const yPos = topMargin + lineHeight;
        const xPos = generatePageNumbers(doc, i, pageCount, width, yPos);

        //Generate hit progress
        if (!isQuickSearch) {
            generateHitProgress(doc, progress, xPos, yPos);
        } else {
            generateHitCount(doc, progress, xPos, yPos);
        }
    }
}

//Generates the page number text and returns the start x position of that text
function generatePageNumbers(doc: jsPDF, pageNumber: number, pageCount: number, initialXPos: number, yPos: number): number {
    const pageNumberText = " " + pageNumber.toString() + "/" + (pageCount - 1);
    doc.setTextColor(aderantColors.DeathStar);
    doc.setFontSize(defaultFontSize);
    let xPos = initialXPos - rightMargin;
    doc.text(pageNumberText, xPos, yPos, { align: "right" });
    const pageNumberTextLength = 2 + pageNumberText.length * pixelFactor;
    const pageText = "  |  " + pdfMessages.PAGE;
    xPos = xPos - pageNumberTextLength;
    doc.text(pageText, xPos, yPos, { align: "right" });
    xPos = xPos - 11;
    return xPos;
}

function generateHitProgress(doc: jsPDF, progress: { progress: number; total: number }, initialXPos: number, yPos: number): void {
    doc.setFontSize(defaultFontSize);
    doc.setTextColor(aderantColors.DarthVader);
    const percentageText = `${getProgressPercentage(progress.progress, progress.total)}% `;
    let xPos = initialXPos - 2;
    doc.text(percentageText, xPos, yPos, { align: "right" });
    xPos = xPos - Math.round((percentageText.length + 2) * pixelFactor);
    doc.setTextColor(aderantColors.DeathStar);
    const progressText = `${progress.progress}/${progress.total} ${pdfMessages.RESOLVED}`;
    doc.text(progressText, xPos, yPos, { align: "right" });
}

function generateHitCount(doc: jsPDF, progress: { progress: number; total: number }, initialXPos: number, yPos: number): void {
    doc.setFontSize(defaultFontSize);
    doc.setTextColor(aderantColors.DarthVader);
    const hitCountText = `${progress.total} ${pdfMessages.HITS}`;
    const xPos = initialXPos - 2;
    doc.text(hitCountText, xPos, yPos, { align: "right" });
}

function generateHeader(
    doc: jsPDF,
    searchName: string,
    description: string[],
    searchVersionNumber: string,
    requestStatus: SearchStatus,
    hasRefiners: boolean,
    searchDate?: string,
    user?: string,
    isQuickSearch?: boolean
) {
    if (currentPage < doc.internal.pages.length) {
        if (currentPage == 0) {
            doc.setFontSize(16);
        } else {
            doc.setFontSize(14);
        }
        const splitText: string[] = doc.splitTextToSize(searchName, currentPage == 1 ? 200 : 240);
        //Hide search number for quick searches
        const titleSuffix = isQuickSearch ? "" : searchVersionNumber;
        let pdfTitle = splitText[0] + " " + titleSuffix;
        if (splitText.length > 1) {
            pdfTitle = splitText[0] + "... " + titleSuffix;
        }
        let yPos = topMargin;
        let xPos = leftMargin;
        doc.text(pdfTitle, xPos, yPos);

        doc.setFontSize(defaultFontSize);
        doc.setTextColor(aderantColors.DeathStar);
        yPos = yPos += lineHeight;
        doc.text(pdfMessages.HEADER_SEARCH_DATE, xPos, yPos);
        doc.setTextColor(aderantColors.DarthVader);
        xPos += Math.round(pdfMessages.HEADER_SEARCH_DATE.length * pixelFactor);
        doc.text(searchDate ?? pdfMessages.NO_DATA, xPos, yPos);

        //Hide request status, requested by user and description for quick searches
        if (!isQuickSearch) {
            xPos += searchDate ? Math.round((searchDate.length + 2) * pixelFactor) : 10;
            doc.setTextColor(aderantColors.DeathStar);
            doc.text("  |  " + pdfMessages.HEADER_REQUEST_STATUS, xPos, yPos);
            xPos += Math.round((pdfMessages.HEADER_REQUEST_STATUS.length + 5) * pixelFactor);
            doc.setTextColor(aderantColors.DarthVader);
            const requestStatusDisplayValue = getSearchStatusDisplayValue(requestStatus);
            requestStatus && doc.text(requestStatusDisplayValue, xPos, yPos);
            xPos += requestStatus ? Math.round((requestStatusDisplayValue.length + 2) * pixelFactor) : 10;
            doc.setTextColor(aderantColors.DeathStar);
            doc.text("  |  " + pdfMessages.HEADER_REQUESTED_BY, xPos, yPos);
            xPos += Math.round((pdfMessages.HEADER_REQUESTED_BY.length + 6) * pixelFactor);
            doc.setTextColor(aderantColors.DarthVader);
            user && doc.text(user, xPos, yPos);
            yPos += lineHeight;
            xPos = leftMargin;
            if (doc.getCurrentPageInfo().pageNumber === 1 && description.length > 0) {
                doc.setTextColor(aderantColors.DeathStar);
                doc.text(pdfMessages.HEADER_DESCRIPTION, xPos, yPos);
                xPos += Math.round((pdfMessages.HEADER_DESCRIPTION.length + 1) * pixelFactor);
                doc.setTextColor(aderantColors.DarthVader);
                let printedRow = 0;
                for (let descriptionRow = 0; descriptionRow < description.length && printedRow < 5; descriptionRow++) {
                    if (description[descriptionRow]) {
                        let descriptionText = description[descriptionRow];
                        if (printedRow === 4 && descriptionRow < description.length - 1) {
                            descriptionText = descriptionText + "...";
                        }
                        doc.text(descriptionText, xPos, yPos, { align: "justify" });
                        printedRow++;
                        yPos += lineHeight;
                    }
                }
            }
        } else {
            yPos += lineHeight;
        }

        if (hasRefiners && doc.getCurrentPageInfo().pageNumber === 1) {
            xPos = leftMargin;
            doc.setFontSize(defaultFontSize);
            doc.setFont("Open Sans", "normal", "bold");
            doc.text(pdfMessages.NOTE, xPos, yPos);
            xPos += Math.round((pdfMessages.NOTE.length + 2) * pixelFactor);
            doc.setFont("Open Sans", "italic", "normal");
            doc.text(pdfMessages.REFINER_NOTE, xPos, yPos);
            doc.setFont("Open Sans", "normal", "normal");
        }
        currentPage = doc.internal.pages.length;
    }
}

function generateTermsTable(doc: jsPDF, termsRows: string[][], marginTop: number, header: () => void, isQuickSearch?: boolean) {
    autoTable(doc, {
        head: [isQuickSearch ? [pdfMessages.TERMS] : [pdfMessages.REQUEST_TERMS, pdfMessages.SEARCH_TERMS]],
        tableLineWidth: 0.25,
        tableLineColor: aderantColors.TIEFighter,
        styles: {
            lineColor: aderantColors.TIEFighter,
            lineWidth: 0.25,
            font: "Open Sans",
            fontStyle: "normal"
        },
        headStyles: {
            fillColor: aderantColors.Galaxy,
            fontSize: 8,
            fontStyle: "normal"
        },
        body: termsRows,
        bodyStyles: {
            fillColor: aderantColors.Stormtrooper,
            fontSize: 8,
            textColor: aderantColors.DarthVader
        },
        alternateRowStyles: {
            fillColor: aderantColors.Stormtrooper
        },
        columnStyles: {
            0: isQuickSearch ? { fontStyle: "bold" } : { cellWidth: 60, fontStyle: "bold" }
        },
        startY: doc.getNumberOfPages() > 1 ? undefined : marginTop,
        margin: { top: 20 },
        didDrawPage: function (data) {
            header();
        }
    });
}

function generateRefinersTable(doc: jsPDF, refinerRows: string[][], marginTop: number, header: () => void) {
    autoTable(doc, {
        head: [[pdfMessages.APPLIED_REFINERS, pdfMessages.REFINED_VALUES]],
        tableLineWidth: 0.25,
        tableLineColor: aderantColors.TIEFighter,
        styles: {
            lineColor: aderantColors.TIEFighter,
            lineWidth: 0.25,
            font: "Open Sans",
            fontStyle: "normal"
        },
        headStyles: {
            fillColor: aderantColors.Galaxy,
            fontSize: 8,
            fontStyle: "normal"
        },
        body: refinerRows,
        bodyStyles: {
            fillColor: aderantColors.Stormtrooper,
            fontSize: 8,
            textColor: aderantColors.DarthVader
        },
        alternateRowStyles: {
            fillColor: aderantColors.Stormtrooper
        },
        columnStyles: {
            0: { cellWidth: 60 }
        },
        startY: marginTop,
        margin: { top: 25 },
        didDrawPage: function (data) {
            header();
        }
    });
}

function mapResultsIntoDataMap(
    currentColumns: Map<string, { displayName: string; sequence?: number }>,
    results: HitResult[],
    requestTerms: RequestTerm[],
    affiliationList: Lookup[],
    partyStatusList: Lookup[]
): Map<{ requestTermId: string; requestTerm: string; searchTerm: string }, string[][]> {
    const dataMap: Map<{ requestTermId: string; requestTerm: string; searchTerm: string }, string[][]> = new Map();
    results.forEach((result) => {
        const rawData: string[] = [];
        currentColumns.forEach((col, key) => {
            if (key === "conflictingParty") {
                //we want conflicting party to always be at end so when it's hidden within table it doesn't create a space
                //(this column currently doesn't exist in the default config, but excluding it anyway in case it gets customized)
                return;
            }
            if (key === "contextIcons") {
                //used for the conflicting party and padlock icons on the grid, we handle this differently on the report (using the conflictingParty field on the underlying data)
                return;
            }
            rawData.push(`${col.displayName}:`);
            if (result[key] === "**Redacted**") {
                rawData.push(pdfMessages.REDACTED);
            } else if (result[key] === "undefined" || result[key] === "null" || !result[key]) {
                rawData.push(pdfMessages.NO_DATA);
            } else if (isValidDate(result[key])) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const resultKeyAsDate = result[key] as Date;
                if (resultKeyAsDate?.toDateString) {
                    rawData.push(`${resultKeyAsDate.toDateString()}`);
                } else {
                    rawData.push(`${result[key]}`);
                }
            } else {
                rawData.push(`${result[key]}`);
            }
        });
        rawData.push(pdfMessages.CONFLICTING_PARTY);
        rawData.push(`${result["conflictingParty"]}`);
        rawData.push(pdfMessages.IS_REDACTED);
        rawData.push(`${result["isRedacted"]}`);
        const chunkedData = _.chunk(rawData, 6);
        result.requestTerm &&
            result.requestTermId &&
            dataMap.set(
                {
                    requestTermId: result.requestTermId.toString(),
                    requestTerm: getRequestTermWithAffiliationPartyStatus(result.requestTerm.toString(), requestTerms, affiliationList, partyStatusList),
                    searchTerm: result.searchTermsCommaSeparated.toString()
                },
                chunkedData
            );
    });
    return dataMap;
}

function generateHitResultTables(doc: jsPDF, columns: { requestTerm: string; searchTerm: string }, rows: string[][], header: () => void, firstHitResultPage: boolean, isQuickSearch?: boolean) {
    const width = doc.internal.pageSize.getWidth();

    if (columns.searchTerm.length > 30) {
        columns.searchTerm = columns.searchTerm.substring(0, 30) + "...";
    }
    autoTable(doc, {
        head: [
            isQuickSearch
                ? [
                      { content: pdfMessages.TERM, colSpan: 1, styles: { fontStyle: "bold" } },
                      { content: columns.requestTerm, colSpan: 5 }
                  ]
                : [
                      { content: pdfMessages.REQUEST_TERM, colSpan: 1, styles: { fontStyle: "bold" } },
                      { content: columns.requestTerm, colSpan: 1 },
                      { content: pdfMessages.SEARCH_TERM, colSpan: 1, styles: { fontStyle: "bold" } },
                      { content: columns.searchTerm, colSpan: 3 }
                  ]
        ],
        body: rows,
        tableLineWidth: 0.25,
        // Justification: JS Plugin, lastAutoTable does exist on doc but isn't typed within JSPDF, so use any type.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
        startY: firstHitResultPage ? 25 : (doc as any).lastAutoTable.finalY + 3,
        styles: { font: "Open Sans", fontStyle: "normal", cellPadding: { top: 1, bottom: 1, right: 1, left: 1 } },
        margin: { top: 25 },
        headStyles: {
            fillColor: aderantColors.Lightsaber45,
            fontSize: 8,
            lineWidth: { bottom: 0.25 },
            textColor: aderantColors.DarthVader
        },
        bodyStyles: {
            fillColor: aderantColors.Stormtrooper,
            fontSize: 8,
            textColor: aderantColors.DarthVader
        },
        alternateRowStyles: {
            fillColor: aderantColors.Stormtrooper
        },
        columnStyles: {
            0: { font: "Open Sans", fontStyle: "bold", cellWidth: 28, halign: "left" },
            1: { cellWidth: width / 3 - 37.4 },
            2: { fontStyle: "bold", cellWidth: 28, halign: "left", lineWidth: { left: 0.25 } },
            3: { cellWidth: width / 3 - 37.4 },
            4: { fontStyle: "bold", cellWidth: 28, halign: "left", lineWidth: { left: 0.25 } },
            5: { cellWidth: width / 3 - 37.4 }
        },
        willDrawCell: (cell: CellHookData) => {
            generateCellStyling(doc, cell);
        },
        didDrawPage: function (data) {
            header();
        }
    });
}

export function generatePdf(
    searchVersion: SearchVersion | QuickSearch,
    results: HitResult[],
    user: string,
    gridColumns: Map<string, { displayName: string; sequence?: number }>,
    affliationList: Lookup[],
    partyStatusList: Lookup[],
    refiners?: RefinerGroup[],
    inputDoc?: jsPDF
): void {
    currentPage = 0;
    //inputDoc is used in tests so the test knows the jsPDF class instance
    const doc =
        inputDoc ??
        new jsPDF({
            orientation: "landscape"
        });
    doc.addFileToVFS("Open Sans-bold.ttf", openSansBoldBase64Encoded);
    doc.addFont("Open Sans-bold.ttf", "Open Sans", "bold");
    doc.addFileToVFS("Open Sans-normal.ttf", openSansRegularBase64Encoded);
    doc.addFont("Open Sans-normal.ttf", "Open Sans", "normal");
    doc.addFileToVFS("Open Sans-italic.ttf", openSansItalicBase64Encoded);
    doc.addFont("Open Sans-italic.ttf", "Open Sans", "italic");
    doc.setFont("Open Sans", undefined, "normal");
    doc.setTextColor(aderantColors.DarthVader);
    const termsRows: string[][] = getRequestAndSearchTermRows(searchVersion, affliationList, partyStatusList);
    const refinerRows: string[][] = getRefinerRows(refiners ?? []);
    const searchVersionNumber = searchVersion.version > 1 ? "(" + searchVersion.number?.toString() + "." + searchVersion.version.toString() + ")" : "(" + searchVersion.number?.toString() + ")";
    const progress = getSearchProgress(searchVersion.summary?.hitCountByStatus);
    const description: string[] = searchVersion.description ? doc.splitTextToSize(searchVersion.description, 400) : [];
    const hasRefiners = refinerRows.length > 0;
    const headerFunction = function () {
        generateHeader(
            doc,
            searchVersion.name,
            description,
            searchVersionNumber,
            searchVersion.status,
            hasRefiners,
            formatShortDateTimeDisplay(searchVersion.searchDate),
            user,
            searchVersion.isQuickSearch
        );
    };

    let termsTableMarginTop = 25;

    if (!searchVersion.isQuickSearch) {
        //Add extra margin for up to 5 lines of description
        const printedDescription = description.filter((d) => d); // We only print non-blank lines
        const numberOfLines = printedDescription.length <= 5 ? printedDescription.length : 5;
        termsTableMarginTop += lineHeight * numberOfLines;
    }
    if (refinerRows.length > 0) {
        termsTableMarginTop += lineHeight; //Add extra margin to cater for the refiner note
    }
    generateTermsTable(doc, termsRows, termsTableMarginTop, headerFunction, searchVersion.isQuickSearch);
    if (refinerRows.length > 0) {
        // Justification: lastAutoTable does exist on doc but isn't typed within JSPDF, so use any type.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const finalY = (doc as any).lastAutoTable.finalY;
        const refinerRowsTableMarginTop = finalY + lineHeight; //leave 1 blank line between terms and refiners
        generateRefinersTable(doc, refinerRows, refinerRowsTableMarginTop, headerFunction);
    }

    if (results && results.length > 0) {
        doc.addPage();
        const resultData = hasRefiners ? getRefinedResults(results, searchVersion.requestTerms ?? []) : results;
        if (resultData.length > 4000) {
            throw Error(pdfMessages.TOO_MANY_RESULTS);
        }
        console.log("results length after:", results.length);
        const dataMap = mapResultsIntoDataMap(gridColumns, resultData, searchVersion.requestTerms, affliationList, partyStatusList);
        let firstHitResultPage = true;
        let currentKey: { requestTermId: string; requestTerm: string; searchTerm: string };
        dataMap.forEach((v, k) => {
            if (currentKey && currentKey.requestTermId !== k.requestTermId && !firstHitResultPage) {
                doc.addPage();
                firstHitResultPage = true;
            }
            currentKey = k;
            generateHitResultTables(doc, k, v, headerFunction, firstHitResultPage, searchVersion.isQuickSearch);
            firstHitResultPage = false;
        });
    }
    generatePageNumbersAndHitProgress(doc, progress, searchVersion.isQuickSearch ?? false);
    const blobPDF = doc.output("blob");
    const blobURL = URL.createObjectURL(blobPDF);
    window.open(blobURL);
}

function getRefinedResults(results: HitResult[], requestTerms: RequestTerm[]): HitResult[] {
    // we want the results grouped by request term, but in the order the request terms exist in
    //the search's request terms array
    //we can't sort the data as sorting by requestTerm or requestTermId gives different results
    //to the order the request terms exist in the search's request terms array
    const refinedResults: HitResult[] = [];
    requestTerms.forEach((requestTerm) => {
        results.forEach((result) => {
            if (result.requestTermId === requestTerm.id) {
                refinedResults.push(result);
            }
        });
    });
    return refinedResults;
}

function generateCellStyling(doc: jsPDF, cell: CellHookData) {
    if (cell.row.section === undefined && cell.row.section === "head") {
        cell.column.width = 0;
    }
    if (cell.row.section === "body" && cell.row.section) {
        //We are going through each row but only want to change styling based on the headings. i+2 skips the cells with values in them.
        for (let i = 0; i < 5; i = i + 2) {
            if (cell.row.raw[i] === "Hit Status:" || cell.row.raw[i] === "Previous Hit Status:") {
                // previous hit status can be "-"
                const hitStatus: HitStatus | "-" = cell.row.raw[i + 1];
                switch (hitStatus) {
                    case HitStatuses.NoConflict:
                        cell.row.cells[i + 1].text = ["No Conflict"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.BobaFett);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.BobaFett, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case HitStatuses.NotApplicable:
                        cell.row.cells[i + 1].text = ["Not Applicable"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.Watto);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.Watto, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case HitStatuses.Conflict:
                        cell.row.cells[i + 1].text = ["Conflict"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.DarthMaul);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthMaul, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case HitStatuses.Unactioned:
                        cell.row.cells[i + 1].text = ["Unactioned"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.DeathStar);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DeathStar, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case HitStatuses.InProgress:
                        cell.row.cells[i + 1].text = ["In Progress"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.R2D2);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.R2D2, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case HitStatuses.SeekingWaiver:
                        cell.row.cells[i + 1].text = ["Seeking Waiver"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.C3PO);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.C3PO, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case HitStatuses.Confirm:
                        cell.row.cells[i + 1].text = ["Confirm"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.R2D2);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.R2D2, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case HitStatuses.WaiverRequired:
                        cell.row.cells[i + 1].text = ["Waiver Required"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.BB8);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.BB8, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case HitStatuses.WaiverOnFile:
                        cell.row.cells[i + 1].text = ["Waiver On File"];
                        if (cell.row.raw[i] === "Hit Status:") {
                            doc.setFillColor(aderantColors.JarJarBinks);
                            doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 2, cell.row.cells[i + 1].getTextPos().y + 1.5, 1, 1, "F");
                            doc.setFillColor(aderantColors.Stormtrooper);
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.JarJarBinks, fontStyle: "bold" };
                        } else {
                            cell.row.cells[i + 1].styles = { ...cell.row.cells[i + 1].styles, textColor: aderantColors.DarthVader, fontStyle: "normal" };
                        }
                        break;
                    case "-":
                        break;
                    default:
                        assertUnreachable(hitStatus);
                }
            }
            if (cell.row.raw[i] === "Name:") {
                cell.table.body.forEach((row) => {
                    if (row.raw.toString().includes(`${pdfMessages.CONFLICTING_PARTY},true`)) {
                        doc.setFillColor(aderantColors.DarthMaul);
                        doc.rect(cell.row.cells[i + 1].getTextPos().x - 6.375, cell.row.cells[i + 1].getTextPos().y - 0.5, 0.75, 2.5, "F");
                        doc.ellipse(cell.row.cells[i + 1].getTextPos().x - 6, cell.row.cells[i + 1].getTextPos().y + 3, 0.375, 0.375, "F");
                        doc.setFillColor(aderantColors.Stormtrooper);
                    }
                    if (row.raw.toString().includes(`${pdfMessages.IS_REDACTED},true`)) {
                        doc.setLineWidth(0.3);
                        doc.setFillColor(aderantColors.DeathStar);
                        doc.setDrawColor(aderantColors.DeathStar);
                        doc.roundedRect(cell.row.cells[i + 1].getTextPos().x - 3.4, cell.row.cells[i + 1].getTextPos().y - 0.2, 1.3, 3, 0.65, 0.65, "S");
                        doc.roundedRect(cell.row.cells[i + 1].getTextPos().x - 4, cell.row.cells[i + 1].getTextPos().y + 1, 2.5, 2.1, 0.35, 0.35, "F");
                        doc.setFillColor(aderantColors.Stormtrooper);
                    }
                });
            }
            //The conflicting and redacted cells need to be rendered as we need the information for the logic above but we can clear the text in the cells so they are effectively hidden.
            if (cell.row.raw[i] === pdfMessages.CONFLICTING_PARTY) {
                cell.row.cells[i].text = [""];
                cell.row.cells[i + 1].text = [""];
            }
            if (cell.row.raw[i] === pdfMessages.IS_REDACTED) {
                cell.row.cells[i].text = [""];
                cell.row.cells[i + 1].text = [""];
            }
        }
    }
}
