import * as MyTypes from "MyTypes";
import _ from "lodash";
import { assertUnreachable } from "@aderant/aderant-web-fw-core";
import { AdminActionType } from "state/actions/AdminActions";
import { EditableFieldValue, FirmSettings, IntegrationStatus, SynonymMap } from "aderant-conflicts-models";
import { AppFetchedFirmSetting } from "App";

export type DataLoadedState = { dataToLoad: string; isLoaded: boolean; isErrored: boolean };
export type PageDefinitionState = { [k in FirmSettings.PageDefinitionName]: boolean };
export type PageDataLoadedState = { [k in FirmSettings.PageDefinitionName]: DataLoadedState[] };
// justification: couldn't find an easy way for constructing this object with type safety
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const isPersistingDefaultValue: PageDefinitionState = FirmSettings.sortedPageDefinitions.reduce((a, v) => ({ ...a, [v.name]: false }), {}) as PageDefinitionState;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const isFetchingDefaultValue: PageDefinitionState = FirmSettings.sortedPageDefinitions.reduce((a, v) => ({ ...a, [v.name]: false }), {}) as PageDefinitionState;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const isLoadedDefaultValue: PageDataLoadedState = FirmSettings.sortedPageDefinitions.reduce(
    (a, v) => ({
        ...a,
        [v.name]: v.dataToLoad.map((d) => {
            return { dataToLoad: d, isLoaded: false, isErrored: false };
        })
    }),
    {}
) as PageDataLoadedState;

const resetDataLoaded = (dataToLoad: string, currentPageDataLoaded: DataLoadedState[]): DataLoadedState[] => {
    const newPageDataLoaded = _.cloneDeep(currentPageDataLoaded);
    //map is being used to change the specific DataLoadedState object in the array
    newPageDataLoaded.map((d: DataLoadedState) => {
        if (d.dataToLoad === dataToLoad) {
            d.isLoaded = false;
            d.isErrored = false;
        }
    });
    return newPageDataLoaded;
};

const setDataLoaded = (dataToLoad: string, currentPageDataLoaded: DataLoadedState[]): DataLoadedState[] => {
    const newPageDataLoaded = _.cloneDeep(currentPageDataLoaded);
    //map is being used to change the specific DataLoadedState object in the array
    newPageDataLoaded.map((d: DataLoadedState) => {
        if (d.dataToLoad === dataToLoad) {
            d.isLoaded = true;
        }
    });
    return newPageDataLoaded;
};

function setIsErrored(dataToError: string, currentPageDataLoaded: DataLoadedState[]): DataLoadedState[] {
    const newPageDataLoaded = _.cloneDeep(currentPageDataLoaded);
    //map is being used to change the specific DataLoadedState object in the array
    newPageDataLoaded.map((d: DataLoadedState) => {
        if (d.dataToLoad === dataToError) {
            d.isErrored = true;
        }
    });
    return newPageDataLoaded;
}

export const isAllPageDataLoadedOrNotErrored = (pageDataLoaded: DataLoadedState[]): boolean => {
    return pageDataLoaded.every((d) => d.isLoaded) || !pageDataLoaded.some((d) => d.isErrored);
};

export type PageEditState = { [k in FirmSettings.PageDefinitionName]?: boolean } & { "synonym-management:status-toggle"?: boolean };

export interface AdminState {
    synonymMap?: SynonymMap;
    apiKeys?: { keys?: { uniqueKeyName: string }[]; generatedApiKey?: string };
    isPersisting: PageDefinitionState;
    isFetching: PageDefinitionState;
    isLoaded: PageDataLoadedState;
    errors: string[];
    //separate hardcoded status toggle state because synonyms page has two separate things it persists
    isEdited: PageEditState;
    isSynonymsToggledOn?: boolean;
    firmSettings: Partial<FirmSettings.PageDataModel>;
    integration: { ingestionStatuses: IntegrationStatus[] };
    rlsColumnVisibilityConfig?: string[];
}

export const initialState: AdminState = {
    synonymMap: undefined,
    errors: [],
    isPersisting: isPersistingDefaultValue,
    isFetching: isFetchingDefaultValue,
    isLoaded: isLoadedDefaultValue,
    isEdited: {},
    isSynonymsToggledOn: undefined,
    firmSettings: {},
    integration: { ingestionStatuses: [] }
};

/**
 * place fetched settings values on the firm settings object based on their paths.
 * this is so that the admin pages for editing settings can put the whole page of settings in the same place and app side consumption will get the same values from the same paths
 * @param fieldPaths the fetched paths
 * @param fetchedSettings the fetched settings values, in the same order as the paths
 * @param currentFirmSettings the current firm settings object
 * @returns the new firm settings object
 */
function constructFirmSettingsFromFetchedSettings(
    fetchedSettings: Record<AppFetchedFirmSetting, { value: EditableFieldValue }>,
    currentFirmSettings: Partial<FirmSettings.PageDataModel>
): Partial<FirmSettings.PageDataModel> {
    const newFirmSettings = _.cloneDeep(currentFirmSettings);
    Object.entries(fetchedSettings).forEach(([path, response]) => {
        const value = response.value;
        const split = path.split("/");

        if (!newFirmSettings[split[0]]) {
            newFirmSettings[split[0]] = {};
        }
        if (!newFirmSettings[split[0]][split[1]]) {
            newFirmSettings[split[0]][split[1]] = {};
        }

        newFirmSettings[split[0]][split[1]][split[2]] = value;
    });

    return newFirmSettings;
}

export const adminReducer = (state: AdminState = initialState, action: MyTypes.RootAdminAction): AdminState => {
    switch (action.type) {
        case AdminActionType.ADMIN_FAILURE: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case AdminActionType.ADMIN_FETCH_FAILURE: {
            return {
                ...state,
                errors: [...state.errors, action.payload.error],
                isLoaded: { ...state.isLoaded, [action.payload.pageName]: setIsErrored(action.payload.dataName, state.isLoaded[action.payload.pageName]) }
            };
        }
        case AdminActionType.UPDATE_SYNONYM_MAP_SUCCESS:
        case AdminActionType.FETCH_SYNONYM_MAP_SUCCESS: {
            return {
                ...state,
                isEdited: { ...state.isEdited, [FirmSettings.SynonymManagementDefinition.name]: false },
                isLoaded: { ...state.isLoaded, [FirmSettings.SynonymManagementDefinition.name]: setDataLoaded("SYNONYM_MAP", state.isLoaded[FirmSettings.SynonymManagementDefinition.name]) },
                synonymMap: action.payload
            };
        }
        case AdminActionType.UPDATE_SYNONYM_GROUP: {
            if (state.synonymMap?.synonymGroups) {
                const updateSynonymGroups = [...state.synonymMap.synonymGroups];
                updateSynonymGroups[action.payload.synonymGroupIndex] = action.payload.updatedSynonymGroup;
                return {
                    ...state,
                    isEdited: { ...state.isEdited, [FirmSettings.SynonymManagementDefinition.name]: true },
                    synonymMap: {
                        ...state.synonymMap,
                        synonymGroups: updateSynonymGroups
                    }
                };
            }

            return {
                ...state
            };
        }
        case AdminActionType.CREATE_SYNONYM_GROUP: {
            if (state.synonymMap) {
                return {
                    ...state,
                    isEdited: { ...state.isEdited, [FirmSettings.SynonymManagementDefinition.name]: true },
                    synonymMap: {
                        ...state.synonymMap,
                        synonymGroups: [...state.synonymMap.synonymGroups, action.payload.newSynonymGroup]
                    }
                };
            }
            return {
                ...state
            };
        }
        case AdminActionType.IS_PERSISTING: {
            return { ...state, isPersisting: { ...state.isPersisting, [action.payload.pageName]: action.payload.isPersisting } };
        }
        case AdminActionType.UPDATE_SYNONYMS_STATUS_SUCCESS:
        case AdminActionType.FETCH_SYNONYMS_STATUS_SUCCESS: {
            return {
                ...state,
                isSynonymsToggledOn: action.payload === "ON",
                isEdited: { ...state.isEdited, [`${FirmSettings.SynonymManagementDefinition.name}:status-toggle`]: false },
                isLoaded: { ...state.isLoaded, [FirmSettings.SynonymManagementDefinition.name]: setDataLoaded("SYNONYMS_STATUS", state.isLoaded[FirmSettings.SynonymManagementDefinition.name]) }
            };
        }
        case AdminActionType.IS_FETCHING: {
            return {
                ...state,
                isFetching: { ...state.isFetching, [action.payload.pageName]: action.payload.isFetching },
                isLoaded: action.payload.isFetching
                    ? { ...state.isLoaded, [action.payload.pageName]: resetDataLoaded(action.payload.dataName, state.isLoaded[action.payload.pageName]) }
                    : { ...state.isLoaded }
            };
        }
        case AdminActionType.FETCH_INGESTION_STATUS_SUCCESS: {
            return {
                ...state,
                integration: { ingestionStatuses: action.payload },
                isLoaded: { ...state.isLoaded, [FirmSettings.IntegrationDefinition.name]: setDataLoaded("INGESTION_STATUS", state.isLoaded[FirmSettings.IntegrationDefinition.name]) }
            };
        }
        case AdminActionType.FETCH_INGESTION_STATUS:
        case AdminActionType.FETCH_FIRM_SETTINGS_PAGE_DATA:
        case AdminActionType.SAVE_FIRM_SETTINGS_PAGE_DATA:
        case AdminActionType.FETCH_SYNONYMS_STATUS:
        case AdminActionType.UPDATE_SYNONYMS_STATUS:
        case AdminActionType.UPDATE_SYNONYM_MAP:
        case AdminActionType.FETCH_API_KEYS:
        case AdminActionType.REVOKE_API_KEY:
        case AdminActionType.GENERATE_API_KEY:
        case AdminActionType.REGENERATE_API_KEY:
        case AdminActionType.FETCH_FIRM_SETTINGS:
        case AdminActionType.SAVE_RLS_COLUMN_VISIBILITY:
        case AdminActionType.FETCH_RLS_COLUMN_VISIBILITY:
        case AdminActionType.FETCH_SYNONYM_MAP: {
            return {
                ...state
            };
        }
        case AdminActionType.DELETE_SYNONYM_GROUP: {
            if (state.synonymMap) {
                const newSynonymGroups = [...state.synonymMap.synonymGroups];
                newSynonymGroups.splice(action.payload, 1);
                return {
                    ...state,
                    isEdited: { ...state.isEdited, [FirmSettings.SynonymManagementDefinition.name]: true },
                    synonymMap: {
                        ...state.synonymMap,
                        synonymGroups: newSynonymGroups
                    }
                };
            }
            return state;
        }
        case AdminActionType.TOGGLE_SYNONYM_STATUS: {
            return {
                ...state,
                isSynonymsToggledOn: action.payload.synonymMapToggle,
                isEdited: { ...state.isEdited, [`${FirmSettings.SynonymManagementDefinition.name}:status-toggle`]: action.payload.synonymMapToggle !== state.isSynonymsToggledOn }
            };
        }
        case AdminActionType.FETCH_API_KEYS_SUCCESS: {
            return {
                ...state,
                apiKeys: { keys: action.payload.keys },
                isLoaded: { ...state.isLoaded, [FirmSettings.ApiKeyManagementDefinition.name]: setDataLoaded("API_KEYS", state.isLoaded[FirmSettings.ApiKeyManagementDefinition.name]) }
            };
        }
        case AdminActionType.REVOKE_API_KEY_SUCCESS: {
            return {
                ...state,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                apiKeys: { keys: state.apiKeys!.keys!.filter((key) => key.uniqueKeyName !== action.payload) }
            };
        }
        case AdminActionType.REGENERATE_API_KEY_SUCCESS: {
            return {
                ...state,
                apiKeys: {
                    keys: state.apiKeys?.keys && [...state.apiKeys.keys],
                    generatedApiKey: action.payload.generatedApiKey
                }
            };
        }
        case AdminActionType.GENERATE_API_KEY_SUCCESS: {
            return {
                ...state,
                apiKeys: {
                    keys:
                        state.apiKeys?.keys && !state.apiKeys.keys.find((key) => key.uniqueKeyName === action.payload.uniqueKeyName)
                            ? [...state.apiKeys.keys, { uniqueKeyName: action.payload.uniqueKeyName }]
                            : [{ uniqueKeyName: action.payload.uniqueKeyName }],
                    generatedApiKey: action.payload.generatedApiKey
                }
            };
        }
        case AdminActionType.SAVE_RLS_COLUMN_VISIBILITY_SUCCESS:
        case AdminActionType.FETCH_RLS_COLUMN_VISIBILITY_SUCCESS:
            return {
                ...state,
                rlsColumnVisibilityConfig: action.payload.rlsVisibleColumns,
                isLoaded: {
                    ...state.isLoaded,
                    [FirmSettings.InformationBarriersDefinition.name]: setDataLoaded("RLS_COLUMN_VISIBILITY", state.isLoaded[FirmSettings.InformationBarriersDefinition.name])
                }
            };

        case AdminActionType.CLEAR_GENERATED_API_KEY: {
            return {
                ...state,
                apiKeys: {
                    ...state.apiKeys,
                    generatedApiKey: undefined
                }
            };
        }
        case AdminActionType.FETCH_FIRM_SETTINGS_SUCCESS: {
            const fetchedFirmSettings = constructFirmSettingsFromFetchedSettings(action.payload.firmSettings, state.firmSettings);
            return {
                ...state,
                firmSettings: fetchedFirmSettings
            };
        }
        case AdminActionType.FETCH_FIRM_SETTINGS_PAGE_DATA_SUCCESS: {
            return {
                ...state,
                isEdited: {
                    ...state.isEdited,
                    [action.payload.pageName]: false
                },
                isLoaded: { ...state.isLoaded, [action.payload.pageName]: setDataLoaded("FIRM_SETTINGS_PAGE_DATA", state.isLoaded[action.payload.pageName]) },
                firmSettings: {
                    ...state.firmSettings,
                    [action.payload.pageName]: action.payload.pageData
                }
            };
        }
        case AdminActionType.SAVE_FIRM_SETTINGS_PAGE_DATA_SUCCESS: {
            const newState = {
                ...state,
                isEdited: {
                    ...state.isEdited,
                    [action.payload.pageName]: false
                },
                firmSettings: {
                    ...state.firmSettings,
                    [action.payload.pageName]: action.payload.pageData
                }
            };
            return newState;
        }
        case AdminActionType.SET_IS_PAGE_EDITED: {
            return {
                ...state,
                isEdited: {
                    ...state.isEdited,
                    [action.payload.pageName]: action.payload.isPageEdited
                }
            };
        }
    }

    /**sometimes redux will throw internal actions at reducers (e.g. @@redux/INIT), and it expects you to send back the state unchanged
    /*so our "exhaustive" switch statement isn't actually exhaustive. We want to return the current state *at runtime* if we get an unknown action
    /*to conform with redux expectations/best practices, but it's nice to get a compile time error if we haven't explicitly handled any of our own actions
    /*
    /*the wacky "if undefined || not undefined" statement is because just having a non conditional return with a line after it is not valid code. 
    */
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if (typeof (action as any).type === "undefined" || typeof (action as any).type !== "undefined") {
        return state;
    }
    assertUnreachable(action);
};
