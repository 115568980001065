import { SearchIndexer } from "./SearchIndexConnector";
import { Logger } from "@aderant/aderant-web-fw-core";
import Bluebird from "bluebird";
import { getSearchIndexName, mapPriorSearchIndex, PriorSearch, QuickSearch, SearchVersion } from "aderant-conflicts-models";
import { DeltaType, RecordBatch } from "./Types";
import { actionRecordBatch, createRecordBatch } from "./IngestSearchData";

const BATCH_UPDATE_SIZE = 1000;
const ENTITY_TYPE = "priorsearch";

/**
 * Records are created, updated, or deleted in batches of <= 1000 items.  This is a limitation of the Transactional Batch API.
 *
 * Potential issues:
 * * We assume there is a unique field called "id" on every record that is the primary key.  Ingest will break if this is not the case
 * * Any field that ends with "Id" is treated as a foreign key reference, and converted to string
 * @param logger A Logger
 * @param searches The searches to be updated to the index - not all will be as only certain types are valid for updating to the index. The rest are ignored
 * @param searchIndexConnector A searchIndexConnector
 * @param tenancyName The unique name of the tenancy - used to determine the index name
 * @param action Optional. Options are DeltaType.Add or DeltaType.Update. Default is DeltaType.Add. Can be either. Updating the index will works for Add if index already exists.
 * @param batchUpdateSize Optional.The search records are batched for updating to the index. This is the size of the batches to create. Default is 1000. Overwrite if you need to make the batch size smaller.
 * @returns the number of searches added/updated to the index
 */
export async function indexPriorSearches(
    logger: Logger,
    searches: (SearchVersion | QuickSearch)[],
    searchIndexerConnector: SearchIndexer,
    tenancyName: string,
    action?: DeltaType,
    batchUpdateSize: number = BATCH_UPDATE_SIZE
): Promise<number> {
    logger.info("Begin indexPriorSearches");

    if (searches.length === 0) {
        logger.warn("No searches given. Nothing to index.");
        return 0;
    }

    /* We keep track of the time elapsed in this function for logging. */
    const preBatchStartTime = Date.now();

    //Note priorSearches will not have any undefined elements as they are filtered out, but compiler doesn't know that, so have to define it to allow for undefined
    const priorSearches: (PriorSearch | undefined)[] = searches.map((search: SearchVersion | QuickSearch) => mapPriorSearchIndex(search)).filter((ps) => ps !== undefined);
    if (priorSearches.length === 0) {
        logger.info(`Nothing to index: Indexed 0 of ${searches.length} searches.`);
        return 0;
    }

    const tenancyIndexName = getSearchIndexName(tenancyName);
    const searchIndexFields = await searchIndexerConnector.getFieldsForIndex(tenancyIndexName);
    const searchableIndexFields = await searchIndexerConnector.getSearchableFieldsForIndex(tenancyIndexName);

    logger.info("Begin processing prior searches");

    //Split the searches into batches of batchUpdateSize
    const recordBatches: RecordBatch[] = [];
    for (let index = 0; index < priorSearches.length; index += batchUpdateSize) {
        const batch = priorSearches.slice(index, index + batchUpdateSize);
        recordBatches.push(createRecordBatch(action ?? DeltaType.Add, batch, ENTITY_TYPE, searchIndexFields, searchableIndexFields, logger));
    }
    const startTime = Date.now();

    let concurrency = Number(process.env.ACTION_CONCURRENCY);
    if (!concurrency) {
        concurrency = 5;
    }
    logger.info(`All records batched. Now actioning ${recordBatches.length} prior search batches`);

    await Bluebird.Promise.map(recordBatches, async (batch: RecordBatch) => await actionRecordBatch(batch, searchIndexerConnector, tenancyIndexName, logger), {
        concurrency: concurrency
    });

    logger.info(`Finished indexPriorSearches: Indexed ${priorSearches.length} of ${searches.length} searches. Ids: [${priorSearches.map((s) => s?.id).join(", ")}]`);
    return priorSearches.length;
}
