import { PermissionsContext, PermissionsContextDirect } from "./ConflictsContextModel";
import { ConflictsAction } from "./Permissions";
import { QuickSearch } from "./QuickSearch";
import { SearchSummary, SearchSummaryNew } from "./SearchSummary";
import { isCompleteSearchStatus, isSearchVersionComplete, SearchStatuses, SearchVersion, SearchVersionNew } from "./SearchVersion";
import { Hit } from "./Hit";
import { OmitStrict } from "@aderant/aderant-web-fw-core";
import { DurableHitIdentifier, HitComment } from "./HitComment";
import { IDENTITY_CONFLICTS_PRODUCTION_SUFFIX } from "./IdentityConstants";
import { unexpectedError } from "./Validation/Errors";

/**
 * The different user roles, some can be used in combination with others.
 */
export enum UserRole {
    None = 0,
    Standard = 1 << 0,
    Conflicts = 1 << 1,
    Admin = 1 << 2,
    ApiAdmin = 1 << 3,
    SecurityPlanExempt = 1 << 4
}

/**
 * Represents a subscription within a tenancy in conflicts (a product environment).
 */
export interface Subscription {
    /**
     * The unique key that identifies a subscription.
     * This key is only unique within a tenancy, not across all tenancies.
     * This key is assigned by Identity Management and has the format <ProductName>.<EnvironmentName>.
     * */
    id: string;
    /**
     * Always lowercase. Is unique within the tenancy. The first 8 chars of the environment name specified in Identity. Empty string for Production environment
     * Max 8 characters. The reason for this is that the resourceNameSuffix is used in the resource group name,
     * storage account name, and other Azure resource names, and an azure storage account name has a limit of 24 characters.
     * The storage account name is created by combining 'cfx' (3 chars - denotes the product - Conflicts), the tenancy
     * uniqueName (6 chars - denotes the tenant), the subscription uniqueName (8 chars - denotes the environment) and
     * 'aderant' (7 chars - denotes the vendor of the product).
     */
    resourceNameSuffix: string;
    /**
     * The display name of the subscription. Is only unique within the tenancy. This is the full name of the environment specified in Identity.
     * e.g. 'Production' or 'Test'.
     */
    displayName: string;
}

/**
 * Represents a tenancy in conflicts. Excludes subscription (which indicates the environment, e.g. Production or Test)),
 * as this is only used in a userless context where the tenancy uniqueName already includes the environment e.g. "84159test".
 */
export interface BasicTenancy {
    /**@deprecated - don't use id, except when calling getUsers on UserManagement, UserService or GlobalUserService. Use uniqueName as the one true identifier.
     * This is the unique identifier for the firm (as per Identity). It is not guaranteed to be unique among all 'tenancies', as each firm can have multiple conflicts
     * 'tenancies' (one per environment).
     */
    id: string;
    /**
     * Guaranteed to be unique among all tenancies, always lowercase. e.g. 'aderant' or '84159'.
     * Includes environment name if environment (subscription) is not Production, e.g '84159' for Production environment and '84159test' for Test environment
     */
    uniqueName: string;
    /** The display name of the tenancy.
     *  Includes environment name if environment (subscription) is not Production, e.g 'Aderant' for Production environment and 'Aderant Test' for Test environment
     */
    displayName?: string;
}

/**
 * Represents a tenancy in conflicts (a combination of customer organization and environment (subscription)).
 */
export interface LoggedInUserTenancy extends BasicTenancy {
    /**
     * The subscription that this tenancy relates to.
     * Only available in the context of a user (e.g. FunctionAppContext or ClientAppContext) as it is not needed in the context of a userless or a keyAuth connection. (e.g.  KeyAuthFunctionAppContext or QueueTriggerFunctionAppContext).
     * This is because these contexts already have the full tenancy.uniqueName that includes the environment
     */
    subscription: Subscription;
}

export type Tenancy = BasicTenancy | LoggedInUserTenancy;

/**
 * Represents a user of the application.
 */
export interface User {
    id: string;
    name: string;
    email: string;
}

/**
 * Tenants for Aderant users, currently used for deleting searches for aderant tenants.
 * Don't use this for feature flags, user targeting for feature flags should be done via LaunchDarkly.
 */
export const aderantTenants = ["aderant", "conflix", "84159p", "84159q", "84159c", "84159", "aderanttest"];

export type LoggedInUser = User & {
    role: UserRole;
    tenancy: LoggedInUserTenancy;
};

export type LoggedInUserId = Pick<LoggedInUser, "id"> & { tenancy: Pick<Tenancy, "uniqueName"> };

export function hasRole(userRoles: UserRole, role: UserRole): boolean {
    return (userRoles & role) === role;
}

export function getSubscriptionName(tenancyUniqueName: string, subscriptionId: string, errorContext: string) {
    if (subscriptionId === IDENTITY_CONFLICTS_PRODUCTION_SUFFIX) {
        return IDENTITY_CONFLICTS_PRODUCTION_SUFFIX;
    }
    const subscriptionParts = subscriptionId.split(".");
    if (subscriptionParts.length !== 2) {
        throw unexpectedError(`Expected current subscription ${subscriptionId} for tenancy ${tenancyUniqueName} to be in the form 'product.environment.'`, `${errorContext} - getSubscriptionName`);
    }
    return subscriptionParts[1];
}

export function getSubscriptionResourceNameSuffix(tenancyUniqueName: string, subscriptionId: string, errorContext: string): string {
    const subscriptionName = getSubscriptionName(tenancyUniqueName, subscriptionId, errorContext + " - getSubscriptionResourceName");
    if (subscriptionName === IDENTITY_CONFLICTS_PRODUCTION_SUFFIX) {
        return "";
    }
    //We have a limit of 8 characters for the environment name in Identity, as names that are too long will cause issues with storage account names and resource group names
    //being longer than the max allowed by Azure.
    if (subscriptionName.length > 8) {
        throw unexpectedError(`Expected subscription name ${subscriptionName} for tenancy ${tenancyUniqueName} to be 8 characters or less.`, `${errorContext} - getSubscriptionResourceName`);
    }
    return subscriptionName.toLowerCase();
}

type ViewSearchInfo = {
    currentUserId: string;
    canViewOwnSearches: boolean;
    canViewOthersSearches: boolean;
    searchVersion: SearchVersion | SearchVersionNew | SearchSummary | SearchSummaryNew | QuickSearch;
};
export async function currentUserCanViewSearch(context: PermissionsContext, searchVersion: SearchVersion | SearchVersionNew | SearchSummary | SearchSummaryNew | QuickSearch): Promise<boolean> {
    return (await currentUserCanViewSearchDetailedResponse(context, searchVersion)).canViewSearch;
}
export async function currentUserCanViewSearchDetailedResponse(
    context: PermissionsContext,
    searchVersion: SearchVersion | SearchVersionNew | SearchSummary | SearchSummaryNew | QuickSearch
): Promise<{ canViewSearch: true } | { canViewSearch: false; reason: "CantViewOwnSearches" | "CantViewOthersSearches" | "CantViewOthersDraftSearches" }> {
    const fetchedContext: ViewSearchInfo = {
        currentUserId: context.currentUserId,
        canViewOwnSearches: await context.currentUserHasPermission(ConflictsAction.ViewOwnSearches),
        canViewOthersSearches: await context.currentUserHasPermission(ConflictsAction.ViewOthersSearches),
        searchVersion: searchVersion
    };

    return currentUserCanViewSearchDetailedResponseInternal(fetchedContext);
}
export function currentUserCanViewSearchDirect(context: PermissionsContextDirect, searchVersion: SearchVersion | SearchVersionNew | SearchSummary | SearchSummaryNew): boolean {
    const fetchedContext: ViewSearchInfo = {
        currentUserId: context.currentUserId,
        canViewOwnSearches: context.currentUserHasPermission(ConflictsAction.ViewOwnSearches),
        canViewOthersSearches: context.currentUserHasPermission(ConflictsAction.ViewOthersSearches),
        searchVersion: searchVersion
    };

    return currentUserCanViewSearchInternal(fetchedContext);
}

function currentUserCanViewSearchInternal(context: ViewSearchInfo): boolean {
    return currentUserCanViewSearchDetailedResponseInternal(context).canViewSearch;
}

function currentUserCanViewSearchDetailedResponseInternal(
    context: ViewSearchInfo
): { canViewSearch: true } | { canViewSearch: false; reason: "CantViewOwnSearches" | "CantViewOthersSearches" | "CantViewOthersDraftSearches" } {
    const isOwnSearch =
        context.searchVersion.createdByUserId === context.currentUserId ||
        context.searchVersion.assignedToUserId === context.currentUserId ||
        context.searchVersion.requestedByUserId === context.currentUserId;

    if (context.searchVersion.status === "DRAFT" || context.searchVersion.isQuickSearch) {
        //Users shouldn't see drafts or quicksearches created by other users
        if (context.searchVersion.createdByUserId === context.currentUserId) {
            return { canViewSearch: true };
        }
    } else {
        if (context.canViewOwnSearches && isOwnSearch) {
            return { canViewSearch: true };
        } else if (context.canViewOthersSearches && !isOwnSearch) {
            return { canViewSearch: true };
        }
    }
    //console.debug("currentUserCanViewSearch - Returning user cannot view search");
    if (isOwnSearch) {
        return { canViewSearch: false, reason: "CantViewOwnSearches" };
    } else if (context.searchVersion.status === "DRAFT") {
        return { canViewSearch: false, reason: "CantViewOthersDraftSearches" };
    } else {
        return { canViewSearch: false, reason: "CantViewOthersSearches" };
    }
}

export async function currentUserCanChangeAssignedTo(context: PermissionsContext, searchVersion: SearchVersion | SearchSummary | QuickSearch): Promise<boolean> {
    return (
        searchVersion.status !== SearchStatuses.Draft &&
        searchVersion.status !== SearchStatuses.Searching &&
        (((await context.currentUserHasPermission(ConflictsAction.ReassignSearchesAssignedToSelf)) && context.currentUserId === searchVersion.assignedToUserId) ||
            ((await context.currentUserHasPermission(ConflictsAction.ReassignSearchesAssignedToOthers)) && context.currentUserId !== searchVersion.assignedToUserId))
    );
}

export function currentUserCanChangeAssignedToDirect(context: PermissionsContextDirect, searchVersion: SearchVersion | SearchSummary | QuickSearch): boolean {
    return (
        searchVersion.status !== SearchStatuses.Draft &&
        searchVersion.status !== SearchStatuses.Searching &&
        ((context.currentUserHasPermission(ConflictsAction.ReassignSearchesAssignedToSelf) && context.currentUserId === searchVersion.assignedToUserId) ||
            (context.currentUserHasPermission(ConflictsAction.ReassignSearchesAssignedToOthers) && context.currentUserId !== searchVersion.assignedToUserId))
    );
}

export type HitContext = {
    currentUserId: string;
    canChangeHitOwnerOnOwnHits: boolean;
    canChangeHitOwnerOnOthersHits: boolean;
    searchVersion: SearchVersion | SearchSummary;
};

export function currentUserCanChangeSomeHitOwnersDirect(context: PermissionsContextDirect, searchVersion: SearchVersion | SearchSummary, hits: Hit[]): boolean {
    const fetchedContext: HitContext = {
        currentUserId: context.currentUserId,
        canChangeHitOwnerOnOwnHits: context.currentUserHasPermission(ConflictsAction.ReassignHitsAssignedToSelf),
        canChangeHitOwnerOnOthersHits: context.currentUserHasPermission(ConflictsAction.ReassignHitsAssignedToOthers),
        searchVersion: searchVersion
    };
    return currentUserCanChangeSomeHitOwnersInternal(fetchedContext, hits);
}

export async function currentUserCanChangeSomeHitOwners(context: PermissionsContext, searchVersion: SearchVersion | SearchSummary, hits: Hit[]): Promise<boolean> {
    const fetchedContext: HitContext = {
        currentUserId: context.currentUserId,
        canChangeHitOwnerOnOwnHits: await context.currentUserHasPermission(ConflictsAction.ReassignHitsAssignedToSelf),
        canChangeHitOwnerOnOthersHits: await context.currentUserHasPermission(ConflictsAction.ReassignHitsAssignedToOthers),
        searchVersion: searchVersion
    };
    return currentUserCanChangeSomeHitOwnersInternal(fetchedContext, hits);
}

export function currentUserCanChangeSomeHitOwnersInternal(context: HitContext, hits: Hit[]): boolean {
    return (
        context.searchVersion.status !== SearchStatuses.Draft &&
        context.searchVersion.status !== SearchStatuses.Searching &&
        !isCompleteSearchStatus(context.searchVersion.status) &&
        (context.canChangeHitOwnerOnOthersHits ||
            (context.canChangeHitOwnerOnOwnHits && (context.currentUserId === context.searchVersion.assignedToUserId || hits.some((hit) => context.currentUserId === hit.hitOwnerId))))
    );
}

export function currentUserCanChangeHitOwnerDirect(context: PermissionsContextDirect, searchVersion: SearchVersion | SearchSummary, hit: Hit): boolean {
    const hitContext: HitContext = {
        currentUserId: context.currentUserId,
        canChangeHitOwnerOnOwnHits: context.currentUserHasPermission(ConflictsAction.ReassignHitsAssignedToSelf),
        canChangeHitOwnerOnOthersHits: context.currentUserHasPermission(ConflictsAction.ReassignHitsAssignedToOthers),
        searchVersion: searchVersion
    };
    return currentUserCanChangeHitOwnerInternal(hitContext, hit);
}

export async function currentUserCanChangeHitOwner(context: PermissionsContext, searchVersion: SearchVersion | SearchSummary, hit: Hit): Promise<boolean> {
    const hitContext: HitContext = {
        currentUserId: context.currentUserId,
        canChangeHitOwnerOnOwnHits: await context.currentUserHasPermission(ConflictsAction.ReassignHitsAssignedToSelf),
        canChangeHitOwnerOnOthersHits: await context.currentUserHasPermission(ConflictsAction.ReassignHitsAssignedToOthers),
        searchVersion: searchVersion
    };
    return currentUserCanChangeHitOwnerInternal(hitContext, hit);
}

export function currentUserCanChangeHitOwnerInternal(context: HitContext, hit: Hit): boolean {
    return (
        context.searchVersion.status !== SearchStatuses.Draft &&
        context.searchVersion.status !== SearchStatuses.Searching &&
        !isCompleteSearchStatus(context.searchVersion.status) &&
        (context.canChangeHitOwnerOnOthersHits ||
            (context.canChangeHitOwnerOnOwnHits && (context.currentUserId === context.searchVersion.assignedToUserId || context.currentUserId === hit.hitOwnerId)))
    );
}

type HitStatusInfo = {
    currentUserId: string;
    canChangeHitStatusOnOwnSearches: boolean;
    canChangeHitStatusOnOthersSearches: boolean;
    searchVersion: SearchVersion | SearchSummary;
};
export function currentUserCanChangeHitStatusDirect(context: PermissionsContextDirect, searchVersion: SearchVersion | SearchSummary): boolean {
    const fetchedContext: HitStatusInfo = {
        currentUserId: context.currentUserId,
        canChangeHitStatusOnOwnSearches: context.currentUserHasPermission(ConflictsAction.ChangeHitStatusOnOwnSearches),
        canChangeHitStatusOnOthersSearches: context.currentUserHasPermission(ConflictsAction.ChangeHitStatusOnOthersSearches),
        searchVersion: searchVersion
    };
    return currentUserCanChangeHitStatusInternal(fetchedContext);
}
export async function currentUserCanChangeHitStatus(context: PermissionsContext, searchVersion: SearchVersion | SearchSummary): Promise<boolean> {
    const fetchedContext: HitStatusInfo = {
        currentUserId: context.currentUserId,
        canChangeHitStatusOnOwnSearches: await context.currentUserHasPermission(ConflictsAction.ChangeHitStatusOnOwnSearches),
        canChangeHitStatusOnOthersSearches: await context.currentUserHasPermission(ConflictsAction.ChangeHitStatusOnOthersSearches),
        searchVersion: searchVersion
    };
    return currentUserCanChangeHitStatusInternal(fetchedContext);
}
function currentUserCanChangeHitStatusInternal(context: HitStatusInfo): boolean {
    if (!isCompleteSearchStatus(context.searchVersion.status)) {
        if (context.searchVersion.status === "CONDITIONALAPPROVAL" && !isSearchVersionComplete(context.searchVersion)) {
            return false;
        }

        return (
            (context.canChangeHitStatusOnOwnSearches && context.currentUserId === context.searchVersion.assignedToUserId) ||
            (context.canChangeHitStatusOnOthersSearches && context.currentUserId !== context.searchVersion.assignedToUserId)
        );
    }
    return false;
}

export function currentUserCanDeleteDirect(context: PermissionsContextDirect, searchVersion: SearchVersion | SearchSummary): boolean {
    return searchVersion.status === "DRAFT" || !!searchVersion.isQuickSearch;
}
export async function currentUserCanDelete(context: PermissionsContext, searchVersion: SearchVersion | SearchSummary): Promise<boolean> {
    return searchVersion.status === "DRAFT" || !!searchVersion.isQuickSearch;
}

function currentUserIsMentionedInHitComment(context: PermissionsContext, searchVersion: SearchVersion, hitIdentifier: DurableHitIdentifier): Promise<boolean> {
    //ToDo - This will be implemented with the @mention feature for comments. This should return true if the user has been mentioned in a comment for the hit, else false.
    return Promise.resolve(false);
}

export async function currentUserCanViewHit(context: PermissionsContext, searchVersion: SearchVersion, hitIdentifier: DurableHitIdentifier): Promise<boolean> {
    if (await currentUserCanViewSearch(context, searchVersion)) {
        return true;
    }
    const requestTerm = searchVersion.requestTerms?.find((rt) => rt.id === hitIdentifier.requestTermId);
    const hit = requestTerm?.hits?.find((hit) => hit.sourceType === hitIdentifier.hitEntityType && hit.sourceData.id === hitIdentifier.hitEntityId);
    const currentUserIsHitOwner = hit?.hitOwnerId === context.currentUserId;
    if (currentUserIsHitOwner) {
        return true;
    }
    return await currentUserIsMentionedInHitComment(context, searchVersion, hitIdentifier);
}

export async function currentUserCanCommentOnHit(context: PermissionsContext, searchVersion: SearchVersion, hitIdentifier: DurableHitIdentifier): Promise<boolean> {
    return await currentUserCanViewHit(context, searchVersion, hitIdentifier);
}

export function currentUserCanEditCommentOnHit(context: PermissionsContext, comment: HitComment): boolean {
    return comment.createdByUserId === context.currentUserId;
}

export function currentUserCanDeleteCommentOnHit(context: PermissionsContext, comment: HitComment): boolean {
    return comment.createdByUserId === context.currentUserId;
}

type TenancyResourceGroup = { tenancyResourceGroupUniqueName: string; tenancyResourceGroupDisplayName: string };
export type TenancyDetails = TenancyWithoutId & TenancyResourceGroup;
export type TenancyWithoutId = OmitStrict<Tenancy, "id">;
export type Region = { resourceGroupName: string; displayName: string };
export type FirmEntity = { tenancy: TenancyDetails; region: Region };
export type RegionAndTenancies = {
    appResourceGroupUniqueName: string;
    appResourceGroupDisplayName: string;
    tenancies: TenancyDetails[];
};

export type UserManagementUserApplication = { applicationName: string; roles: { value: string }[] | null; environments?: { environmentName: string; roles: { value: string }[] | null }[] };
